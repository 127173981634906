/* 프로필 */
.profile-wrap {
  margin-bottom: 40px;
  width: 50%;
  height: 160px;
  display: flex;
  flex-direction: row;
  margin-left: 6%;
}
.profile-wrap .my-photo {
  width: 160px;
  height: 160px;
}
.profile-wrap .my-name {
  width: auto;
  height: 160px;
  margin-left: 30px;
}
.profile-wrap .my-name > p {
  font-size: 18px;
  color: #090909;
  margin-top: 70px;
  margin-bottom: 10px;
}

.profile-wrap .my-name > p:nth-child(1) {
  display: flex;
}

.profile-wrap .my-name > p:nth-child(1) > input {
  border: 0;
  border-bottom: 1px solid rgba(184, 183, 183, 0.5);
  box-sizing: border-box;
  padding-top: 1px;
  width: 50%;
  font-size: 18px;
}
.profile-wrap .my-name > p:nth-child(2) {
  color: #919191;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
.subtitle {
  font-size: 18px;
  color: #090909;
  font-weight: bold;
}
/* 아이디 */
.privacy-id {
  margin-left: 6%;
  width: 100%;
  height: 100px;
}
#idbox {
  display: block;
  width: 385px;
  height: 50px;
  line-height: 50px;
  border: 1px solid transparent;
  border-bottom: 1px solid #090909;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 20px;
}
/* 휴대전화 */
.phone {
  margin-left: 6%;
  width: 100%;
  min-height: 120px;
  height: auto;
}
.phone-number-input #pn1 {
  width: 385px;
  height: 50px;
  line-height: 50px;
  border: 1px solid transparent;
  border-bottom: 1px solid #090909;
  box-sizing: border-box;
  font-size: 16px;
  padding-left: 20px;
}

.phone-number-change {
  position: relative;
  width: 83px;
  height: 35px;
  line-height: 35px;
  margin-top: 6px;
  left: -90px;
  text-align: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: #e2e2e2;
  font-size: 16px;
  color: #0e0e0e;
  border-radius: 20px;
  cursor: pointer;
}
.phone-number-change:hover {
  background-color: #ffc938;
  color: #fff;
}
.password {
  margin-left: 6%;
  width: 100%;
  height: 120px;
}
.word {
  margin-top: 10px;
  border: 1px solid transparent;
  box-sizing: border-box;
  width: 385px;
  height: auto;
  line-height: 45px;
  background-color: #f3f3f3;
  color: #000;
  font-size: 16px;
  padding-left: 20px;
}
/* 비밀번호 확인 */
.reconfirm {
  margin-left: 6%;
  width: 100%;
  height: 120px;
}
/* 생년월일 */
.birthday {
  margin-left: 6%;
  box-sizing: border-box;
  padding-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  height: auto;
}
.birthday > input[text] {
  color: #000 !important;
}
.birthday > .word {
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  max-width: 385px;
  height: 45px;
  line-height: 45px;
  background-color: #fff;

  font-size: 16px;
  text-align: center;
}

.birthday > .word-no {
  display: block;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  max-width: 385px;
  height: 45px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  text-align: center;
}

.dis-none {
  display: none;
  color: #000;
}
/* 성별 */
.gender {
  margin-left: 6%;
  width: 100%;
  height: 70px;
  margin-top: 10px;
  display: flex;
  justify-content: left;
}

.gender > #male-button[type="radio"] {
  display: none;
}

#male-button[type="radio"] + label {
  width: 176px;
  height: 45px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  margin-right: 33px;
}

#male-button[type="radio"] + label::before {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content: "남자";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  color: #090909;
  cursor: pointer;
}

#male-button[type="radio"]:checked + label::after {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content: "남자";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 3px var(--kk-color-Ymain) inset;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  color: #090909;
  cursor: pointer;
}

.gender > #female-button[type="radio"] {
  display: none;
}

#female-button[type="radio"] + label {
  width: 176px;
  height: 45px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  position: relative;
}

#female-button[type="radio"] + label::before {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content: "여자";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  color: #090909;
  cursor: pointer;
}

#female-button[type="radio"]:checked + label::after {
  display: flex;
  flex-direction: column;
  justify-content: center;
  content: "여자";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 3px var(--kk-color-Ymain) inset;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  color: #090909;
  cursor: pointer;
}

/* .gender button{
    width: 40%;
    height: 45px;
    line-height: 45px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    color: #090909;
    transition: 0.3s;
    cursor: pointer;
} */

.gender button:hover {
  border: 1px solid #ffc938;
  font-weight: bold;
}
/* 저장하기 */
.privacy-save {
  width: 100%;
  height: 45px;
  margin-top: 30px;
}
.privacy-save > .privacy-save-button {
  width: 200px;
  height: 45px;
  line-height: 45px;
  border: 1px solid transparent;
  border-radius: 40px;
  box-sizing: border-box;
  background-color: #ffc938;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-left: 120px;
  cursor: pointer;
}

.privacy-save-button:hover {
  background-color: var(--kk-color-Ymain);
}

.privacy-save-button:active {
  background-color: var(--kk-color-Ymain);
}
/* 탈퇴 */
.secession {
  width: 50%;
  height: 100px;
  margin-top: 50px;
  margin-left: 6%;
}
.secession > p {
  width: 385px;
  font-size: 14px;
  font-weight: bold;
  color: #919191;
  margin-top: 10%;
}
.secession .secession-btn {
  width: 83px;
  height: 35px;
  line-height: 35px;
  margin-top: 20px;
  text-align: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: #e2e2e2;
  font-size: 16px;
  color: #0e0e0e;
  border-radius: 20px;
  cursor: pointer;
}

.secession .secession-btn:hover {
  background-color: #000;
  color: #fff;
}

.secession .secession-btn:active {
  background-color: #000;
  color: #fff;
}

/*회원탈퇴 모달창 */

.daps-frame > .memberout-modal-frame {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}

.memberout-modal-frame > .memberout-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: auto;
  border-radius: 30px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.memberout-area > span {
  margin-bottom: 20px;
  font-weight: 600;
  display: block;
  font-size: 18px;
  width: 100%;
  height: auto;
  text-align: center;
}

.memberout-area > span > .memberout-close-button {
  float: right;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: none;
  box-shadow: 0 0 0 2px #d2d2d1 inset;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.memberout-area > span > .memberout-close-button:hover {
  background-color: var(--kk-color-hover);
}

.memberout-area > #memberout-input {
  margin-bottom: 20px;
  width: 100%;
  height: 35px;
  border: none;
  background-color: #f5f5f5;
  text-align: center;
}

.memberout-area > .memberout-button {
  margin: 0 auto;
  width: 50%;
  height: 35px;
  border-radius: 30px;
  border: none;
  font-size: 19px;
  color: #fff;
  background-color: #b7b7b7;
}

.memberout-button:hover {
  background-color: var(--kk-color-hover);
}
.memberout-button:active {
  background-color: var(--kk-color-active);
}

/* @media screen and (max-width: 1152px){
    .gender{
        width: 100%;
    }  
    #idbox{
        width: 90%;
    }
    .phone{
         width: 90%;     
    }
} */
@media screen and (max-width: 768px) {
  .phone-number-change {
    position: absolute;
    left: 240px;
  }
  .secession > p {
    width: 300px;
  }
  .profile-wrap {
    width: 90%;
  }
  .password > input {
    width: 300px;
    font-size: 14px;
  }
  .reconfirm > input {
    width: 300px;
    font-size: 14px;
  }
  .birthday > .word {
    font-size: 14px;
    max-width: 300px;
  }
  .birthday > .word-no {
    font-size: 14px;
    max-width: 300px;
  }

  #idbox {
    font-size: 14px;
    max-width: 300px;
  }

  .phone-number-input #pn1 {
    font-size: 14px;
    max-width: 300px;
  }

  .birthday > .dis-none {
    font-size: 14px;
    max-width: 300px;
  }
  #male-button[type="radio"] + label {
    width: 135px;
    margin-right: 30px;
  }
  #female-button[type="radio"] + label {
    width: 135px;
  }
  .privacy-save {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .privacy-save > .privacy-save-button {
    margin: 0;
  }
  .memberout-modal-frame > .memberout-area {
    width: 90%;
  }
  .memberout-area > .memberout-button {
    font-size: 16px;
  }
}
