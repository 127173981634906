.introduction
{
  margin: 0 auto;
  width: 100%;
  height: auto;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Nanum Gothic';
}

.introduction > .kiikey-intro-wrap
{
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  background-color: #f2ada6;
  background-image: url(src_assets/main_bg_nobg.png);
  background-repeat: no-repeat;
  background-size: 41%;
  background-position: 52% 100%;
  padding-left: 20%;
}

.kiikey-intro-wrap > .intro-text-area
{
  margin-top: 160px;
  width: 600px;
  height: auto;
  position: relative;
}

.intro-text-area > img
{
  position: absolute;
  top:0px;
  left:0px;
}


.intro-text-area > .s-intro-text
{
  margin-bottom: 30px;
  display: block;
  width: 100%;
  height: auto;
  font-size: 26px;
  color: white;
  display: flex;
  flex-direction: column;
}

.intro-text-area > .l-intro-text
{
  display: block;
  width: 100%;
  height: auto;
}

.l-intro-text > span
{
  display: block;
  width: auto;
  height: 125px;
  font-size: 120px;
}

.l-intro-logo > img
{
  width: 55%;
}

.l-intro-text > span:nth-child(2)
{
  font-weight: 100;
}


.introduction > .kiikey-discount-wrap
{
  width: 100%;
  height: auto;
  padding-left: 20%;
  background-color: #000000;
  background-image: url(src_assets/discount_img_bg_nobg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 4%;
}

.kiikey-discount-wrap > .discount-text-area
{
  width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.discount-text-area > span
{
  display: block;
  width: 100%;
  height: auto;
}

.discount-text-area > .frame-number
{
  display: none;
  font-size: 20px;
  font-weight: 100;
  color: #d6d6d6;
  margin-bottom: 25px;
}

.discount-text-area > .discount-title
{
    font-size: 38px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 75px;
}

.discount-text-area > .discount-text-list
{
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
}

.discount-text-list > li
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.discount-text-list > li > span
{
  display: block;
}

.discount-text-list > li > .list-number
{
  width: 10%;
  height: 100%;
  color: #fff;
}

.discount-text-list > li > .list-text
{
  width: 90%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 40px;
}

.list-text > h2
{
  margin-bottom: 10px;
  color: #fff;
}

.list-text > p
{
  font-size: 15px;
  color: #afa4a5;
}

@media screen and (max-width: 1280px)
{


}


@media screen and (max-width: 768px)
{
  .introduction
  {
    padding: 0px;
  }

  .introduction > .kiikey-intro-wrap
  {
    box-sizing: border-box;
    padding-top: 100px;
    background-position: 350% 100%;
    background-size: 95%;
    padding-left: 10%;
  }
  .kiikey-intro-wrap > .intro-text-area
  {
    margin-top: 30px;
  }
  .intro-text-area > .s-intro-text
  {
    margin-bottom: 0px;
    font-size: 7vw;
    font-weight: bold;
  }
  .discount-text-list > li > .list-text
  {
    font-size: 3vw;
    padding-bottom: 20px;
  }
  .intro-text-area > .l-intro-text
  {
    color: #fff;
  }

  .l-intro-text > .l-intro-logo
  {
    width: 100vw;
  }

  .l-intro-text > span
  {
    font-size: 20vw;
  }


  .introduction > .kiikey-discount-wrap
  {
    background-image: url(src_assets/discount_img_bg_mobile.png);
    background-size: 100%;
    background-position: 100% 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 30%;
  }
  .kiikey-discount-wrap > .discount-text-area
  {
    width: 100%;
  }
  .discount-text-area > .frame-number
  {
    display: none;
  }
  .list-text > p
  {
    color: #fff;
  }
  .l-intro-text > span
  {
    height: 110px;
  }
  .l-intro-text > span:nth-child(3)
  {
    color: #fff;
    font-weight: 600;
  }

  .l-intro-text > .l-intro-logo
  {
    display: block;
  }

  .l-intro-logo > img
  {
    width: 62%;
  }
  .discount-text-area > .discount-title
  {
    font-size: 6vw;
  }

}










