html {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.game-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  border: 7px solid #ffd533;
  box-sizing: border-box;
  /* padding: 20px 20px 10px 20px; */
}

.game-head {
  position: absolute;
  /* background-color: var(--kk-color-Ymain); */
  top: 50%;
  left: 50%;
  z-index: 1111;
  transform: translate(-50%, -50%);
  height: 0;
}

.mobile-game-head {
  height: 0;
  position: absolute;
  /* background-color: var(--kk-color-Ymain); */
  top: 50%;
  left: 50%;
  z-index: 1111;
  transform: translate(-50%, -50%);
}

.game-head > img,
.mobile-game-head > img {
  width: 200px;
  margin-right: 250px;
  transform: translate(0, -420%);
}

/* finish 모달 */
.game-finish-head {
  position: absolute;
  /* background-color: var(--kk-color-Ymain); */
  top: 50%;
  left: 50%;
  z-index: 1111;
  transform: translate(-50%, -50%);
  /* text-align: left; */
}
.game-finish-head > img {
  width: 150px;
  margin-right: 250px;
  transform: translate(-10%, -265%);
}

.game-foot,
.game-finish-foot {
  height: auto;
  text-align: center;
}

.game-thumbnail {
  margin-top: 50px;
  text-align: center;
}

.game-thumbnail > img {
  width: 90%;
  height: 250px;
}

.game-head > .game-text,
.game-foot > .game-text {
  text-align: center;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  font-size: 21px;
}

/* finisg 모달 */
/* .game-finish-foot {
    height: auto;
    text-align: center;
} */

.game-finish-answer {
  margin: 30px auto;
  width: 90%;
  height: 100px;
  background-color: #fffec8;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
  line-height: 100px;
}
.game-form > .game-finish-list {
  margin: 30px auto;
  width: 90%;
  height: auto;
  border-radius: 20px;
  box-sizing: border-box;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
/* .game-finish-list>li {
    width: 80%;
    padding: 10px 30px 10px 0;
    list-style-type: disc;
}
.game-finish-list > li > .game-colon  {
    margin-left: 15%;
    margin-right: 15%;
} */

.game-form > .game-list {
  margin: 30px auto;
  padding: 10px 0 10px 0;
  width: 90%;
  height: auto;
  background-color: #fffec8;
  border-radius: 20px;
  box-sizing: border-box;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.game-list > li,
.game-finish-list > li {
  width: 80%;
  padding: 10px 30px 10px 0;
  list-style-type: disc;
  /* display: inline-block; */
}
.game-list > li > .game-colon,
.game-finish-list > li > .game-colon {
  margin-left: 15%;
  margin-right: 15%;
}
.game-list > li > img {
  width: 100%;
}

.game-modal > .game-foot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 240%);
}

.game-foot > .foot-btn > img {
  width: 190px;
  border-radius: 10px;
  cursor: pointer;
  transform: translate(0%, 20%);
}

/* finish 모달 */
.game-modal > .game-finish-foot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.game-finish-foot > .foot-btn > img {
  width: 150px;
  border-radius: 10px;
  cursor: pointer;
  transform: translate(0%, 180%);
}

.game-modal > .game-foot > .app-foot-btn {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 285%);
}

.game-modal > .game-foot > .app-foot-btn > img {
  width: 120px;
  margin: 10px;
}

.game-modal > .game-finish-foot > .app-foot-btn {
  display: none;
  position: absolute;
  transform: translate(-50%, 150%);
}

.game-modal > .game-finish-foot > .app-foot-btn > img {
  width: 120px;
  margin: 10px;
}

/* #finish-modal {
    animation: scale1 1s alternate;
}

@keyframes scale1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
} */

.closeWebView {
  width: 50px;
  height: 50px;
  color: #191919;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(200%, -220px);
  font-size: 18px;
  font-weight: bold;
}
.closeWebView:active {
  outline: none;
}

.closeWebView > img {
  width: 50px;
}

.gameStart:hover,
.gameStart:active {
  outline: none;
  content: url(src_assets/game_start_on.png);
}
.finish-btn:hover,
.finish-btn:active {
  outline: none;
  content: url(src_assets/game_finish_on.png);
}

.app-foot-btn > .gameReset:active {
  outline: none;
  content: url(src_assets/reset_game_on.png);
}

@media screen and (max-width: 1024px) {
  /* .game-modal > .mobile-game-head {
        height: auto;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    } */
  /* .game-head {
        width: 200px;
        margin-right: 230px;
        transform: translate(-110%, -100%);
    } */

  .game-modal > .game-foot > .app-foot-btn {
    transform: translate(-50%, 270%);
  }

  .closeWebView {
    transform: translate(350%, -315px);
  }
  /* finish 모달 */
  .game-modal > .game-finish-head {
    /* width: 300px; */
    height: auto;
  }
  .game-finish-head > img {
    width: 140px;
    margin-right: 300px;
    transform: translate(0%, -310%);
  }
  .game-modal > .game-finish-foot > .app-foot-btn {
    display: none;
    position: absolute;
    transform: translate(-50%, 78%);
  }

  .game-modal > .game-finish-foot > .app-foot-btn > img {
    width: 120px;
    margin: 10px;
  }

  .game-modal > .game-finish-foot > .foot-btn {
    transform: translate(0%, 0%);
  }

  .game-head > img {
    width: 170px;
    transform: translate(0%, -498%);
  }

  .game-foot > .foot-btn > img {
    width: 170px;
    transform: translate(0%, 50%);
  }
}

@media screen and (max-width: 768px) {
  .game-thumbnail > img {
    width: 90%;
    height: 160px;
  }

  .closeWebView {
    transform: translate(200%, -220px);
  }

  .game-form {
    top: 50%;
    width: 85%;
  }
  /* .game-modal > .game-head {
        width: 300px;
    } */
  .game-head > img {
    width: 140px;
    margin-right: 170px;
    transform: translate(0%, -445%);
  }

  .game-modal > .mobile-game-head {
    /* width: 300px; */
    height: auto;
    transform: translate(-50%, -50%);
  }
  .mobile-game-head > img {
    width: 140px;
    margin-right: 170px;
  }

  /* finish 모달 */
  .game-modal > .game-finish-head {
    /* width: 300px; */
    height: auto;
    transform: translate(-50%, 25%);
  }
  .game-finish-head > img {
    width: 140px;
    margin-right: 170px;
  }

  .game-modal > .game-finish-foot {
    position: absolute;
    transform: translate(-50%, 50%);
  }

  .game-modal > .game-finish-foot > .foot-btn > img {
    width: 120px;
  }
  .game-modal > .game-foot > .app-foot-btn {
    transform: translate(-50%, 293%);
  }

  .game-modal > .game-finish-foot > .app-foot-btn {
    display: none;
    position: absolute;
    transform: translate(-50%, 150%);
  }

  .game-modal > .game-finish-foot > .app-foot-btn > img {
    width: 120px;
    margin: 10px;
  }

  .game-modal > .game-foot {
    position: absolute;
    transform: translate(-50%, 330%);
  }

  .game-modal > .game-foot > .foot-btn > img {
    width: 140px;
    transform: translate(0%, 0%);
  }
}
