.shopping-basket {
  margin-left: 10px;
  width: 120px;
  height: 100%;
  position: relative;
  scroll-behavior: smooth;
}

.shopping-basket > .follow-basket {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  scroll-behavior: smooth;
  transition: top 0.5s ease-in-out 0s, right 0.5s;
}

.follow-basket > button {
  margin: 0 auto;
  width: 95px;
  height: 95px;
  margin-bottom: 10px;
  border-radius: 100%;
  background-color: rgb(255, 255, 255);
  border: none;
  position: relative;
  cursor: pointer;
}

.follow-basket > .add-item {
  background-image: url(src_assets/drawable/add_cart.png);
  background-position: 45% 65%;
  background-repeat: no-repeat;
  background-size: 50%;
  box-shadow: 2px 2px 10px rgba(189, 189, 189, 0.5);
}

.follow-basket > .add-item > .add-item-count {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 12px;
  right: 15px;
  background-color: #f6c116;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}

.follow-basket > .to-top-button {
  background-color: #4c4c4c;
  background-repeat: no-repeat;
  background-image: url(src_assets/allow-button-top.png);
  background-position: 50% 20%;
  background-size: 50%;
  color: #fff;
  box-sizing: border-box;
  padding-top: 20%;
  font-size: 21px;
}

/* .follow-basket > .add-item-button
{
  background-repeat: no-repeat;
  background-image: url(src_assets/drawable/ic_ad_watch_order.png);
  background-position: 50% 35%;
  background-size: 50%;
  box-shadow: 2px 2px 10px rgba(189, 189, 189, 0.5);
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  padding-top: 40%;
  font-weight: bold;
  font-size: 10px;
} */

.follow-basket > .menu-navi {
  width: 100%;
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.menu-navi > li {
  display: flex;
  margin: 2px 0px 5px 0px;
  width: 100%;
  height: 25px;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
}

.menu-navi > li > p:hover {
  background-color: #f6c116;
  color: #fff;
}

.menu-navi > li > p {
  width: auto;
  height: 100%;
  color: #8e8e8e;
  border-radius: 6px;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 25px;
  background-color: #f2f2f2;
  white-space: nowrap;
}

.shopping-basket-mobile {
  display: none;
}

/*-FolloBasketMobile--*/

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 769px) {
  .shopping-basket-mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .shopping-basket {
    display: none;
  }
  .shopping-basket-mobile {
    width: 100%;
    height: 120px;
    position: fixed;
    background-color: rgb(255, 255, 255);
    bottom: 0px;
    box-sizing: border-box;
    padding: 10px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .shopping-basket-mobile > .shopping-menu-nav-mobile-box {
    width: 100%;
    height: 35px;
    overflow-x: hidden;
  }

  .shopping-menu-nav-mobile-box::-webkit-scrollbar {
    display: none;
  }

  .shopping-menu-nav-mobile-box::-moz-scrollbar {
    display: none;
  }

  .shopping-menu-nav-mobile-box > .shopping-menu-nav-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: scroll;
    box-sizing: border-box;
    padding-left: 0px 5px 0px 5px;
  }
  .shopping-menu-nav-mobile::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .shopping-menu-nav-mobile > li {
    flex-shrink: 0;
    margin: 0px 5px 0px 5px;
    box-sizing: border-box;
    padding: 8px 10px 0px 10px;
    display: block;
    min-width: 100px;
    width: auto;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 18px;
    background-color: #f2f2f2;
  }

  .shopping-menu-nav-mobile > li:hover {
    background-color: var(--kk-color-Ymain);
  }
  .shopping-basket-mobile > .shopping-function-mobile {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
  }

  .shopping-function-mobile > li {
    margin: 0 auto;
    width: 15%;
    height: 100%;
    position: relative;
  }

  .shopping-function-mobile > .shop-basket-li > .shop-basket-count {
    position: absolute;
    width: 5vw;
    height: 5vw;
    border-radius: 100%;
    top: 5px;
    right: 5px;
    font-size: 3vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
  }

  .shopping-function-mobile > li > button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px 10px 0px 0px;
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
    cursor: pointer;
  }

  .shopping-function-mobile > li .shop-basket-button {
    background-color: #2193b0;
    background-image: url(src_assets/drawable/add_cart.png);
    background-position: 35%;
  }

  .shopping-function-mobile > li .shop-share-button {
    background-color: rgb(255, 255, 255);
    background-image: url(src_assets/drawable/ic_share.png);
  }

  .shopping-function-mobile > li .shop-call-button {
    background-color: rgb(255, 255, 255);
    background-image: url(src_assets/drawable/ic_phone.png);
  }

  .shopping-function-mobile > li .shop-add-button {
    background-color: #fff;
    background-image: url(src_assets/drawable/add_cart.png);
  }

  .shopping-function-mobile > li > #like {
    display: none;
  }
  .shopping-function-mobile > li > input + label {
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgb(255, 255, 255);
    background-image: url(src_assets/drawable/ic_shop_like.png);
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
  }
  .shopping-function-mobile > li > #like[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    /* background-image: url(src_assets/drawable/ic_shop_like.png); */
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
  }
  .shopping-function-mobile
    > li
    > #like[type="checkbox"]:checked
    + label::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url(src_assets/drawable/ic_shop_likes.png);
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
  }
  .navBtn a {
    color: red;
    /* overflow-x: hidden; */
  }
}
