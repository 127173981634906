/* NEW */

.-search-order-menu-list-frame {
  /* background-color: aqua; */
  font-family: "Noto Sans KR", sans-serif;
}

.order-search-box-wrap {
  /* background-color: red; */
}
.search-order-search-wrap {
  /* position: fixed;*/
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 0;
  background-color: #fff;
  z-index: 99;
  /* left: 5%; */

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.search-popular-menu-box {
  display: none;
}

.search-popular-menu-box ul {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  /* padding-left: 30px; */
  flex-wrap: wrap;
}
.search-popular-menu-box li {
  border: 2px solid #fff;
  /* padding: 5px 20px; */
  padding: 1% 5%;
  border-radius: 25px;
  color: #fff;
  margin: 5px;
  white-space: nowrap;
  max-width: 90px;
  line-height: 25px;
}

.search-order-search-box {
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* flex-shrink: 0; */
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 31px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 2px #0000000d;
}
.search-order-search-box > input::placeholder {
  color: black;
}
.search-order-search-button {
  min-width: 30px;
  min-height: 30px;

  background-color: transparent;
  background-image: url(src_assets/pageview.svg);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
}
.search-order-search-wrap input {
  width: 100%;
  background: none;
  border: none;
  box-sizing: border-box;
  /* padding-left: 10px; */
  font-weight: bold;
  font-size: 20px;
  caret-color: black;
}
.search-order-search-wrap input:focus {
  outline: none;
}
.search-order-search-wrap input::placeholder {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #a9a9a9;
}

.search-popular-result {
  /* border: 1px solid green; */
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid var(--order-border-bottom-);
}
.search-popular-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid red; */
  flex-wrap: wrap;
  font-size: 19px;
  font-weight: 300;
  font-family: NotoSansCJKkr;
  /* width: 500px; */
  /* flex: 1 1 40%; */
  /* align-content: stretch; */
}
.search-popular-list li {
  /* border: 1px solid purple; */
  width: 40%;
  margin: 5px;
  /* width: 50%; */
  display: flex;
  align-items: center;
}
.search-popular-list .list-number {
  /* padding: 10px; */
  /* flex: 1; */
  width: 20%;
}
.search-popular-list .pop-name {
  /* padding: 10px; */
  /* flex: 1; */
  width: 80%;
  margin-left: 5px;
}

.search-popular-date-box {
  margin: 10px 0;
  width: 100%;
  text-align: right;
  /* border: 1px solid red; */
  font-size: 13px;
  color: #5a5a5a;
  display: flex;
  justify-content: end;
  /* align-items: center; */
  align-items: center;
}
.search-popular-date-box > .search-popular-date-img {
  /* max-width: 20px; */
  /* width: 150px; */
  /* height: 20px; */
}

.search-popular-date-img > img {
  width: 12px;
  height: 12px;
  margin-top: 3px;
}

.search-popular-name {
  padding: 18px 0;
  border-bottom: 2px solid #f5f6f6;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 22px;
  font-weight: 500;
}

.search-history-list-keyword {
  /* padding-bottom: 1em; */
  /* border-bottom: 2px solid #bdbdbd; */
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.search-last-list {
  width: 100%;
  /* margin: 0 auto; */
  /* padding: 0 10px; */
  box-sizing: border-box;
  padding-bottom: 50px;
}
.search-last-list-list {
  width: 85%;
  margin: 0 auto;
  font-family: NotoSansCJKkr;
}

.search-last-list-list li {
  font-size: 19px;
  font-weight: 300;
  color: #0e0e0e;
  margin-bottom: 5px;
}
.search-last-list-list li div {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
}

.search-last-list-list button {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 100%;
  border: none;
  /* box-shadow: 0 0 0 2px #d2d2d1 inset; */
  background-color: transparent;
  background-image: url(src_assets/close_img.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}

.search-last-list-name {
  width: 100%;
  padding: 12px 0;
  /* border-bottom: 1px solid #f5f6f6; */
  /* margin-bottom: 10px; */
  font-size: 22px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-last-list-name button {
  border-radius: 8px;
  width: 70px;
  height: 27px;
  padding-top: 0.2em;
  border: 0;
  background: #bdbdbd;

  color: #fff;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
}
.search-last-list li {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 400px) {
  .search-order-search-wrap input {
    font-size: 16px;
  }
}
#order-search1 {
  background: none;
  border: none;
  color: #bdbdbd;
  width: 242px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 16px;
}
.search-popular-name-text {
  width: 103px;
  position: relative;
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
  font-family: Inter;
  color: #000;
  text-align: left;
  display: flex;
  align-items: center;
  height: 27px;
}

.search-dist-filter-label {
}

.search-dist-filter-label > input {
  display: none;
}

.search-dist-filter-label > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
}
.search-dist-filter-label > div > img {
  content: url("src_assets/icon/check_box_outline_blank.svg");
}

.search-dist-filter-label > input:checked + div > img {
  content: url("src_assets/icon/check_box.svg");
}

.search-sort-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #828282;
  text-align: center;
  font-size: 11px;
  /* height: 18px; */
  padding-top: 0.2em;
  line-height: 1.5;
}

.search-sort-item[active] {
  background: #bdbdbd;
  color: #fff;
}
