.info-change > li > span {
  font-weight: bold;
}

.info-change > li > input {
  margin-top: 10px;
  height: 45px;
  border: none;
  box-sizing: border-box;
  padding-left: 1%;
}

#name-change {
  border: none;
  border-bottom: 1px solid black;
}

#password-change {
  background-color: rgb(240, 240, 240);
}

#phonenum-change {
  background-color: rgb(240, 240, 240);
}

.add-search {
  margin-top: 10px;
  width: 125px;
  height: 35px;
  background-color: var(--kk-color-Ymain);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
}

.add-search:hover {
  background-color: var(--kk-color-hover);
}

#address-change {
  background-color: rgb(240, 240, 240);
}

.daps-frame > .info-chagne-confirm {
  margin-top: 10px;
  width: 125px;
  height: 35px;
  background-color: var(--kk-color-Ymain);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  border: none;
}

.info-chagne-confirm:hover {
  background-color: var(--kk-color-hover);
}

.change-note {
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: rgb(163, 8, 8);
}
