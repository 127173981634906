.mobile-menu-section
{
  display: block;
  position: fixed;
  top:0px;
  width: 90%;
  height: 100%;
  transition-duration: 0.6s;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}


.mobile-menu-section > .menu-head
{
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-head > button 
{
  width: 90px;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  background-image: url(src_assets/mobile-menu-img.png);
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: center;
}

.menu-head > .mobile-function
{
  width: 200px;
  height: 100%;
}

.mobile-function > span
{
  display: block;
  width: 100px;
  height: 100%;
  font-size: 25px;
  box-sizing: border-box;
  padding-top: 12%;
  float: left;
}

.mobile-function > span > a
{
  color:#fff;
}

.mobile-function > .aside-close
{
  width: 100px;
  height: 100%;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url(src_assets/mobile-menu-close.png);
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center;
}