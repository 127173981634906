.password-find {
  width: 100%;
  height: 100vh;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
}

.password-find > .pw-find-form {
  margin: 0 auto;
  width: 710px;
  height: auto;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 70px;
}

.pw-find-form > .form-title {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 2px solid rgb(214, 214, 214);
}

.pw-find-form > .pw-input-area {
  width: 100%;
  height: 300px;
}

.pw-input-area > .pw-note {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  font-size: 18px;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: bold;
}

.pw-input-area > .pw-input {
  width: 100%;
  height: auto;
}

.pw-input-area > .pw-input > span {
  display: block;
  width: 100%;
  height: 49px;
  margin-bottom: 15px;
  display: block;
  display: flex;
  flex-direction: row;
}

.pw-input > span > label {
  width: 120px;
  height: 100%;
  line-height: 50px;
}

.pw-input > span > input {
  width: 80%;
  height: 100%;
  border-radius: 50px;
  border: none;
  border: 2px solid #ffedbe;
  box-sizing: border-box;
  padding-left: 15px;
}

.pw-find-form > .ps-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 82%;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  border-radius: 50px;
  background-color: #642a1f;
  border: none;
  color: #fff;
}

.ps-button:hover {
  background-color: var(--kk-color-Ymain);
}

.ps-button:active {
  background-color: #ffda7b;
}

.pw-find-form > .login-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 82%;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  border-radius: 50px;
  background-color: #642a1f;
  color: white;
  border: none;
}

@media screen and (max-width: 768px) {
  .password-find {
    height: auto;
    background-color: #fff;
    padding-top: 10%;
  }
  .password-find > .pw-find-form {
    margin: 0 auto;
    width: 85%;
    padding: 70px 0px 70px 0px;
  }
  .pw-find-form > .form-title {
    margin: 0 auto;
    width: 100%;
  }
  .pw-find-form > .pw-input-area {
    margin: 0 auto;
    height: auto;
  }
  .pw-input-area > .pw-input {
    width: 100%;
  }
}
