:root {
  --button-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  --kk-logo-height-large: 26px;
  --kk-logo-width-large: 120px;
  --kk-color-main: #6dd5ed;
  --kk-color-introduction: #f2aca6;
  --kk-color-sub: #f5ffbe;
  --kk-color-hover: #7c7359;
  --kk-color-active: rgb(214, 214, 214);
  --kk-color-button: #642a1f;
  --kk-color-white-text: #fff;
  --kk-color-hover-light: rgba(238, 238, 238, 0.6);
  --join-button-large-width: 100%;
  --join-button-height: 100%;
  --join-input-width: 100%;
  --join-input-height: 100%;
  --order-border-bottom-: #f6f7f7;
  --order-input-color: #f5f5f5;
  accent-color: #6dd5ed;
  appearance: none;
  --kk-color-Ymain: #6dd5ed;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
  line-height: 1;
}
menu,
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

/* #nice_layer, #bg_layer {
  max-width: 100vw;
  max-height: 100vh;
} */

@font-face {
  font-family: "Nanum Gothic", "Noto Sans KR", "sans-serif";
  src: url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SUIT-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Medium.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  src: url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

  font-family: "Noto Sans KR", sans-serif;
}
/* @font-face {
  font-family: 'SUIT-Light';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "SUIT-ExtraLight";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-ExtraLight.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  /* user-select: none; */
}

a {
  text-decoration: none;
  color: initial;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  color: #0e0e0e;
  background-color: #ffffff;
  font-family: "Noto Sans KR", sans-serif;
}
button,
html,
input [type="button"] {
  -webkit-appearance: none;
}
input [type="checkbox"] {
  -webkit-appearance: none;
}

input:checked[type="checkbox"] {
  background-color: #fff;
  -webkit-appearance: checkbox;
}
#root {
  margin: 0 auto;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  position: relative;
}

.kk-logo-large {
  height: var(--kk-logo-height-large);
  width: var(--kk-logo-width-large);
}

/* 플렉스 */
.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

/*갭*/

.gap-s {
  gap: 5px;
}

/*justify*/
.just-center {
  justify-content: center;
}

.just-between {
  justify-content: space-between;
}

.just-around {
  justify-content: space-around;
}

/* 회원가입 */

.join-btn-comfirm {
  display: block;
  margin: 0 auto;
  width: 300px;
  border: none;
  background-color: var(--kk-color-button);
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  border-radius: 100px;
  height: 50px;
  cursor: pointer;
}

.join-btn-comfirm:hover {
  background-color: var(--kk-color-Ymain);
}

.join-btn-comfirm:active {
  background-color: #ffda7b;
}

/*공용 디자인 버튼 리스트 아무거나 */

/*폰트*/

.font-w-light {
  font-weight: 300;
}

.font-w-heavy {
  font-weight: 600;
}

/* 주문 페이지 CSS */

.order-title {
  display: block;
  font-size: 17px;
  font-weight: 600;
  /* margin-top: 25px; */
  /* margin-bottom: 10px; */
  line-height: 24px;
}

.order-title-light {
  display: block;
  font-weight: 300;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.order-wrap4529 .order-title-light {
  margin-top: 0;
}
.order-title-center {
  display: block;
  width: 100%;
  font-size: 22px;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  text-align: center;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.order-title-bottom {
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.order-text {
  font-weight: 300;
  line-height: 130%;
}

.order-input-color {
  background-color: var(--order-input-color);
  border: none;
  text-align: right;
  box-sizing: border-box;
  padding-right: 5px;
  font-weight: 600;
}

.order-frame-title {
  display: block;
  display: none;
  width: 100%;
  text-align: center;
  font-size: 24px;
}

.oft-btm {
  border-bottom: 10px solid var(--order-border-bottom-);
}

.order-frame-mini {
  display: block;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding-left: 10px;
  font-weight: 300;
  color: #b8b8b8;
}
.item-count-box > .count {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: right;
  align-items: center;
  box-sizing: border-box;

  border-radius: 12px;
  border: 1px solid #bdbdbd;
  overflow: hidden;
}

.count > button {
  width: 35px;
  height: 32px;
  color: #bdbdbd;
  /* border-radius: 100%; */
  background-color: transparent;
  /* border: 1px solid #cacaca; */
  cursor: pointer;
}
.count-btn {
  /* border-radius: 100%; */
  background-color: transparent;
  border: none;
  /* border: 1px solid #cacaca; */
  cursor: pointer;
  /* font-size: 17px; */
  /* font-weight: bold; */
}

.count-btn:hover {
  background-color: rgb(224, 224, 224);
}

.count-btn.last {
  margin-right: 0px;
}

.count-num {
  padding: 0px 5px 1.5px;
  width: 1.5em;
  height: 100%;
  text-align: center;
  font-size: 19px;
  display: flex;
  font-weight: 300;
  border: none;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
}

@media screen and (max-width: 768px) {
  .count-btn {
    width: 35px;
    height: 35px;
  }
}

/* 전표 CSS */

.sta-color {
  color: #979797;
}

.statement-title {
  font-size: 15px;
  font-weight: 600;
  float: left;
  height: 30px;
  line-height: 30px;
}
