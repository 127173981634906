.order-history-frame {
  margin-top: 22px;
  width: 840px;
  height: auto;
  font-family: noto-sans-cjk-kr, sans-serif;
  font-weight: 500;
  font-style: normal;
  box-sizing: border-box;
  /* padding: 0% 5% 0% 5%; */
}

.order-history-frame > .order-way-box {
  width: 90%;
  height: auto;
  margin: 0 auto;
}

.order-way-select {
  margin-top: 20px;
  /* margin-bottom: 20px; */
  /* margin: 20px 0; */
  /* padding-top: 20px; */
  /* padding: 20px 0; */
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: center;
  gap: 7%;
  /* border-bottom: 10px solid var(--order-border-bottom-); */
}

/*라디오 버튼*/
.order-way-select > input[id^="barcode-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="barcode-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.order-way-select
  > input[id^="barcode-way0"][type="radio"]:checked
  + label::after {
  content: "충전";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding-bottom: 2px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="barcode-way1"][type="radio"]:checked
  + label::after {
  content: "구매";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding-bottom: 2px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="barcode-way"][type="radio"]:checked
  + label::before {
  display: none;
}

/*라디오 버튼*/
.order-way-select > input[id^="gift-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="gift-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.order-way-select > input[id^="gift-way"][type="radio"] + label::before {
  content: "구매";
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0 0 0 2px black inset;
  padding-bottom: 2px;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
}

.order-way-select > input[id^="gift-way"][type="radio"]:checked + label::after {
  content: "구매";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding-bottom: 2px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="gift-way"][type="radio"]:checked
  + label::before {
  display: none;
}

/* 라디오 버튼 */
.order-way-select > input[id^="ticket-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="ticket-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.order-way-select > input[id^="ticket-way"][type="radio"] + label::before {
  content: "구매";
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0 0 0 2px black inset;
  padding-bottom: 2px;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
}

.order-way-select
  > input[id^="ticket-way"][type="radio"]:checked
  + label::after {
  content: "구매";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding-bottom: 2px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="ticket-way"][type="radio"]:checked
  + label::before {
  display: none;
}

/*라디오 버튼*/
.order-way-select > input[id^="schedule-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="schedule-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.order-way-select > input[id^="schedule-way"][type="radio"] + label::before {
  content: "예약";
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0 0 0 2px black inset;
  padding-bottom: 2px;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
}

.order-way-select
  > input[id^="schedule-way"][type="radio"]:checked
  + label::after {
  content: "예약";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding-bottom: 2px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="schedule-way"][type="radio"]:checked
  + label::before {
  display: none;
}

/*라디오 버튼*/
.order-way-select > input[id^="delivery-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="delivery-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-family: NotoSansCJKkr;
  font-weight: 600;
}

.order-way-select > input[id^="delivery-way"][type="radio"] + label::before {
  content: "장바구니";
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0 0 0 2px black inset;
  padding-bottom: 2px;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
}

.order-way-select
  > input[id^="delivery-way"][type="radio"]:checked
  + label::after {
  content: "장바구니";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding-bottom: 2px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="delivery-way"][type="radio"]:checked
  + label::before {
  display: none;
}

/*라디오 버튼*/

.order-way-select > input[id^="wrap-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="wrap-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.order-way-select > input[id^="wrap-way"][type="radio"] + label::before {
  content: "포장";
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2px;
  box-sizing: border-box;
  font-size: 18px;
  box-shadow: 0 0 0 2px black inset;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 1px #808080 inset;
  font-family: NotoSansCJKkr;
  font-weight: 600;
}

.order-way-select > input[id^="wrap-way"][type="radio"]:checked + label::after {
  content: "포장";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2px;
  box-sizing: border-box;
  font-size: 18px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="wrap-way"][type="radio"]:checked
  + label::before {
  display: none;
}

.order-way-select > input[id^="parcel-way"][type="radio"] {
  display: none;
}

.order-way-select > input[id^="parcel-way"][type="radio"] + label {
  display: inline-block;
  width: 25%;
  height: 100%;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.order-way-select > input[id^="parcel-way"][type="radio"] + label::before {
  content: "택배";
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2px;
  box-sizing: border-box;
  font-size: 18px;
  box-shadow: 0 0 0 2px black inset;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
}

.order-way-select
  > input[id^="parcel-way"][type="radio"]:checked
  + label::after {
  content: "택배";
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2px;
  box-sizing: border-box;
  font-size: 18px;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
}

.order-way-select
  > input[id^="parcel-way"][type="radio"]:checked
  + label::before {
  display: none;
}

.order-detail-text-frame {
  width: 100%;
  height: auto;
}

.order-detail-text-frame ul {
  width: 100%;
  height: 80px;
  padding: 2% 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}

.order-detail-text-frame ul li {
  display: flex;
  flex-direction: row;
}

.order-detail-text-frame ul li > span {
  padding-left: 1%;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 768px) {
  .order-history-frame {
    width: 100%;
    height: 100%;
    /* margin-top: 55px; */
    box-sizing: border-box;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    /* padding: 30px 0; */
    margin: 0 auto;
    /* margin: 70px auto 0 auto; */
  }

  .order-way-select > input[id^="schedule-way"][type="radio"] + label {
    width: 150px;
  }

  .order-way-select > input[id^="parcel-way"][type="radio"] + label {
    width: 150px;
  }

  .order-way-select > input[id^="wrap-way"][type="radio"] + label {
    width: 150px;
  }

  .order-way-select > input[id^="delivery-way"][type="radio"] + label {
    width: 150px;
  }
}

.order-history-frame .order-mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.order-history-frame .order-mainInfo .order-total-count {
  color: #000;

  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  padding: 1em 1em 0;
}

.order-history-frame .order-mainInfo .order-select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 1em;
  box-sizing: border-box;
}

.order-history-frame .order-mainInfo .order-select-container label {
  display: flex;
  flex-direction: column;
}

.order-history-frame .order-mainInfo .order-select-container label input {
  display: none;
}

.order-history-frame .order-mainInfo .order-select-container label > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5em;
}

.order-history-frame .order-mainInfo .order-select-container label > div img {
  content: url("src_assets/icon/check_box_outline_blank.svg");
}

.order-history-frame
  .order-mainInfo
  .order-select-container
  label
  input:checked
  + div
  img {
  content: url("src_assets/icon/check_box.svg");
}

.order-history-frame .order-menu-info-label {
}

.order-history-frame
  .order-menu-info-label
  > input:checked
  + div
  .order-menu-checkbox-img {
  content: url("src_assets/icon/check_box.svg");
}

.order-history-frame .order-menu-info-label .order-menu-checkbox-img {
  content: url("src_assets/icon/check_box_outline_blank.svg");
}
