.daps-ul-list > li > .ticket-list-head
{
   width: 100%;
   display: flex;
   flex-direction: column;
}
 
.ticket-list-head > .ticket-list-name
{
   font-size: 18px;
   font-weight: bold;
   margin-bottom: 20px;
}
/* .daps-ul-list > li > .ticket-list-body
{
   margin-top: 10px;
   display: flex;
   flex-direction: row;
   width: 100%;
} */
/* 낭짜 */
.ticket-list-date{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   height: 40px;
}
.ticket-list-date > span{
   font-size: 16px;
   color: #919191;
}
.ticket-list-date .ticket-list-body{
   display: flex;
   justify-content: flex-end;
   flex-direction: row;
}
.ticket-list-body > li
{
   width: auto;
   margin-right: 5px;
   margin-bottom: 5px;
   height: 40px;
}
/* 버튼 */
.ticket-list-body > li > button
{
 width: 150px;
 height: 100%;
 border: 2px solid #f4f4f4;
 background-color: transparent;
 border-radius: 100px;
 box-sizing: border-box;
 padding-left: 10px;
 cursor: pointer;
 transition: 0.3s;
}
 
.ticket-list-body > li > button:hover
{
 border: 2px solid #ffc938;
}
 
.ticket-list-body > li > .ticket-check-button
{
   background-size: 12%;
   background-position: 12%;
   background-image: url(src_assets/yellowboards.png);
   background-repeat: no-repeat;
   color: #0e0e0e;
 }
 
.ticket-list-body > li > .ticket-delete-button
{
   background-size: 12%;
   background-position: 12%;
   background-image: url(src_assets/trashcans.png);
   background-repeat: no-repeat;
   color: #0e0e0e;
}
/* 리뷰 모달창 */
.ticket-modal-frame
{
   position: fixed;
   top:0px;
   left:0px;
   width: 100%;
   height: 100%;
   background-color: rgba(44, 44, 44,0.3);
   z-index: 1000;
}
 
.ticket-modal-frame > .ticket-modal
{
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%,-50%);
   margin: 0 auto;
   width: 450px;
   min-height: 250px;
   box-sizing: border-box;
   padding: 30px;
   border-radius: 30px;
   background-color: #fff;
  
}
.ticket-modal-frame > .ticket-modal  .ticket-title{
   display: flex;
   justify-content: space-between;
   padding: 5% 5% 2% 5%;
}
.ticket-modal-frame > .ticket-modal  .ticket-title > p{
   color: #0e0e0e;
   font-size: 18px;
   font-weight: bold;
   height: 30px;
   line-height: 30px;
   margin-bottom: 0;
 
}
.ticket-modal-frame > .ticket-modal  .ticket-title > button{
   width: 30px;
   height: 30px;
   line-height: 30px;
   border-radius: 50px;
   border:  2px solid #d2d2d1;
   background-color: transparent;
   color: #d2d2d1;
   font-size: 18px;
   cursor: pointer;
}
.ticket-modal-frame > .ticket-modal  .ticket-title > button:hover{
   background-color: var(--kk-color-hover-light);
}
 
/* 이미지 1개일떄 */
.ticket-img-one{
   max-width: 350px;
   height: 350px;
   margin-left: 5%;
   overflow: hidden;
}
.ticket-modal-frame > .ticket-modal .ticket-img-one img{
   width: 100%;
   height: 100%;
}
/* 이미지 2개일때 */
.ticket-img-two{
   max-width: 350px;
   height: 350px;
   margin-left: 5%;
   border: 1px solid  #ddd;
   background-size: 100%;
   overflow: hidden;
  
}
.ticket-img-two .section-two-one{
   max-width: 350px;
   height: 175px;
   overflow: hidden;
 
}
.ticket-img-two .section-two-one img{
   width: 100%;
   height: auto;
   overflow: hidden;
 
}
 
.ticket-img-two .section-two-two{
   max-width: 350px;
   height: 175px;
   overflow: hidden;
}
.ticket-img-two .section-two-two img{
   width: 100%;
   height: auto;
   overflow: hidden;
}
/* 이미지 3개일떄 */
.ticket-img-three{
   max-width: 350px;
   height: 350px;
   margin-left: 5%;
   border: 1px solid  #ddd;
   background-size: 100%;
   overflow: hidden;
  
}
.ticket-img-three .section-three-top{
   max-width: 350px;
   height: 175px;
   overflow: hidden;
}
.ticket-img-three .section-three-top > img{
   width: 100%;
   height: auto;
  
}
.ticket-img-three .section-three-bottom{
   width: 100%;
   height: 50%;
   display: flex;
}
.ticket-img-three .section-three-bottom .three-bottom-one{
   width: 50%;
   height: 100%;
  
}
.ticket-img-three .section-three-bottom .three-bottom-one img{
   width: 100%;
   height: 100%;
  
}
.ticket-img-three .section-three-bottom .three-bottom-two{
   width: 50%;
   height: 100%;
  
}
.ticket-img-three .section-three-bottom .three-bottom-two img{
   width: 100%;
   height: 100%;
  
}
/* 이미지 4개일떄 */
.ticket-img-four{
   max-width: 350px;
   max-height: 350px;
   margin-left: 5%;
   border: 1px solid  #ddd;
   background-size: 100%;
   overflow: hidden;
   display: flex;
   flex-direction: column;
}
.ticket-img-four .section-four-top{
   width: 100%;
   height: 50%;
   display: flex;
}
.ticket-img-four .section-four-top .four-top-one img{
   width: 100%;
   height: 100%;
  
}
.ticket-img-four .section-four-top .four-top-two img{
   width: 100%;
   height: 100%;
 
}
.ticket-img-four .section-four-bottom{
   width: 100%;
   height: 50%;
   display: flex;
  
}
.ticket-img-four .section-four-bottom .four-bottom-one img{
   width: 100%;
   height: 100%;
  
  
}
.ticket-img-four .section-four-bottom .four-bottom-two img{
   width: 100%;
   height: 100%;
 
}
/* .ticket-img-four .section-four-bottom .four-bottom-one > img{
  
}
.ticket-img-four .section-four-bottom .four-bottom-two > img{
 } */
 
.ticket-modal-frame > .ticket-modal .ticket-text{
   max-width: 350px;
   height: auto;
   padding: 2% 5% 2% 5%;
   overflow: hidden;
   word-break:break-all;
   text-overflow:clip;
 
  
}
.ticket-modal-frame > .ticket-modal .order-history{
   margin: 2% 5% 2% 5%;
   display: flex;
   flex-direction: column;
}
.ticket-modal-frame > .ticket-modal .order-history > p{
  
   height: auto;
  font-weight: bold;
  color: #969696;
  margin-right: 3%;
}
.ticket-modal-frame > .ticket-modal .order-history .order-subtitle{
   height: auto;
  display: flex;
  flex-wrap: wrap;
  
}
.ticket-modal-frame > .ticket-modal .order-history .order-subtitle > span{
   margin-right: 10px;
   font-weight: bold;
   color: #0e0e0e;
}
 
@media screen and (max-width: 650px)
{
 
   .ticket-modal-frame > .ticket-modal
   {
       width: 350px;
       height: auto;
      
   }
 
   .ticket-modal > .ticket-title
   {
       display: block;
       width: 100%;
       font-size: 18px;
       font-weight: bold;
       box-sizing: border-box;
       padding-bottom: 10px;
     
   }
 
   .ticket-modal > .ticket-content
   {
       margin-top: 10px;
       margin-bottom: 10px;
       display: block;
       width: 100%;
       height: auto;
   }
 
   .ticket-content > .ticket-text
   {
       width: 100%;
       white-space: pre-wrap;
   }
 
}
