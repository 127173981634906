.loading-section {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 1000;
}

.loading-section > .loading-box {
  border-radius: 50%;
  position: absolute;
  padding: 21%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(src_assets/kiikey_logo_white.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 9%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulse {
  50% {
    background: white;
  }
}

.cycleBar-loading {
  position: relative;
  top: 50%;
  left: 45%;
  /* text-align: center; */
  /* margin-top: 100px; */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 0.25rem solid rgba(75, 75, 75, 0.2);
  border-top-color: #000;
  animation: spin 1s infinite linear;
  /* margin: 0 auto; */
  transform: translate(-50%, -50%);
  /* margin-top: 300px; */
}

.newloading-section {
  width: 100%;
  height: 80vh;
  text-align: center;
}
.new-loading {
  position: relative;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 40%;
  transform: translate(-50%, -50%);
}

/* .loading-box > img
{
  width: 100%;
  height: 100%;
  animation: loadimgAnime 1.5s linear infinite;
} */

/* @keyframes loadimgAnime {

  0%
  {
    transform: rotate(0deg);
  }
  100%
  {
    transform: rotate(360deg);
  }
  
}

@media screen and (max-width: 768px)
{
  .loading-section > .loading-box
  {
    background-size: 35%;
    padding: 10%;
  }
}
 */
