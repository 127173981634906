.notice-area{
  
    margin: 0 auto;
    width: 90%;
    height: auto;
}
/* 표 */
.notice-area > .notice-list{
    width: 100%;
    height: auto;
    border-top: 1px solid #d2d2d1;
    margin-top: 20px;
    text-overflow: ellipsis;
    overflow: hidden;

}
.notice-list > li{
    width: 100%;
    height: auto;
    padding-top: 20px;
    border-bottom: 1px solid #d2d2d1;
    font-size: 18px;
    color: #0e0e0e;
    cursor: pointer;
    
}
.notice-head{
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
} 

.notice-head .notice-title{
  font-weight: bold;
  height: auto;
  padding: 0 10px;
  word-break:break-all;
}
.notice-head .notice-title > p{
  font-size: 16px;
  color: #919191;
  font-weight: normal;
  
  height: auto;
}
.notice-head .notice-title > span{
  height: auto;
  
  
}
/* .notice-list > li:hover
{
  background-color: var(--kk-color-hover-light);
} */


.notice-list > li > .notice-head
{
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.open-button
{
    float: right;
   width: 45px;
    height:15px;
    border: none;
    background-color: transparent;
    background-image: url(src_assets/allow-button-top-b.png);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
}



.notice-list > li > .notice-body
{
  width: 100%;
  height: auto;
  
}
/* 2022-07-18 김재용 */
.notice-body.dis-none
{
  display: none;
}
.notice-body > .notice-text
{
  display: block;
  width: 100%;
  height: auto;
  background-color: #f3f3f3;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
  white-space: pre-wrap;
  padding: 20px;
  word-break:break-all;
}
@media screen and (max-width: 768px)
{
  .page
  {
    width: 80%;
  }
  .page-list
  {
    justify-content: space-around;
  }
}