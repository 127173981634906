/* 조회기간 버튼 */
.lookup {
  margin: 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
}

.lookup .lookup-list {
  margin-left: 10px;
  width: auto;
  height: 40px;
  display: flex;
}

.lookup-list > input[type="radio"] {
  display: none;
}

.lookup-list > input + label {
  display: inline-block;
  margin-right: 5px;
  width: 82px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 20px;
  transition: 0.3s;
}

.lookup-list > #week[type="radio"] + label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #0e0e0e;
  box-sizing: border-box;
  border: 1px solid #d2d2d1;
  top: 0;
  left: 0px;
  border-radius: 20px;
}

.lookup-list > #week[type="radio"]:checked + label::after {
  content: "일주일";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #fff;
  border-radius: 20px;
  background-color: #000;
}

.lookup-list > #month[type="radio"] + label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #0e0e0e;
  box-sizing: border-box;
  border: 1px solid #d2d2d1;
  top: 0;
  left: 0px;
  border-radius: 20px;
}

.lookup-list > #month[type="radio"]:checked + label::after {
  content: "한달";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #fff;
  border-radius: 20px;
  background-color: #000;
}

.lookup-list > #sixmonth[type="radio"] + label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #0e0e0e;
  box-sizing: border-box;
  border: 1px solid #d2d2d1;
  top: 0;
  left: 0px;
  border-radius: 20px;
}

.lookup-list > #sixmonth[type="radio"]:checked + label::after {
  content: "6개월";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000;
  border-radius: 20px;
}

.lookup-list > #type-self[type="radio"] + label::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #0e0e0e;
  box-sizing: border-box;
  border: 1px solid #d2d2d1;
  top: 0;
  left: 0px;
  border-radius: 20px;
}

.lookup-list > #type-self[type="radio"]:checked + label::after {
  content: "직접입력";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000;
  border-radius: 20px;
}

/* .lookup .btns + label{
    display: inline-block;
    margin-right: 5px;
    width: 82px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    text-align: center;
    border-radius: 20px;
    transition: 0.3s;
}
.btns + label:hover{
    background-color: #000;
    border-radius: 20px;
    border: 0;
    color: #fff;
    transition-duration: 0.1s;
}
.btns[type="radio"]:checked + label{
    background-color: #000;
    border-radius: 20px;
    border: 0;
    color: #fff;
    transition-duration: 0.1s;
    visibility: visible;
}
.btns[type="radio"] + label::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    color: #0e0e0e;
    box-sizing: border-box;
    border: 1px solid #d2d2d1;
    top: 0;
    left: 0px;
    border-radius: 20px;
} 


.btns[type="radio"]:checked  + label::before{
    display: none;
    color: #fff;
}
*/
.lookup h3 {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: bold;
  color: #090909;
}
/* 조회 기간 결과 */
.period {
  margin: 0 auto;
  margin-top: 15px;
  width: 90%;
  height: auto;
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
}

.period > img {
  margin-right: 5px;
}

.period > span {
  margin-right: 20px;
}

.icon > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.period > p {
  width: auto;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  color: #0e0e0e;
  margin-left: 5px;
}

.period > span:nth-child(4) {
  width: 12px;
  margin: 0;
}

/* 사용내역 표 */
caption {
  display: none;
}
.use-list {
  margin: 0 auto;
  width: 90%;
  margin-top: 30px;
  text-align: center;
  border-collapse: collapse;
  height: auto;
  border-top: 4px solid #0e0e0e;
}
.use-list .use-title {
  max-width: 756px;
  height: 60px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #d2d2d1;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.use-list .use-title li {
  width: 25%;
  font-size: 18px;
  font-weight: bold;
}
.use-list .history {
  max-width: 756px;
  height: 60px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #d2d2d1;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.use-list .history li {
  width: 25%;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* 페이지 */
.page {
  width: 400px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.page ul {
  width: 450px;
  height: auto;
  display: flex;
  flex-direction: row;
}
.page ul li {
  width: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  margin: 10px;
  text-align: center;
}
.page ul li:first-child {
  border: 1px solid var(--kk-color-Ymain);
  box-sizing: border-box;
  background-color: var(--kk-color-Ymain);
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 50px;
  line-height: 30px;
}
.page button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid transparent;
  background-color: #fff;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1152px) {
  .mykiikey-frame {
    width: 100%;
  }
  .use-list .history li {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .mykiikey-frame {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .mykiikey-frame > .mykiikey-menu-list-area {
    display: none;
  }
  .mykiikey-frame > .mykiikey-menu-area {
    width: 100%;
  }
  .daps-frame > .daps-paging-list {
    display: none;
  }
  .daps-frame > .mobile-scroll-button {
    display: block;
  }

  /* 조회기간 */
  .lookup h3 {
    font-size: 16px;
  }
  .lookup button {
    width: 70px;
    height: 35px;
    font-size: 16px;
  }
}

/* 조회기간 결과 */

.period > p {
  font-size: 16px;
}
.period > span {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
