.shop-search-frame {
  margin: 0 auto;
  width: 90%;
  height: auto;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}
/* 검색창 */
.shop-search-frame > .category-search-box {
  margin: 0 auto;
  margin-top: 25px;
  width: 95%;
  height: 45px;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  box-shadow: 0 0 0 1px rgb(224, 224, 224);
}

.category-search-box > #category-search-input {
  width: 90%;
  height: 100%;
  border: none;
  box-sizing: border-box;
  padding-left: 10px;
  color: #191919;
}

.category-search-box > .category-search-button {
  width: 50px;
  height: 100%;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/search-img_g.png);
  background-position: center;
  background-size: 45%;
  background-repeat: no-repeat;
  cursor: pointer;
}

/* 대분류 */
.main-category-list-box {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 2%;
}
.main-category-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}
.main-category-list li {
  margin-right: 5%;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
}
.main-category-list li span {
  color: #707070;
  font-size: 20px;
}
.main-category-list li::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 5px;
  background-color: var(--kk-color-Ymain);
  bottom: -3px;
  left: 0;
  display: none;
}
.main-category-list li:hover::after {
  display: block;
}
/* 중분류 */
.middle-category-list-box {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 1%;
}
.middle-category-list {
  width: 100%;
  height: auto;
  padding: 1% 0.5%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f6f6;
}
.middle-category-list li {
  width: auto;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}
.middle-category-list li span {
  width: 100%;
  font-size: 16px;
  color: #707070;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0 20px;
}
.middle-category-list li:hover span {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  color: #fff;
}
/* 소분류 */
.subcategory-list-box {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 2%;
}
.subcategory-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 2%;
}
.subcategory-list li {
  width: auto;
  position: relative;
  text-align: center;
  transition: 0.3s;
  margin: 0 1.5%;
  cursor: pointer;
}
.subcategory-list li span {
  width: 100%;
  font-size: 14px;
  color: #707070;
  box-sizing: border-box;
  border-radius: 12px;
}

.subcategory-list li::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 5px;
  background-color: var(--kk-color-Ymain);
  bottom: -3px;
  left: 0;
  display: none;
}
.subcategory-list li:hover::after {
  display: block;
}

@media screen and (max-width: 768px) {
  .shop-search-frame {
    margin: 0 auto;
    width: 90%;
  }
}
