.shop-menu-list > .shop-menu-select {
  width: 100%;
  height: auto;
}

.shop-menu-select > .shop-menu-select-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 1% 0 1%;
}

.shop-menu-select-list > li {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 0px;
}

.shop-menu-select-list > li > .main-select {
  width: 100%;
  height: 75px;
  padding: 20px 0px 20px 0px;
  display: flex;
}

.shop-menu-select-list > li:first-child {
  border-bottom: 1px dashed gray;
}

.main-select > .list-img-box {
  width: 75px;
  height: 100%;
  position: relative;
}

.list-img-box > img {
  width: 100%;
  height: 100%;
  border-radius: 20%;
}

.main-select > .list-name-box {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 1%;
  padding-left: 2%;
}

.list-name-box > .list-title {
  font-size: 19px;
}

.list-name-box > .list-price {
  font-size: 15px;
  font-weight: 300;
}

.main-select > .item-count-box {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 1%;
  font-weight: 300;
}

.item-count-box > .count {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: right;
  align-items: center;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid #bdbdbd;
  overflow: hidden;
}

.count > button {
  height: 25px;
  color: #bdbdbd;
  /* border-radius: 100%; */
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  /* font-weight: bold; */
}
.count-btn {
  width: 35px;
  height: 35px;
}
.count-num {
  display: flex;
  height: 25px;

  flex-direction: column;
  justify-content: center;
}
.count > .item-count {
  width: 35px;
  height: 30px;
  text-align: center;
  font-size: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-count-box > .sale {
  width: 100%;
  height: 40%;
  color: #fea267;
  font-weight: bold;
  text-align: right;
  font-size: 15px;
  font-weight: 300;
}

.shop-menu-select-list > li > .size-select-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 10px 25px 10px;
}
.size-select-list > span {
  margin-bottom: 5px;
  box-sizing: border-box;
  padding-left: 20px;
  font-weight: 300;
  color: #b8b8b8;
}
.size-select-list > li {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 3px 25px 3px 25px;
}

.size-select-list > li > .size-select {
  font-weight: 300;
  display: flex;
}

.size-select > input {
  width: 21px;
  height: 21px;
}
.size-select > input[type="radio"] {
  accent-color: #f6c116;
}
.size-select > p {
  margin-left: 10px;
}

.size-select-list > li > .size-price {
  font-size: 18px;
}

.option-select {
  padding: 3px 25px 3px 25px;
}

.option-select > span {
  display: block;
  margin-bottom: 10px;
  color: #b8b8b8;
}

.option-select > li {
  display: flex;
  justify-content: space-between;
}

.option-select > li > .option-name {
  font-weight: 300;
}

.option-select > li > .option-price-box {
  display: flex;
  justify-content: left;
}

.option-select > li > .option-price-box > p {
  margin-right: 10px;
  display: block;
  font-size: 18px;
}

.option-select > li > .option-price-box > span {
  display: flex;
}

.option-select > li > .option-price-box > span > p {
  width: 35px;
  height: 30px;
  text-align: center;
  font-size: 19px;
  display: flex;
  font-weight: 300;
  flex-direction: column;
  justify-content: center;
}

.option-select > li > .option-price-box > span > button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid gray;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
}

.shop-menu-select > .kind-option {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.kind-option > h3 {
  margin-bottom: 10px;
}

.kind-option > .kind-option-list {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.kind-option-list > li {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.kind-option-list > li > .kind-name {
  font-weight: 300;
}

.kind-option-list > li > .kind-count-box {
  display: flex;
}

.kind-count-box > button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid gray;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
}

.kind-count-box > .count-number {
  width: 35px;
  height: 30px;
  text-align: center;
  font-size: 19px;
  display: flex;
  font-weight: 300;
  flex-direction: column;
  justify-content: center;
}

.shop-menu-select > .item-native {
  width: 100%;
  height: auto;
  background-color: #f1f0f0;
  box-sizing: border-box;
  font-weight: 300;
  padding: 20px;
}

.shop-menu-select > .menu-nav {
  margin-top: 10px;
  width: 100%;
  height: 30px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.menu-nav > li {
  margin-right: 10px;
  width: 125px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #8e8e8e;
  background-color: #f2f2f2;
  cursor: pointer;
}
.menu-nav > li:hover {
  background-color: #f6c116;
  color: #fff;
}

.origin-note {
  width: 100%;
  margin: 0 auto;
  background-color: #f1f0f0;
}
.origin-note-text {
  margin: 0 auto;
  width: 90%;
  padding-top: 20px;
  padding-bottom: 125px;
}
.origin-note-text p {
  margin: 5px 0;
  font-size: 15.5px;
  font-weight: 300;
  letter-spacing: -0.39px;
  color: #000;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 768px) {
  .menu-nav > li {
    margin: 0 auto;
  }
}
