.join-agreement-frame {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 20%;
}

.agreement-form {
  margin: 0 auto;
  width: 60%;
  height: auto;
  border-radius: 50px;
  background-color: white;
  padding: 70px;
}

.agreement-form > .form-title {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 2px solid rgb(214, 214, 214);
  margin-bottom: 60px;
}

.agreement-box-style {
  margin: 0 auto;
  margin-bottom: 100px;
  width: 100%;
  height: auto;
}

.agreement-box-style > .agreement-title {
  display: block;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.agreement-title > h3 > b {
  color: rgb(255, 0, 0);
}

.agreement-title > .agreement-mobile-button {
  width: 100px;
  height: auto;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/allow-button.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35%;
  display: none;
}

.agreement-box-style > .agreement-text {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 300px;
  overflow: auto;
  color: #050505;
  box-sizing: border-box;
  padding: 20px;
  font-size: 15px;
  border: 1px solid rgba(58, 58, 58, 0.1);
}

.agreement-text > table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  border: 1;
}

.agreement-text > table > tbody > tr > td {
  border: solid 1px #000000;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
}

.agreement-text > table > tbody > tr:first-child {
  background-color: #d9d9d9;
  height: 50px;
  text-align: center;
}

.agreement-text > .WordSection1 > table {
  width: 100%;
  text-align: center;
}

.agreement-text > p {
  line-height: 25px;
}

.agreement-box-style > .agreement-checkbox {
  margin: 0 auto;
  width: auto;
  height: 35px;
  display: flex;
  gap: 20px;
  justify-content: right;
}

.agreement-checkbox > span {
  display: block;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#service-agreement[type="checkbox"] {
  width: 35px;
  height: 35px;
  display: none;
}

#service-agreement[type="checkbox"] + label {
  display: inline-block;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 7px;
  padding-left: 50px;
  overflow: hidden;
  cursor: pointer;
}

#service-agreement[type="checkbox"] + label > span {
  display: none;
}

#service-agreement[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 2px black inset; */
  border-radius: 10px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_off.svg);

  top: 0px;
  left: 0px;
}

#service-agreement[type="checkbox"]:checked + label::after {
  content: "";
  font-size: 21px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

#service-agreement[type="checkbox"]:checked + label::before {
  display: none;
}

#service-agreement[type="checkbox"]:checked + label > span {
  display: block;
}

#privacy-agreement[type="checkbox"] {
  width: 35px;
  height: 35px;
  display: none;
}

#privacy-agreement[type="checkbox"] + label {
  display: inline-block;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 7px;
  padding-left: 50px;
  cursor: pointer;
}

#privacy-agreement[type="checkbox"] + label > span {
  display: none;
}

#privacy-agreement[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 2px black inset; */
  border-radius: 10px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_off.svg);

  top: 0px;
  left: 0px;
}

#privacy-agreement[type="checkbox"]:checked + label::after {
  content: "";
  font-size: 21px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

#privacy-agreement[type="checkbox"]:checked + label::before {
  display: none;
}

#privacy-agreement[type="checkbox"]:checked + label > span {
  display: block;
}

#sms-agreement[type="checkbox"] {
  width: 35px;
  height: 35px;
  display: none;
}

#sms-agreement[type="checkbox"] + label {
  display: inline-block;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 7px;
  padding-left: 50px;
  cursor: pointer;
}

#sms-agreement[type="checkbox"] + label > span {
  display: none;
}

#sms-agreement[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 2px black inset; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 10px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

#sms-agreement[type="checkbox"]:checked + label::after {
  content: "✔";
  font-size: 21px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

#sms-agreement[type="checkbox"]:checked + label::before {
  display: none;
}

#sms-agreement[type="checkbox"]:checked + label > span {
  display: block;
}

#out-agreement[type="checkbox"] {
  width: 35px;
  height: 35px;
  display: none;
}

#out-agreement[type="checkbox"] + label {
  display: inline-block;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 7px;
  padding-left: 50px;
  cursor: pointer;
}

#out-agreement[type="checkbox"] + label > span {
  display: none;
}

#out-agreement[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 2px black inset; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 10px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

#out-agreement[type="checkbox"]:checked + label::after {
  content: "";
  font-size: 21px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_on.svg);

  left: 0px;
  top: 0px;
  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

#out-agreement[type="checkbox"]:checked + label::before {
  display: none;
}

#out-agreement[type="checkbox"]:checked + label > span {
  display: block;
}

.agreement_all_checkbox {
  margin: 0 auto;
  margin-bottom: 20px;
  width: auto;
  height: 35px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.agreement_all_checkbox > span {
  display: block;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#all-agreement[type="checkbox"] {
  width: 35px;
  height: 35px;
  display: none;
}

#all-agreement[type="checkbox"] + label {
  display: inline-block;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 7px;
  padding-left: 50px;
  cursor: pointer;
}

#all-agreement[type="checkbox"] + label > span {
  display: none;
}

#all-agreement[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 2px black inset; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 10px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

#all-agreement[type="checkbox"]:checked + label::after {
  content: "";
  font-size: 21px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_on.svg);

  left: 0px;
  top: 0px;
  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

#all-agreement[type="checkbox"]:checked + label::before {
  display: none;
}

#all-agreement[type="checkbox"]:checked + label > span {
  display: block;
}

@media screen and (max-width: 768px) {
  .join-agreement-frame {
    background-color: #fff;
  }
  .agreement-form {
    width: 95%;
    padding: 0px;
  }
  .agreement-title > .agreement-mobile-button {
    display: block;
  }
}
