.select-event-box {
  margin: 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.select-event-box > .select-event-title {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  height: auto;
  display: flex;
}

.select-event-title > img {
  box-sizing: border-box;
  padding: 5px 0px 5px 0px;
  margin-top: 3px;
  margin-right: 10px;
  height: 100%;
}

.select-event-title > p {
  font-size: 17px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.select-event-box > .select-event {
  width: 100%;
  height: 45px;
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
  border-radius: 5px;
}

.select-event > select {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url(src_assets/select-single-allow.png);
  background-size: 14px;
  background-position: 95%;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 17px;
  font-weight: 600;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-appearance: none;
  outline: 0;
}

.event-list {
  margin: 0 auto;
  width: 90%;
  height: auto;
}

.event-list > .event-to-apply {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 45px;
  /* margin: 0 auto; */
}

.event-to-apply-none {
  display: none;
}

.event-to-apply > .event-to-apply-button {
  width: 100%;
  height: 100%;
  /* margin: 10px 0; */
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 100px;
  background-color: #000;
  cursor: pointer;
}

.event-to-apply-button:hover {
  background-color: var(--kk-color-Ymain);
}

.event-to-apply-button:active {
  background-color: var(--kk-color-active);
}

.event-list > .event-do-control {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
}

.event-do-control-none {
  display: none;
}

.event-do-control > button {
  margin: 0% 2% 0% 2%;
  width: 50%;
  height: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 100px;
  background-color: var(--kk-color-Ymain);
  cursor: pointer;
}

.event-do-control > .event-delete {
  background-color: #b7b7b7;
}
.event-do-control > button:hover {
  background-color: #000;
}
.event-do-control > .event-modify:hover {
  /* background-color: #f6c015; */
  /* background-color: #f5c015; */
  background-color: var(--kk-color-Ymain);
}
.event-do-control > button:active {
  background-color: #000;
}

/* 이벤트 이미지 공간 */
.event-img-wrap {
  width: 100%;
  height: 500px;
  /* margin-top: 20px; */
  overflow: auto;
}
.event-img-wrap .event-content {
  max-width: 756px;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 0px 5% 0px;
  /* overflow-y: scroll; */
  /* background-color: #e2e2e2; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-content > div > img {
  display: block;
  margin: 0 auto;
  /* width: 65%; */
  /* height: 100%; */
  /* height: 100%; */
  max-height: 100%;
}

.event-content > .event-text {
  display: block;
  font-weight: 300;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 10px;
  width: 65%;
  height: auto;
}
.event-img-wrap .event-content::-webkit-scrollbar-thumb {
  background-color: #7d5d34;
  border-radius: 50px;
}
/* .event-img-wrap .event-content::-webkit-scrollbar{background-color: orange;} */
.event-list > .event-list-title {
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;
  text-align: center;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  color: rgb(216, 216, 216);
  box-sizing: border-box;
  padding: 5px 0px 5px 10px;
}

.event-list-title-none {
  display: none;
}

.event-list > .event-list-table {
  display: block;
  width: 100%;
  height: auto;
  border-bottom: 3px solid #000;
  font-size: 13px;
}

.event-list-table > .evnet-list-table-head {
  display: block;
  width: 100%;
  /* height: 35px; */
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  border-collapse: separate;
  border-spacing: 2px;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  font-weight: 600;
}

.evnet-list-table-head > tr {
  display: flex;
  width: 100%;
  height: 100%;
}

.evnet-list-table-head > tr > td:first-child {
  width: 20%;
  text-align: center;
}

.evnet-list-table-head > tr > td:nth-child(2) {
  width: 70%;
  text-align: center;
}

.evnet-list-table-head > tr > td:nth-child(3) {
  width: 50%;
  text-align: center;
}

.event-list-table > tbody {
  display: block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 5px 0px 5px 0px;
}

.event-list-table > tbody > tr {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8px 0px 8px 0px;
  border-bottom: 1px solid rgb(216, 216, 216);
  border-collapse: separate;
  border-spacing: 2px;
  cursor: pointer;
}

.event-list-table > tbody > tr:hover {
  background-color: var(--kk-color-hover-light);
}

.event-list-head {
  display: flex;
  padding: 7px 0;
}

.event-list-head > td:first-child {
  width: 20%;
  text-align: center;
}

.event-list-head > td:nth-child(2) {
  width: 60%;
}

.event-list-head > td:nth-child(3) {
  width: 50%;
  text-align: center;
  white-space: nowrap;
}

.event-list-body {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 10px;
  /* background-color: var(--kk-color-hover-light); */
}
/* 2022-07-19 이상윤
텍스트 와 이미지 들어가는 영역 */

/* 이미지 영역 */
.event-list-body > .event-list-body-img {
  display: flex;
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  justify-content: center;
}

.event-list-body-img-wrap {
  justify-content: center;
  display: flex;
  height: 100px;
  width: 100%;
  gap: 3%;
}

/*이미지가 들어가지 않았을때 */
.event-list-body-img.dis-none {
  display: none;
}

.event-list-body-img > img {
  display: block;
  /* margin: 0 auto; */
  height: 100%;
  max-width: 101px;
}

/* 텍스트 영역 */
.event-list-body > .event-list-text {
  margin-top: 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  word-break: break-all;
  padding: 0px 5% 0px;
  line-height: 130%;
}

/* 2022-07-18 김재용 */
.event-list-body.dis-none {
  display: none;
}

tbody > tr:last-child {
  border: none;
}

.event-enter-modal-frame {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}

.event-enter-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 500px;
  height: 500px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  background-color: #fff;
}

.event-enter-modal > .photo-upload {
  margin-bottom: 5%;
  width: 100%;
  height: 45%;
  display: flex;
}

.photo-upload > .photo-box {
  margin-right: 5%;
  display: block;
  width: 45%;
  box-shadow: 0 0 0 2px #000 inset;
  position: relative;
}
.photo-box > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.photo-upload > #photo-upload-btn {
  display: none;
}

#photo-upload-btn[type="file"] + label {
  width: 100px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #b7b7b7;
  cursor: pointer;
}

#photo-upload-btn[type="file"] + label:hover {
  background-color: #000;
}

.photo-upload > .photo-upload-button {
  width: 100px;
  height: 40px;
  border-radius: 100px;
  border: none;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  cursor: pointer;
}

.photo-upload-button:hover {
  background-color: var(--kk-color-hover);
}

.photo-upload-button:active {
  background-color: var(--kk-color-hover);
}

.event-enter-modal > .text-input {
  margin-bottom: 5%;
  width: 100%;
  height: 30%;
  background-color: red;
}

.text-input > #event-enter-input {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  resize: none;
}

.event-enter-modal > .event-button {
  width: 100%;
  height: 8%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.event-button > button {
  margin: 0% 3% 0% 3%;
  width: 130px;
  height: 100%;
  border-radius: 100px;
  border: none;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
}

.event-button > button:hover {
  background-color: #000;
}

.event-button > button:active {
  background-color: #000;
}

.event-button > .event-cancel-button {
  background-color: #b7b7b7;
}

.event-button > .event-upload-button {
  background-color: var(--kk-color-Ymain);
}
.event-button > .event-upload-button:hover {
  background-color: var(--kk-color-Ymain);
}
/* 이미지모달창 */
.event-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  background-color: rgba(0, 0, 0, 0.301);
}
.event-wrap {
  /* border: 1px solid red; */
  position: fixed;
  /* top: 30%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 90%;
}
.event-wrap img {
  width: 90%;
  display: block;
  margin: 0 auto;
  max-height: 500px;
}
.event-modal-img {
  margin: 0 auto;
}
.dis-none {
  display: none;
}

@media screen and (max-width: 768px) {
  .event-enter-modal {
    width: 350px;
    height: 350px;
  }
}
