.shop-grade-frame {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.shop-grade-frame > .grade-number-box {
  width: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grade-number-box > .grade-number {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 40px;
  margin-bottom: 10px;
}

.grade-number-box > .grade-star {
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.grade-star > img {
  width: 50%;
}

.shop-grade-frame > .grade-bar-box {
  width: 250px;
  height: 100%;
}

.grade-bar-box > .grade-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.progress-bar::-webkit-progress-bar {
  background: #f0f0f0;
  border-radius: 0px;
  box-shadow: inset 3px 3px 10px #ccc;
  height: 6px;
}

.progress-bar::-webkit-progress-value {
  border-radius: 0px;
  background: var(--kk-color-Ymain);
  background: -webkit-linear-gradient(#6dd5ed);
  background: linear-gradient(#6dd5ed);
}

.progress-bar {
  appearance: none;
  transform: translate(0, 30%);
}

.grade-bar > li {
  width: 100%;
  display: flex;
  gap: 2%;
  align-items: center;
}

.grade-bar span:nth-last-of-type(1) {
  color: #8b8b8b;
}
