.id-result {
  width: 100%;
  height: 100vh;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
}

.id-result > .id-result-form {
  margin: 0 auto;
  width: 710px;
  height: auto;
  border-radius: 50px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 70px;
}

.id-result-form > .form-title {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 2px solid rgb(214, 214, 214);
}

.id-result-form > .id-result-area {
  width: 100%;
  height: 300px;
}

.id-result-area > .form-note {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  font-size: 18px;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  font-weight: bold;
}

.id-result-area > .result-text {
  width: 100%;
  height: auto;
}

.id-result-area > .result-text > span {
  display: block;
  width: 100%;
  height: 49px;
  background-color: rgb(236, 236, 236);
  border: 2px solid rgb(223, 223, 223);
  margin-bottom: 15px;
  box-sizing: border-box;
  padding-top: 10px;
  padding-left: 15%;
  padding-right: 15%;
  display: block;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.result-text > span > p {
  font-size: 16px;
  font-weight: bold;
}

.id-result-form > .ps-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 82%;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  border-radius: 50px;
  background-color: #642a1f;
  color: white;
  border: none;
}

.ps-button:hover {
  background-color: var(--kk-color-Ymain);
}

.ps-button:active {
  background-color: #ffda7b;
}

.id-result-form > .login-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 82%;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  border-radius: 50px;
  background-color: #642a1f;
  color: #fff;
  border: none;
}

.login-button > a {
  color: #fff;
}

.login-button:hover {
  background-color: var(--kk-color-Ymain);
}

.login-button:active {
  background-color: #ffda7b;
}

@media screen and (max-width: 768px) {
  .id-result {
    height: auto;
    background-color: #fff;
  }
  .id-result > .id-result-form {
    margin: 0 auto;
    width: 85%;
    padding: 70px 0px 70px 0px;
  }
  .id-result-form > .form-title {
    margin: 0 auto;
    width: 100%;
  }
  .id-result-form > .id-result-area {
    margin: 0 auto;
    height: auto;
  }
  .id-result-area > .result-text {
    width: 100%;
  }
}
