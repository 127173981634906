.popup-frame
{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0,0.7);
    z-index: 999;
}

/*PC형 가로 동영상 */
.popup-frame > .horizontal-video
{
    margin: 0 auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 62vw;
    height: 62vh;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
}

/*PC형 세로 동영상 */
.popup-frame > .verticality-video
{
    margin: 0 auto;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 452px;
    height: 75vh;
    overflow: hidden;
    border-radius: 5px;
    background-color: white;
}

@media screen and (max-width: 768px)
{
    
    /*모바일 가로 동영상 */
    .popup-frame > .horizontal-video
    {
        width: 100vw;
        height: 50vh;
    }
    /*모바일 세로 동영상 */
    .popup-frame > .verticality-video
    {
        width: 100vw;
        height: 88vh;
    }
}
