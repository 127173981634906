.question-form {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
}

.question-form > .question-head {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  border-bottom: 2px solid gold;
  display: flex;
}

.question-head > img {
  margin-top: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.question-head .question-img {
  display: block;
}

.question-head > .question-text {
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  font-size: 21px;
}

.question-form > .question-list {
  margin-top: 30px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.question-list > li {
  margin-bottom: 30px;
}

#q-check-first[type="checkbox"] {
  width: 25px;
  height: 25px;
  display: none;
}

#q-check-first[type="checkbox"] + label {
  display: inline-block;
  width: 300px;
  font-weight: bold;
  height: 25px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 3px;
  padding-left: 50px;
  cursor: pointer;
}

#q-check-first[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 3px black inset; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 5px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

#q-check-first[type="checkbox"]:checked + label::after {
  content: "";
  font-size: 21px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_on.svg);

  left: 0px;
  top: 0px;
  /* background-color: var(--kk-color-Ymain); */
}

/*-----------------------------*/

#q-check-second[type="checkbox"] {
  width: 25px;
  height: 25px;
  display: none;
}

#q-check-second[type="checkbox"] + label {
  display: inline-block;
  width: 300px;
  font-weight: bold;
  height: 25px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 3px;
  padding-left: 50px;
  cursor: pointer;
}

#q-check-second[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 3px black inset; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 5px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

#q-check-second[type="checkbox"]:checked + label::after {
  content: "";
  font-size: 21px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  /* background-color: var(--kk-color-Ymain); */
  background-image: url(src_assets/check_on.svg);
}

/*-----------------------------*/

#q-check-third[type="checkbox"] {
  width: 25px;
  height: 25px;
  display: none;
}

#q-check-third[type="checkbox"] + label {
  display: inline-block;
  width: 300px;
  font-weight: bold;
  height: 25px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding-top: 3px;
  padding-left: 50px;
  cursor: pointer;
}

#q-check-third[type="checkbox"] + label::before {
  content: "";
  /* box-shadow: 0 0 0 3px black inset; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 5px;
  font-size: 21px;
  font-weight: bold;
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

#q-check-third[type="checkbox"]:checked + label::after {
  content: "✔";
  font-size: 21px;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  width: 35px;
  height: 35px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* background-color: var(--kk-color-Ymain); */
}

@media screen and (max-width: 768px) {
  .question-form {
    width: 95%;
  }
}
