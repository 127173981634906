.order-menu-list-frame {
  margin-right: 20px;
  width: 430px;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 5px 0px 5px;
  margin-bottom: 50px;
}

.order-search-box-wrap {
  /* position: fixed;*/
  bottom: 0;
  width: 100%;
  height: 60px;
  min-height: 60px;
  padding: 10px 0px;
  background-color: #fff;
  z-index: 99;
  box-sizing: border-box;
  /* left: 5%; */

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}
.container {
  display: flex;
  align-items: center;
}
.reservation-date > .container {
  flex-direction: column;
}
.reservation-date > .container > .week {
  width: 100%;
}
.order-menu-list-frame .order-search-box {
  /* display: fixed; */
  /* bottom: 0; */
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* flex-shrink: 0; */
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 31px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 2px #0000000d;
  /* padding: 50px 0; */
}

.order-search-button {
  min-width: 30px;
  min-height: 30px;

  background-color: transparent;
  background-image: url(src_assets/pageview.svg);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
}

.order-search-box > #order-search {
  background: none;
  border: none;
  color: #bdbdbd;
  width: 222px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 16px;
}
::placeholder {
  color: #a9a9a9;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.order-search-box > #order-search:focus {
  outline: none;
}

.order-menu-list-frame > .popular-search-result {
  margin-top: 10px;
  border-bottom: 10px solid #f5f6f6;
  width: 100%;
  height: 40px;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  display: none;
}

.popular-search-result.clicked {
  height: auto;
}

.popular-search-result > .popular-result {
  width: 65%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.popular-result > .popular-name {
  width: 100px;
  box-sizing: border-box;
}

.popular-result > .popular-list {
  width: 150px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.popular-list > li {
  height: 45px;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  cursor: pointer;
}

.popular-list > li:hover {
  color: #000;
  font-weight: 600;
}

.popular-list > li > .list-number {
  display: block;
  width: 20px;
  height: 100%;
  margin-right: 3%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popular-list > li > .pop-name {
  display: block;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popular-search-result > .popular-date-box {
  box-sizing: border-box;
  width: auto;
  height: 50px;
  display: flex;
}

.popular-date-box > .popular-date-img {
  max-width: 20px;
  height: 20px;
}

.popular-date-img > img {
  width: 100%;
  height: 100%;
}

.popular-date-box > .popular-date {
  margin-left: 10px;
  font-size: 14px;
}

.popular-list {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.popular-list > li {
  width: 100%;
  height: auto;
  display: flex;
  color: #858585;
}

.popular-search-result > .date-check {
  margin-top: 12px;
  display: flex;
  width: auto;
  height: 20px;
}

.date-check > .date-change-button {
  margin-right: 5px;
  width: 20px;
  height: 100%;
  background-color: transparent;
  background-image: url("src_assets/date-time.png");
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
}

.order-menu-list-frame > .popular-menu-box {
  margin-top: 10px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-evenly;
  display: none;
}

.popular-menu-box > li {
  width: 90px;
  height: 100%;
  background-color: var(--kk-color-Ymain);
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
}

.popular-menu-box > li:hover {
  background-color: var(--kk-color-hover-light);
}

.order-menu-list {
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px #bdbdbd;
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
}
.frameDiv {
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
}

.order-menu-list > li {
  top: 6px;
  left: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 13px;
  cursor: pointer;
}

.order-menu-list > li:hover > .order-list-head > .list-head-text::after {
  /* display: block; */
}
/* .order-menu-list > li > .order-list-head > .list-head-text.textline::before {
  display: block;
} */

.order-menu-list > li > .order-list-head {
  margin-bottom: 5px;
  display: block;
  width: 95%;
  margin: 0px auto;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
  /* padding: 0px 10px 0px 10px; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eae8e7;
}

.order-list-head > .list-head-text {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  font-family: "Noto Sans KR", sans-serif;
}

.list-head-text::after {
  display: none;
  content: "";
  position: absolute;
  top: 25px;
  left: 0px;
  width: 100%;
  height: 9px;
  border-radius: 100px;
  background-color: #fee900;
  transition: 0.3s;
  z-index: -100;
}
/* 
.list-head-text.textline {
  position: relative;
  width: 80px;
  height: 50px;
  line-height: 15px;
} */
.list-head-text.textline p {
  position: absolute;
  width: auto;
  background-image: url(src_assets/drawable/line.png);
  background-size: 100% 10px;
  background-position: 100% 8px;
  background-repeat: no-repeat;
  /* background-color:#fee900; */
}
.list-head-text p {
  font-size: 17px;

  /* display: none; */
}

.textline {
  border-radius: 100px;
  /* background: linear-gradient(to top, #fee900 50%, transparent 50%); */
  /* background: #fee900 50%; */
  line-height: 15px;
  /* transition: 0.3s; */
  background-image: url(src_assets/line.png);
  background-size: 100% 23px;
  background-position: 0 6px;
  background-repeat: no-repeat;
  font-family: "noto-sans-cjk-kr";
  font-size: 20px;
}

/* .list-head-text > p
{
  position: relative;
  width: auto;
  height: auto;
} */

.order-list-head > .order-list-button {
  width: 30px;
  height: 100%;
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.order-list-button > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25%;
}

.order-menu-list > li > .menu-list-datail {
  /* margin-top: 14px; */
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  flex-wrap: wrap;
  /* padding-left: 9px; */
}

/* 2022-07-18 김재용 */
.order-menu-list > li > .menu-list-datail.dis-none {
  width: 100%;
  height: auto;
  display: none;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.menu-list-datail > li {
  margin: 3px;
  float: left;
  /* width: 94px; */
  gap: 5%;
  width: 23%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

/* .menu-list-datail > li:hover
{
  background-color: rgba(238, 238, 238, 0.6);
} */

.menu-list-datail > li > .detail-img {
  width: 100%;
  height: 60px;
  position: relative;
  /* box-sizing: content-box; */
  /* border: 1px solid red; */
}

.detail-img > img {
  /* border-radius: 100%;*/
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px solid red; */
}

.menu-list-datail > li > span {
  /* margin-top: 1px; */
  display: flex;
  /* height: 100px; */
  width: 100%;
  font-size: 14px;
  letter-spacing: -1px;
  text-align: center;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  justify-content: center;
  align-items: center;
  /* box-sizing: content-box; */
  /* font-family: NotoSansCJKkr; */
}
.menu-list-datail > li span {
  display: block;
  line-height: 20px;
  /* margin-top: 1px; */
  height: 20px;
  /* display: flex; */
  /* height: 100px; */
  width: 100%;
  font-size: 13px;
  /* letter-spacing: -1px; */
  text-align: center;
  font-weight: 300;
  /* font-stretch: normal; */
  /* font-style: normal; */
  /* justify-content: center; */
  /* align-items: center;s */
  /* box-sizing: content-box; */
  /* font-family: NotoSansCJKkr; */
  /* border: 1px solid red; */
}
/* .menu-list-detail
{
  box-sizing: border-box;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.menu-list-detail > tbody
{
  width: 100%;
  height: 100%;
}

.menu-list-detail > tbody > tr
{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: left;
  gap: 1%;
}

.menu-list-detail > tbody > tr > td
{
  width: 75px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-list-detail > tbody > tr > td:hover
{
  background-color: #dfdfdf;
}


.menu-list-detail > tbody > tr > td > .detail-img
{
  width: 100%;
  height: 65px;
  position: relative;
} 

.detail-img > img
{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  height: 100%;
  border-radius: 100%;
}
*/

.menu-list-detail > tbody > tr > td > span {
  margin-top: 5px;
  font-size: 11px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .order-menu-list-frame {
    margin: 0 auto;
    /* margin-top: 80px; */
    width: 100%;
    border-right: none;
    padding: 0;
  }

  .order-menu-list-frame.kapp {
    margin-top: 10px;
  }

  .order-menu-list-frame > .popular-menu-box {
    justify-content: center;
    gap: 1%;
  }
  .menu-list-datail > li {
    margin: 1px;
    font-family: "Noto Sans KR", sans-serif;
    /* font-size: 20px; */
    /* margin: 0 auto; */
  }
  .menu-list-detail > tbody > tr {
    justify-content: left;
    margin-bottom: 10px;
    height: auto;
  }
  .menu-list-detail > tbody > tr > td {
    width: 20%;
    height: 100%;
  }
  .menu-list-detail > tbody > tr > td > .detail-img {
    height: 15vw;
  }
  .menu-list-detail > tbody > tr > td > span {
    font-size: 2.5vw;
  }
  .menu-list-detail {
    align-content: space-between;
    padding-left: 21px;
  }
}

@media screen and (max-width: 400px) {
  .menu-list-datail > li {
    /* margin: 3px;
    float: left;
    width: 94px;
    height: 110px; */
    /* width: 66px; */
  }
  .menu-list-datail > li > span {
    font-size: 13px;
  }
}
.cateName {
  width: 170px;
  height: 30px;
  position: relative;
  font-size: 18px;
  line-height: 100%;
  font-weight: 500;
  font-family: Inter;
  color: #000;
  text-align: left;
  display: inline-block;
}
.adv52Icon {
  width: 100%;
  border-radius: 8px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  top: 40px;
}
.adv53Icon {
  width: 100%;
  position: relative;
  border-radius: 8px;
  max-width: 100%;
  overflow: hidden;
  height: 81px;
  object-fit: cover;
  top: 80px;
}
.moreSee1 {
  width: 100%;
  position: relative;
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  font-family: Inter;
  color: #828282;
  text-align: right;
  align-items: right;
  justify-content: center;
  height: 19px;
}
.moreSee {
  width: 64px;
  position: relative;
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  font-family: Inter;
  color: #828282;
  text-align: center;
  display: inline-block;
}
