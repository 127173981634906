.order-waiting-frame
{
  margin-top: 22px;
  width: 840px;
  height: auto;
  font-family: noto-sans-cjk-kr, sans-serif;
  font-weight: 500;
  font-style: normal;
  position: relative;
}

.order-waiting-box
{
  margin: 0 auto;
  width: 350px;
  height: auto;
}

.order-waiting-box > .progress-view
{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.progress-view > .under-bar
{
  display: block;
  width: 6px;
  height: 100%;
  position: relative;
}

.under-bar::after
{
  content: '';
  position: absolute;
  bottom: 8px;
  left:50%;
  transform: translate(-50%);
  width: 100%;
  height: 2px;
  background-color: #bdbcb9;
}



.progress-view > li
{
  width: 24%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.progress-view > li > .progress-img-box
{
  width: 100%;
  height: 80%;
  position: relative;
}

.progress-img-box > img
{
  height: 50%;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}


.progress-view > li > span
{
  display: block;
  width: 100%;
  text-align: center;
}

.order-waiting-box > .progress-main
{
  margin-top: 50px;
  width: 100%;
  height: auto;
}

.progress-main > .main-img
{
  display: flex;
  width: 100%;
  height: 200px;
  justify-content : center;
}

.progress-main > .main-img > img
{
  height: 200px;
  object-fit: cover;
}

.progress-main > .img-div
{
  width: 100%;
  height: 200px;
  /* background-image: url(src_assets/progress-main-img.png); */
  background-repeat: no-repeat;
  background-position: center;
}

.progress-main > .progress-note
{
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.order-waiting-box > .order-cancle
{
  margin: 0 auto;
  margin-top: 30px;
  display: block;
  width: 50%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #b8b7b7;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.order-cancle:hover
{
  background-color: var(--kk-color-hover);
}

.order-cancle:active
{
  background-color: var(--kk-color-active);
}


@media screen and (max-width: 1280px)
{




}


@media screen and (max-width: 768px)
{
  .order-waiting-frame
  {
    margin-top: 55px;
    width: 100%;
  }
  .order-waiting-box
  {
    width: 100%;
  }
  .order-waiting-box > .progress-view
  {
    width: 100%;
  }

}



