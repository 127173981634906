.nav-section {
  height: auto;
}
.mobile-nav > .nav-section > a {
  display: block;
}

.nav-ol {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}

.nav-ol > li {
  margin-left: 2%;
  margin-right: 2%;
  width: 100px;
  min-width: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-ol > li > a {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 300;
  color: #b05a16;
  font-weight: bold;
}

.nav-ol > li > a:hover {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.nav-ol > li > .mobile-mykiikey-daps {
  margin-left: 5%;
  margin-top: 2%;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.mobile-mykiikey-daps > li {
  flex-shrink: 0;
  display: block;
  margin: 1%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% / 3);
  text-align: center;
  border-radius: 20px;
  background-color: rgb(92, 92, 92);
}

.mobile-mykiikey-daps > li > a {
  color: #fff;
}

.mobile-mykiikey-daps > li > a:hover {
  color: var(--kk-color-Ymain);
}

.nav-section > .login-box {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
  height: 100%;
  font-weight: bold;
}

.nav-section > .join-in-box {
  display: none;
}

.login-box > a {
  color: #b05a16;
}

.login-box > a :hover {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .nav-section {
    margin-left: 3%;
    padding-right: 3%;
  }
  .mobile-nav > .nav-section > a {
    display: none;
  }
  .nav-ol {
    margin-top: 10%;
    flex-direction: column;
    box-sizing: border-box;
  }

  .nav-ol > li {
    margin: 0px;
    margin-top: 5%;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }
  .nav-ol > li > a {
    color: #fff;
    text-align: left;
  }
  .nav-section > .login-box {
    display: none;
    margin-top: 25px;
    border-top: 1px solid #fff;
    padding-top: 30px;
    width: 100%;
    height: 100%;
  }

  .nav-section > .join-in-box {
    display: block;
    margin-top: 25px;
    border-top: 1px solid #fff;
    padding-top: 30px;
    width: 100%;
    height: 100%;
  }
  .join-in-box > a {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
}
