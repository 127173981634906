.wrap{
    max-width: 760px;
    height:auto;
}
.designer{
    max-width: 760px;
    height: 130px;
    margin-top: 30px;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
}
/* 시은수석디자이너 타이틀 */
.designer > p{
    font-size: 18px;
    border: 1px solid transparent;
    width: auto;
    font-weight: bold;
    position: absolute;
    top: -15px;
    left: 20px;
    background-color: #fff;
    text-align: center;
}
/* 회색박스 */
.designer .designer-img{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}
/* 시은 이미지 */
.designer .designer-img{
    width: 120px;
    height: auto;
}
.designer .designer-img .picture{
    width: 86px;
    height: 86px;
    margin: 0 0px 20px 20px;
}
.designer .designer-img .picture > img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
/* 소개 */
.designer .introduce{
    width: 82%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 10px;
    word-break:break-all;
}
.designer .introduce > ul{
    display: flex;
    flex-direction: column;
}
.designer .introduce > ul > li{
    font-size: 14px;
    padding-bottom: 10px;
    color: #b0b0b0;

}
/* .designer .introduce > ul:last-child{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60px;
}
.designer .introduce > ul:last-child > li{
    color: #0e0e0e;
} */

@media screen and (max-width: 1152px){
    .designer .introduce{
        width: 60%;
    }
}
@media screen and (max-width: 768px)
{
    .designer .introduce{
        width: 60%;
    }
}