.menu-type-check {
  width: 100%;
  height: auto;
  margin: 20px auto;
  border-bottom: 10px solid #f5f6f6;
}
.menu-type-check:nth-last-child(2) {
  border-bottom: 0px solid #f5f6f6;
}

.menu-type-check-content {
  width: 90%;
  margin: 0 auto;
}

.menu-type-check > .shop-menu-check-list {
  height: auto;
  display: flex;
  flex-direction: column;
}

.shop-menu-check-list > li {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  /* padding: 20px 0px 20px 0px; */
  border-bottom: 1px solid #eae8e7;
}
.shop-menu-check-list > li:nth-last-child(1) {
  border-bottom: 0 solid red;
}

.shop-menu-check-list > li > .shop-menu-head {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-menu-head > .menu-info {
  width: 90%;
  min-height: 50px;
  height: auto;
  display: flex;
  box-sizing: border-box;

  overflow: hidden;
}

.menu-info > .menu-info-img {
  display: block;
  width: 75px;
  height: 75px;
  /* margin-right: 3%; */
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.menu-info > .menu-info-img > img {
  width: 75px;
  height: 75px;
}
/* 가게가 영업중이면 display:none 으로 변경 */
.menu-info-img > .menu-preparing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
}
.menu-preparing.none {
  display: none;
}

.menu-info-img > img {
  width: 100%;
  border-radius: 15px;
}

.menu-info > .menu-name-box {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  flex: 1;
}

.menu-name-box > .menu-name {
  font-size: 20px;
  font-weight: 500;
}

.shop-menu-head > .menu-function {
  margin-right: 17px;
  display: block;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/**/

.menu-function > input[type="checkbox"] {
  display: none;
}
input [type="checkbox"] {
  -webkit-appearance: none !important;
  -moz-appearance: none;
  appearance: none;
}

input [type="checkbox"] + label {
  -webkit-appearance: none !important;
}

.menu-function > input[type="checkbox"] + label {
  -webkit-appearance: none !important;

  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.menu-function > input[type="checkbox"] + label::before {
  content: "";
  -webkit-appearance: none !important;

  color: #e7e7e7;
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain); */
  border-radius: 2px;
  border-bottom: none;
}

.menu-function > input[type="checkbox"]:checked + label::after {
  content: "";
  /* accent-color: #fff; */
  /* color: rgba(255, 255, 255, 1); */
  width: 20px;
  height: 20px;
  /* color: #000; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_on.svg);
  /* background-image: url(src_assets/list-delete-button.png); */

  top: 0px;
  left: 0px;
  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain); */
  /* background-color: var(--kk-color-Ymain); */
}

.menu-function > input[type="checkbox"]:checked + label::before {
  display: none;
}

/**/

.shop-menu-check-list > li > .menu-note {
  padding: 5px 0 0 5px;
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 300;
  color: #767676;
  width: 90%;
  line-height: 20px;
}

.menu-note-note {
  display: none;
}

/*라디오 종류*/
.option-radio {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  padding: 25px 10px 25px 10px;
  border-top: 2px dashed var(--order-border-bottom-);
  /* border-bottom: 2px dashed var(--order-border-bottom-); */
}

/* 2022-07-18 김재용 */
.option-radio.dis-none {
  display: none;
}
.option-radio > li {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  gap: 5px;
  box-sizing: border-box;
  padding: 3px 25px 3px 10px;
}

.option-radio > li > .radio-select-box {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.radio-select-box > input {
  margin-top: 2px;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: white;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  /* vertical-align: middle; */
}
[type="radio"]:checked {
  /* border: 0.4em solid tomato; */
  border: 5px solid #fff;
  background-color: var(--kk-color-Ymain);
  box-shadow: 0 0 0 1px var(--kk-color-Ymain);
}

.option-radio > li > .radio-price {
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}

/*수량 종류*/

.option-add {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  padding: 25px 10px 25px 10px;
  border-top: 2px dashed var(--order-border-bottom-);
  border-bottom: 2px dashed var(--order-border-bottom-);
}
.option-add:nth-last-child(1) {
  border-bottom: 0px dashed var(--order-border-bottom-);
}
/* 2022-07-18 김재용 */
.option-add.dis-none {
  display: none;
}

.option-add > li {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-weight: 300;
  box-sizing: border-box;
  padding: 3px 25px 3px 10px;
  align-items: center;
}

.option-add > li > .menu-item-add-name {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.option-add > li > .menu-item-add-name > span:last-child {
  min-width: 62px;

  display: flex;
  flex-wrap: nowrap;
}
.option-add > li > .menu-item-more {
  width: auto;
  display: flex;
}

@media screen and (max-width: 1280px) {
  .menu-article {
    height: 170px;
  }
  .menu-info > .menu-info-img {
    width: 80px;
    height: 70px;
    /* margin-right: 5%; */
  }
  .menu-info > .menu-info-img > img {
    width: 65px;
    height: 65px;
  }
  .menu-name-box > .menu-name {
    font-size: 16px;
  }
  .menu-name-box > p:last-child {
    font-size: 14px;
  }
  .menu-info-img > .menu-preparing {
    width: 65px;
    height: 65px;
    border-radius: 15px;
  }
  .option-add > li > .menu-item-add-name {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .menu-article {
    height: 170px;
  }
  .menu-info > .menu-info-img {
    /* width: 65px; */
    /* height: 65px; */
    /* border: 1px solid green; */
  }
  .menu-info > .menu-info-img > img {
    box-sizing: border-box;
    border: 1px solid #eae8e7;
    /* width: 90%; */
    /* height: 90%; */
  }
  .menu-name-box > .menu-name {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .menu-name-box > p:last-child {
    font-size: 13px;
  }
  .menu-info-img > .menu-preparing {
    box-sizing: border-box;
    /* width: 60px; */
    /* height: 60px; */
    border-radius: 15px;
  }
  .option-add > li > .menu-item-add-name {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
