.adscrap-title-box {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scarp-list-delete {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 100px;
  border: 2px solid #d2d2d1;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  cursor: pointer;
}

.scarp-list-delete:hover {
  border: 2px solid #d1d1d1;
  background-color: #f4f4f4;
}

.adscrap-info-box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.adscrap-info-box > span {
  font-size: 16px;
  color: #919191;
}
.adscrap-button-list {
  width: auto;
  display: flex;
  justify-content: space-around;
}

.adscrap-button-list > li {
  width: auto;
  height: 40px;
}

.adscrap-button-list > li > button {
  width: 150px;
  height: 40px;
  border: 2px solid #f4f4f4;
  background-image: url(src_assets/scarp.png);
  background-repeat: no-repeat;
  background-position: left 5px center;
  border-radius: 100px;
  background-color: #fff;
  font-weight: bold;
  margin-left: 10px;
  color: #0e0e0e;
  padding-left: 25px;
  font-weight: normal;
  cursor: pointer;
  transition: 0.3s;
}

.adscrap-button-list > li > button:hover {
  border: 2px solid var(--kk-color-Ymain);
}

.adscrap-button-list > li > .adscrap-share {
  background-image: url(src_assets/sharing.png);
  background-repeat: no-repeat;
  background-position: left 25px center;
}

.adscrap-button-list > li > .adscrap-video {
  background-image: url(src_assets/video.png);
  background-repeat: no-repeat;
  background-position: left 25px center;
}

.adscrap-button-list > li > .adscrap-adinfo {
  background-image: url(src_assets/info.png);
  background-repeat: no-repeat;
  background-position: left 25px center;
}
/* 공유하기 */
.share-modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}
.share-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  width: 570px;
  height: 300px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  padding: 23px 23px;
}
.share-modal ul {
  width: 100%;
  display: flex;
  margin-left: 20px;
  /* justify-content: center; */
}
.share-modal ul:first-child {
  margin-bottom: 30px;
}
.share-modal ul li {
  width: 77px;
  height: 77px;
  font-size: 14px;
  margin-right: 10px;
  text-align: center;
}
.share-modal ul li img {
  width: 77px;
  height: 77px;
}
/* url박스 */
.url-box {
  width: 490px;
  height: 50px;
  display: flex;
  margin: 50px 0 0 20px;
}
.url {
  display: inline-block;
  width: 218px;
  width: 405px;
  height: 25px;
  height: 50px;
  padding: 0 9px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #222;
  line-height: 50px;
  vertical-align: middle;
  text-decoration: none !important;
  background-color: #f6f6f6;
}

.url-box .url-copy-button {
  display: inline-block;
  height: 25px;
  height: 50px;
  width: 85px;
  margin-left: -1px;
  left: -2px;
  padding: 0 7px 0 7px !important;
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  text-decoration: none !important;
  vertical-align: middle;
  background-color: var(--kk-color-Ymain);
  color: #fff;
  font-weight: bold;
}
.share-delete-btn {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 100px;
  border: 2px solid #d2d2d1;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  cursor: pointer;
  margin-left: 92%;
}
.share-delete-btn:hover {
  border: 2px solid #d1d1d1;
  background-color: #f4f4f4;
}

@media screen and (max-width: 768px) {
  .adscrap-info-box {
    flex-direction: column;
  }
  .adscrap-button-list {
    margin-top: 5px;
    justify-content: right;
    /* flex-wrap: wrap; */
  }

  .adscrap-button-list > li > button {
    width: 110px;
    height: 40px;
  }
}
