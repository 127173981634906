.event-enter-frame {
  /* display: table; */
  width: 90%;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: none;
  font-family: NotoSansCJKkr;
}

.event-enter-frame .field {
  /* display: table-row; */
  margin: 15px 0;
}

.event-enter-frame b {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  /* font-weight: 600; */
  color: #0e0e0e;
}

.event-enter-frame input:focus {
  border: 1px solid var(--kk-color-Ymain);
  outline: none;
}
.event-enter-frame select:focus {
  /* border: 1px solid #eaeaea; */
  outline: none;
  border: 1px solid var(--kk-color-Ymain);
}

.placehold-text {
  display: flex;
  height: 36px;
  /* border-radius: 6px; */
  border: 1px solid #eaeaea;
  align-items: center;
  justify-content: space-evenly;
}

.placehold-text input {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
  font-size: 16px;
}

.placehold-text input[type="tel"] {
  text-align: center;
}
.placehold-text select {
  height: 100%;
}

.placehold-text .add-search {
  margin: 0;
  height: 100%;
  border-radius: 6px;
}

.event-enter-frame input[type="radio"] {
  display: none;
}

.event-enter-frame label {
  display: flex;
  width: 48%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px #eaeaea inset;
  font-weight: bold;
}

.event-enter-frame input[type="radio"]:checked + label {
  box-shadow: 0 0 0 1px var(--kk-color-Ymain) inset;

  /* color: #fff; */
  /* background-color:var(--kk-color-Ymain); */
}

#event-story {
  height: 100px;
  padding: 10px 0;
}

#event-story textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px 10px;
  border-radius: 6px;
  resize: none;
  font-family: NotoSansCJKkr;
  font-size: 16px;
}
#event-story textarea:focus {
  outline: 0;
  border: 1px solid var(--kk-color-Ymain);
}

#photo-upload-btn[type="file"] {
  display: none;
}

.event-enter-frame .photo-field {
  display: flex;
  width: 100%;
  align-items: flex-end;
  /* box-shadow: 0 0 0 2px #000 inset; */
}

.event-enter-frame .photo-box {
  width: 20%;
  padding-bottom: 20%;
  position: relative;
  box-shadow: 0 0 0 2px #eaeaea inset;
  margin-right: 10px;
}

.event-enter-frame .photo-box > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.event-enter-frame .photo-box > #loading-img{
  width: 50%;
  height: 50%;
  position: absolute;
  animation: spin 1s infinite linear;
  top: 25%;
  left: 25%;
  transform: translate(-50%, -50%);
}
.event-enter-frame #photo-upload-btn[type="file"] + label {
  border-radius: 100px;
  background-color: #000;
  margin: 0;
  width: 100px;
  height: 29px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 0 0;
}
.photo-upload-btn-label span {
  font-size: 20px;
  /* margin-bottom: 3px; */
  font-weight: 500;
}

#event-note {
  width: 98%;
  height: auto;
  margin: auto;
  background-color: #e5e5e5;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  font-size: 12px;
  color: #606060;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
}

.event-enter-frame #event-note p {
  /* width: 100%; */
  width: 90%;
  margin: auto;
  line-height: 130%;
}

.submit-field {
  display: flex;
  justify-content: center;
}

.submit-field > button {
  margin: 0% 3% 0% 3%;
  width: 140px;
  height: 100%;
  border-radius: 100px;
  border: none;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
  padding: 8px 0;
}

.submit-field > .event-cancel-button {
  background-color: #000;
}

.submit-field > .event-upload-button {
  background-color: var(--kk-color-Ymain);
}
.placehold-text-addr {
  display: flex;
  height: 36px;
  /* border-radius: 6px; */
  /* border: 1px solid #eaeaea; */
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.event-sns-select {
  border: 1px solid #eaeaea;
  height: 100%;
  width: 26%;
  background: #fff;
  /* text-align: center; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(/src/src_assets/input-button.png) no-repeat 93% 56%;
  background-size: 10px 8px;
  padding: 0 5px;
}
.placehold-text-addr .event-sns-select {
  height: 100%;
  border: 0;
  background: #fff;
  border: 1px solid #eaeaea;
  width: 48%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  -webkit-appearance: none; /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
  background: url(/src/src_assets/input-button.png) no-repeat 93% 56%;
  background-size: 10px 8px;
  /* 화살표 모양의 이미지 */
}
.event-sns-select::-ms-expand {
  display: none;
}
/* .placehold-text-addr input {
  padding: 0 10px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  width: 100%;
} */

.placehold-text-addr input:focus {
  /* border-color: #0982f0; */
  /* outline: none; */
}
/* SNS 계 부분 */
.placehold-text-sns {
  display: flex;
  height: 36px;
  /* border-radius: 6px; */
  /* border: 1px solid #eaeaea; */
  align-items: center;
  justify-content: space-between;
}
.placehold-text-sns input {
  padding: 0 10px;
  height: 100%;
  box-sizing: border-box;
  /* border-radius: 6px; */
  border: 1px solid #eaeaea;
  /* border: none; */
  font-size: 16px;
  width: 73%;
}

.placehold-text-sns select {
  height: 100%;
}
