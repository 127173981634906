.login {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 20%;
  padding-bottom: 20%;
  font-family: "Nanum Gothic";
}

.login > .login-form {
  margin: 0 auto;
  width: 710px;
  height: auto;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 70px;
}

.login-form > .login-logo {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login-form > .login-input {
  margin: 0 auto;
  margin-top: 50px;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.login-input > span {
  display: block;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 49px;
}

.login-id {
  margin-bottom: 15px;
}

.login-id > label {
  width: 100px;
  height: 100%;
  line-height: 50px;
}

.login-id > #loginId {
  width: 100%;
  border-radius: 100px;
  border: 2px solid #ffedbe;
  box-sizing: border-box;
  padding-left: 15px;
  /* outline-color: blue; */
}

.login-password > label {
  width: 100px;
  height: 100%;
  line-height: 50px;
}

.login-password > #loginPw {
  width: 100%;
  border-radius: 100px;
  border: none;
  border: 2px solid #ffedbe;
  padding-left: 15px;
}

.login-form > .account-support {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.account-support > li {
  padding: 0px 8px 0px 8px;
  border-right: 1px solid gray;
}

.account-support > li:last-child {
  border-right: none;
}

.login-form > .login-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 82%;
  height: 50px;
  font-size: 21px;
  font-weight: bold;
  border-radius: 50px;
  background-color: #642a1f;
  color: white;
  border: none;
}

.login-button:hover {
  background-color: var(--kk-color-Ymain);
}

.login-button:active {
  background-color: #ffda7b;
}

@media screen and (max-width: 768px) {
  .login {
    height: auto;
    background-color: #fff;
  }
  .login > .login-form {
    margin: 0 auto;
    width: 100%;
    padding: 70px 0px 70px 0px;
  }
}
