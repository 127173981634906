.title_area {
  width: 100%;
  display: flex;
  border-bottom: 10px solid var(--order-border-bottom-);
}
.wrap_frame {
  width: 100%;
  height: auto;
  margin: 0 auto;
  box-sizing: border-box;
  /* border-bottom: 7px solid var(--order-border-bottom-); */
}
.wrap_frame:nth-last-child(1) {
  /* border-bottom: 7px solid var(--order-border-bottom-); */
}
.delivery-details-frame {
  margin-top: 22px;
  width: 840px;
  height: auto;
  font-family: noto-sans-cjk-kr, sans-serif;
  font-weight: 500;
  font-style: normal;
  box-sizing: border-box;
  padding: 0% 5% 0% 5%;
}
.delivery-info-box {
  /* background-color: var(--order-border-bottom-); */
  border-bottom: 8px solid var(--order-border-bottom-);
}

/* .underline {
  width: 100%;
  height: 10px;
  background-color: var(--order-border-bottom-);
  margin-bottom: 20px;
} */
.delivery-title-pre {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 30px;
  float: left;
  position: absolute;
}
.delivery-title-pre img {
  width: 10px;
  height: 20px;
}
.order_check_box {
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.order_check_box > .shop-name {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.black-btn {
  width: 25px;
  height: 25px;
  /* margin-top: 12px; */
  margin-left: 10px;
  cursor: pointer;
  background-image: url(src_assets/icon/black-button.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 0;
  background-color: #fff;
  /* transform: translate(-50%, -50%); */
}
.drop {
  width: 25px;
  height: 25px;
  background-image: url(src_assets/allow-button.png);
  /* margin-top: 12px; */
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 0;
  background-color: #fff;
  display: none;
  /* transform: translate(-50%, -50%); */
}
.chek_box_area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chek_box_area > span {
  margin-left: 5px;
  font-style: normal;
  font-family: "noto-sans-cjk-kr";
  font-size: 18px;
  /* font-weight: 600; */
}
.order_type {
  width: 90%;
  margin: 0 auto;
  /* width: 88px; */
  /* background-image: url(src_assets/cosmetics/text_deco_type.png); */
  background-image: url(src_assets/cosmetics/text_deco_type_round.png);
  background-size: 81px 8px;
  background-position: 0px 13px;
  /* background-size: 79px 23px;
  background-position: 0 6px; */
  background-repeat: no-repeat;
  font-family: "noto-sans-cjk-kr";
  font-size: 20px;
}
.font-weight {
  font-weight: 700;
}
.font-color {
  color: #f51640;
}
/*포장 주문 내역(Wrapinfo)*/
.delivery-details-frame > .wrap-info-box {
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.wrap-info-box > .wrap-title {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
}

.wrap-info-box > .wrap-info {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.wrap-info > p {
  font-size: 15px;
}
.safe_number_check {
  font-size: 12px;
}
/*포장 주문 내역 끝*/

/*배송 주문 프레임*/
.delivery-history-frame {
  margin-top: 22px;
  width: 840px;
  box-sizing: border-box;
  /* padding: 0% 5% 0% 5%; */
}

.delivery-history-frame .delivery-title {
  font-weight: bold;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding-bottom: 10px;
}

/*배송 주문 프레임 끝*/

/*배송 주문 내역(DeliveryDetail)*/
.delivery-details-frame > .delivery-info-box {
  width: 100%;
  height: auto;
}
.request_underline1 {
  background-image: url(src_assets/cosmetics/text_deco.png);
  width: 100px;
  background-size: 100% 25px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.request_underline2 {
  background-image: url(src_assets/cosmetics/text_deco.png);
  width: 100px;
  background-size: 100% 25px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.order_check_box > .shop-name > .order-title-light {
  /* font-family: "noto-sans-cjk-kr" !important; */
  /* font-weight: 700; */
  font-weight: 500;
  font-size: 18px;
}
.order-content-title > .order-title-light {
  /* font-family: "noto-sans-cjk-kr"; */
  /* font-weight: bold; */
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 4px;
}
.delivery-details-frame .delivery-title {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 24px;
}
.delivery-info {
  margin-top: 80px;
  margin-bottom: 30px;
  /* margin: 30px 0; */
}
.delivery-info-list {
  line-height: 25px;
  margin-top: 10px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin: 0 auto;
}
.phone-number-wrap {
  border-top: 8px solid var(--order-border-bottom-);
  /* padding: 20px 0; */
  padding-top: 30px;
}
.phone-number-wrap > li {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.phone-number {
  margin-bottom: 10px;
}

.delivery-info-list > li {
  padding-bottom: 10px;
  margin-top: 5px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: 300;
  color: #4e4e4e;
}

.phone-number-takeout {
  width: 100%;
  height: 100px;

  border-bottom: 10px solid var(--order-border-bottom-);
}
.delivery-info-list > li > .phone-number {
  font-family: "Bahnschrift";
  width: 100%;
  height: auto;

  font-size: 20px;
}

.font-Bahnschrift {
  font-family: "Bahnschrift";
  font-size: 20px;
}
.delivery-info-list > li .address-change {
  margin-left: 15px;
  width: 100px;
  height: 28px;
  background-color: black;
  background-image: url(src_assets/search-img_w.png);
  background-repeat: no-repeat;
  background-size: 16%;
  background-position: 88% 50%;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left;
  color: #fff;
  cursor: pointer;
  font-family: "Noto-Sans-CJKkr";
}
.phone-number-wrap > li .address-change {
  margin-left: 15px;
  width: 100px;
  height: 28px;
  background-color: black;

  background-repeat: no-repeat;
  background-size: 16%;
  background-position: 88% 50%;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left;
  color: #fff;
  cursor: pointer;
  font-family: "Noto-Sans-CJKkr";
}

.adress_area {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.adress_area-content {
  display: flex;
  align-items: center;
}
input[id^="address-checkbox"][type="checkbox"] {
  display: none;
}

input[id^="address-checkbox"][type="checkbox"] + label {
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-top: 2px;
}

input[id^="address-checkbox"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px #eae6e4 inset; */
  /* background-color: #eae6e4; */
}

input[id^="address-checkbox"][type="checkbox"]:checked + label::after {
  content: "";
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
  /* line-height: 17px; */
}
input[id^="direct-checkbox"][type="checkbox"] {
  display: none;
}

input[id^="direct-checkbox"][type="checkbox"] + label {
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-top: 2px;
}

input[id^="direct-checkbox"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px #eae6e4 inset; */
  /* background-color: #eae6e4; */
}

input[id^="direct-checkbox"][type="checkbox"]:checked + label::after {
  content: "";
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
  /* line-height: 17px; */
}
input[id^="tel-checkbox"][type="checkbox"] {
  display: none;
}

input[id^="tel-checkbox"][type="checkbox"] + label {
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-top: 2px;
}

input[id^="tel-checkbox"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px #eae6e4 inset; */
  /* background-color: #eae6e4; */
}

input[id^="tel-checkbox"][type="checkbox"]:checked + label::after {
  content: "";
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
  /* line-height: 17px; */
}
input[id^="order-checkbox"][type="checkbox"] {
  display: none;
}

input[id^="order-checkbox"][type="checkbox"] + label {
  margin-right: 4px;
  margin-left: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

input[id^="order-checkbox"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0 0 0 2px black inset;
  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
  background-color: #eae6e4;
}

input[id^="order-checkbox"][type="checkbox"]:checked + label::after {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
  background-color: var(--kk-color-Ymain);
  line-height: 17px;
}

input[id^="safe-number"][type="checkbox"] {
  display: none;
}

input[id^="safe-number"][type="checkbox"] + label {
  margin-right: 10px;
  margin-top: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

input[id^="safe-number"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  background-image: url(src_assets/check_off.svg);

  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  /* box-shadow: 0 0 0 2px #eae6e4 inset; */
  /* background-color: #eae6e4; */
}

input[id^="safe-number"][type="checkbox"]:checked + label::after {
  content: "";
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  background-image: url(src_assets/check_on.svg);

  position: absolute;
  left: 0px;
  top: 0px;
  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
  line-height: 17px;
}

@media screen and (max-width: 768px) {
  .delivery-history-frame {
    /* margin: 65px auto 0 auto; */
    margin-top: 30px;
    width: 100%;
    box-sizing: border-box;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
  }
}

/*배송 주문 내역 끝*/

/*요청 사항*/
.order-request {
  width: 90%;
  margin: 30px auto;
  height: auto;
  /* padding-bottom: 25px; */
}
.order-request-wrap {
  border-bottom: 10px solid var(--order-border-bottom-);
}
.order-request > span:first-child {
  font-weight: 500;
  font-family: "noto-sans-cjk-kr";
}
.order-request > #request-text {
  width: 90%;
  height: 40px;
  background-color: #f5f5f5;
  border: none;
  box-sizing: border-box;
  padding: 10px;
}
.phone-number-wrap > li > #tel-phone {
  width: 60%;
  height: 40px;
  background-color: #f5f5f5;
  border: none;
  box-sizing: border-box;
  padding: 10px;
}
.phone-number-wrap > li > #mb-name {
  width: 60%;
  height: 40px;
  background-color: #f5f5f5;
  border: none;
  box-sizing: border-box;
  padding: 10px;
}
.order-request > .request-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.request-list > li {
  font-family: "noto-sans-cjk-kr";
  padding: 20px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  display: flex;
  font-weight: 300;
  align-items: center;
}

.request-list > li > input[id^="request"][type="checkbox"] {
  display: none;
}

.request-list > li > input[id^="request"][type="checkbox"] + label {
  margin-right: 10px;
  margin-top: 1px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.request-list > li > input[id^="request"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px #eae6e4 inset; */
  /* background-color: #eae6e4; */
}

.request-list
  > li
  > input[id^="request"][type="checkbox"]:checked
  + label::after {
  content: "";
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

#request2[type="checkbox"] {
  display: none;
}

#request2[type="checkbox"] + label {
  margin-right: 10px;
  margin-top: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#request2[type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  background-image: url(src_assets/check_off.svg);

  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 2px #eae6e4 inset;
  background-color: #eae6e4;
}

#request2[type="checkbox"]:checked + label::after {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}
/*요청 사항*/

/* 주문리스트(OrderContents) */
.order-content {
  width: 100%;
  height: auto;
  margin: 1.2em auto;
  /* padding-bottom: 25px; */
  text-align: center;

  color: #000;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.pay-order-content {
  width: 100%;
  height: auto;
  margin: 30px auto;
  text-align: unset !important;
  /* padding-bottom: 25px; */
}
.wrap_frame-wrap {
  width: 100%;
  margin: 0 auto;
}
.order-content .order-content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
}
.order-content .order-content-title > .drop-down {
  background-image: url(src_assets/allow-button.png);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  background-color: #fff;
}
.order-content .order-content-title > .drop-up {
  background-image: url(src_assets/allow-button-top-b.png);
  width: 20px;
  height: 20px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  background-color: #fff;
}

.order-content > .order-content-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-weight: 300;
}
/* 접기 */
.order-content-list .folder.dis-none {
  display: none;
}

.order-content-list > .order-content-list-cancel {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: right;
}

.menu-price > button {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  border-radius: 100%;
  border: none;
  box-shadow: 0 0 0 2px #d2d2d1 inset;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}

.order-content-list-cancel > button:hover {
  background-color: #adadad;
}

.order-content-list > .order-menu-name-box {
  width: 100%;
  height: auto;
  /* background-color: #f5f5f5; */
  box-sizing: border-box;
  /* padding: 8px; */
  display: grid;
  grid-template-columns: auto 1fr;
  /* justify-content: space-between; */
  /* align-items: center; */
  gap: 1em;
  margin-top: 30px;
  /* margin-bottom: 30px; */
  /* margin: 30px 0; */
  /* white-space: nowrap; */
}
.delivery-history-frame
  .order-content-list
  > .order-menu-name-box:nth-child(1) {
  margin-top: 0px;
}
.order-wrap4923 .order-content-list > .order-menu-name-box:nth-child(1) {
  margin-top: 0px;
}
.order-history-frame
  .order-content
  .order-content-list:nth-child(1)
  .order-menu-name-box {
  margin-top: 0px;
}
.order-menu-name-box > .menu-name {
  font-family: "noto-sans-cjk-kr";

  padding-left: 10px;
  text-align: left;
}
.order-menu-name-box > .menu-function {
  display: flex;
  margin: 0 10px;
  align-items: center;
  text-align: left;
}
.menu-function > span {
  margin: 0 5px;
  font-size: 12px;
  font-weight: 200;
  width: 100%;
}
.order-menu-name-box > .menu-price {
  /* min-width: 140px; */
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.menu-price > button {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  border-radius: 100%;
  border: none;
  box-shadow: 0 0 0 2px #d2d2d1 inset;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}
.menu-price > p {
  /* margin-left: 10px; */
  /* font-family: Bahnschrift; */
  /* font-weight: 300; */
  /* font-size: 18px; */
  white-space: nowrap;
}

.order-content-list > li {
  font-family: "noto-sans-cjk-kr-DemiLight";
  margin: 0 auto;
  width: 90%;
  height: auto;
  box-sizing: border-box;
  padding: 10px 10px 0px 0px;
  /* border-bottom: 1px dashed #9b9b9b; */
  display: flex;
  flex-direction: column;
}
.opciton-price-box {
  border-bottom: 1px dashed #9b9b9b;
  padding: 7px 0;
}
.order-content-list > ul {
  font-family: "noto-sans-cjk-kr-DemiLight";
  margin: 0 auto;
  width: 90%;
  height: auto;
  box-sizing: border-box;
  padding: 10px 10px 0px 0px;
  border-bottom: 1px dashed #9b9b9b;
  display: flex;
  flex-direction: column;
}

.delivery-history-frame .order-content-list > li:nth-last-of-type(1) {
  /* background-color: red; */
  /* border-bottom: 0px dashed #9b9b9b; */
}
.order-content-list > li > .opction-class {
  color: #8c8c8c;
  text-align: left;
  font-weight: 500;
}
.opciton-price-box p {
  white-space: nowrap;
}

.order-content-list > li > .opciton-price-box {
  /* margin-top: 5px; */
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opciton-price-box > .opction-name {
  gap: 7px;
  font-weight: 300;
  font-family: "noto-sans-cjk-kr";
  color: #0f0f0f;
  text-align: left;
}

.opction-name > p {
  color: #8c8c8c;
  font-family: "noto-sans-cjk-kr";
}

.opciton-price-box > span {
  display: flex;
  gap: 10px;
  font-family: Bahnschrift;
  font-weight: 300;
  color: #070808;
}

.add-menu {
  margin-top: 20px;
  width: 90%;
  height: 52px;
  font-size: 19px;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--kk-color-Ymain);
  border-radius: 5px;
}

.payment {
  width: 100%;
  /* margin: 30px auto; */
}
.payment-result {
  width: 100%;
  /* margin: 30px auto; */

  border-radius: 8px;
  border: 1px solid #e0e0e0;

  background: #fff;

  padding: 1em;
  box-sizing: border-box;
}
.payment-title {
  /* margin: 10px 0; */
  margin-bottom: 20px;
  font-size: 17px;
  display: block;
}
/* .add-menu:hover
{
  background-color: #f1f0f0;
}

.add-menu:active
{
  background-color: #c4c4c4;
}

/* 주문리스트끝(OrderContents) */

/* 포인트 사용(KiikeyPointUse) */
.kiikey-point-use {
  width: 90%;
  margin: 30px auto;
  height: auto;
  /* padding-bottom: 25px; */
  /* padding-top: 10px; */
}
.kiikey-point-use-wrap {
  border-top: 10px solid var(--order-border-bottom-);
}
.kiikey-point-use .order-title {
  width: 80%;
  font-weight: bold;
}
.kiikey-point-use > .kiikey-have-point {
  margin-bottom: 10px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
}
.font-weght-light {
  font-weight: 300;
  font-size: 12px;
}
.font-weght-light2 {
  font-weight: 500;
  font-size: 13px;
}
.font-weght-light3 {
  font-weight: 500;
  font-size: 16px;
  font-family: "noto-sans-cjk-kr";
}
.font-weght-light4 {
  font-weight: 300;
  font-size: 16px;
  font-family: "noto-sans-cjk-kr";
}
.kiikey-have-point > span {
  display: flex;
  justify-content: end;
  align-items: center;
  font-weight: 500;
  font-family: "noto-sans-cjk-kr";
  font-size: 15px;
}

.kiikey-have-point > span > p {
  margin-right: 5px;

  font-weight: 600;
}

input[id^="point-use"][type="checkbox"] {
  display: none;
}

input[id^="point-use"][type="checkbox"] + label {
  margin-right: 10px;
  margin-top: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

input[id^="point-use"][type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  /* box-shadow: 0 0 0 2px black inset; */
  line-height: 17px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px #eae6e4 inset; */
  /* background-color: #eae6e4; */
}

input[id^="point-use"][type="checkbox"]:checked + label::after {
  content: "";
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
  line-height: 17px;
}

.kiikey-point-use > .kiikey-point-used {
  width: 100%;
  height: 30px;
  display: flex;
}

.kiikey-point-used > span {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 105px;
}

.kiikey-point-used > #point-use-input {
  margin-left: 5px;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  text-align: right;
  box-sizing: border-box;
  padding-right: 5px;
  font-weight: 600;
  font-family: "Bahnschrift";
  font-size: 15px;
}

#point-use-input::-webkit-outer-spin-button,
#point-use-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#point-use-input[type="number"] {
  -moz-appearance: textfield;
}
/* 포인트 사용 끝(KiikeyPointUse) */

/* 픽업시간 설정(KiikeyPointUse) */
.pickup-time {
  width: 100%;
  height: auto;
  padding-bottom: 25px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.pickup-time > .pickup-time-set {
  width: 100%;
  height: 30px;
  display: flex;
}

.pickup-time-set > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pickup-time-set > span:first-child {
  margin-right: 10px;
}

.pickup-time-set > span:nth-child(3) {
  margin-left: 10px;
}

/* 결제수단(Payment) */
.payment_frame {
  /* border-top: 10px solid var(--order-border-bottom-); */
  /* border-bottom: 10px solid var(--order-border-bottom-); */

  color: #000;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */

  border-radius: 8px;
  border: 1px solid #e0e0e0;

  background: #fff;

  padding: 1em 1em 0 1em;
}
.delivery-details-frame > .payment {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  border-bottom: 10px solid var(--order-border-bottom-);
}
.payment > span {
  /* font-weight: bold; */
}
.payment > .payment-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Precautions {
  font-size: 15px;
  font-family: "noto-sans-cjk-kr";
  margin: 10px auto;
  text-align: center;
}

/*--------------------------------------*/

.payment-list > #payment-credit[type="radio"] {
  display: none;
}

.payment-list > #payment-credit[type="radio"] + label {
  display: inline-block;
  width: 48%;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.payment-list > #payment-credit[type="radio"] + label > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
}
.payment-list > #payment-credit[type="radio"] + label > div > span {
  padding-top: 0.3em;
}
.payment-list > #payment-credit[type="radio"] + label > div > img {
  content: url("src_assets/icon/radio_button_unchecked.svg");
}

.payment-list > #payment-credit[type="radio"]:checked + label > div > img {
  content: url("src_assets/icon/radio_button_checked.svg");
}

.payment-list > #payment-credit[type="radio"] + label::before {
  /* content: "신용카드 및 페이";
  color: #a9a8a9;
  width: 100%;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;
  box-shadow: 0 0 0 1px black inset;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 1px #eae6e4 inset;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.payment-list > #payment-credit[type="radio"]:checked + label::after {
  /* content: "신용카드 및 페이";
  color: #a9a8a9;
  width: 100%;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;
  box-shadow: 0 0 0 1px black inset;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 1px #eae6e4 inset;
  display: flex;
  justify-content: center;
  align-items: center; */
}

/*--------------------------------------*/

.payment-list > #payment-phone[type="radio"] {
  display: none;
}

.payment-list > #payment-phone[type="radio"] + label {
  display: inline-block;
  width: 48%;
  height: 40px;

  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.payment-list > #payment-phone[type="radio"] + label > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
}
.payment-list > #payment-phone[type="radio"] + label > div > span {
  padding-top: 0.3em;
}
.payment-list > #payment-phone[type="radio"] + label > div > img {
  content: url("src_assets/icon/radio_button_unchecked.svg");
}

.payment-list > #payment-phone[type="radio"]:checked + label > div > img {
  content: url("src_assets/icon/radio_button_checked.svg");
}

.payment-list > #payment-phone[type="radio"] + label::before {
  /* content: "계좌이체";
  color: #a9a8a9;
  width: 100%;
  font-family: NotoSansCJKkr;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;
  box-shadow: 0 0 0 1px black inset;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 1px #eae6e4 inset;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.payment-list > #payment-phone[type="radio"]:checked + label::after {
  /* content: "계좌이체";
  color: var(--kk-color-Ymain);
  width: 100%;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  font-family: NotoSansCJKkr;
  text-align: center;
  font-size: 15px;

  position: absolute;
  left: 0px;
  top: 0px;
  box-shadow: 0 0 0 1px var(--kk-color-Ymain) inset;
  color: var(--kk-color-Ymain);
  display: flex;
  justify-content: center;
  align-items: center; */
}

/*--------------------------------------*/

.payment-list > #payment-meet[type="radio"] {
  display: none;
}
/* 비활성화 */
.payment-list > #payment-meet[type="radio"]:disabled + label:before {
  text-decoration: 2px line-through;
}
.payment-list > #payment-meet[type="radio"] + label {
  display: inline-block;
  width: 48%;
  height: 40px;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.payment-list > #payment-meet[type="radio"] + label::before {
  content: "만나서 카드";
  color: #a9a8a9;

  width: 100%;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;

  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 1px #eae6e4 inset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-list > #payment-meet[type="radio"]:checked + label::after {
  content: "만나서 카드";
  color: var(--kk-color-Ymain);
  width: 100%;
  height: 100%;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;

  position: absolute;
  left: 0px;
  top: 0px;
  box-shadow: 0 0 0 1px var(--kk-color-Ymain) inset;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------*/

.payment-list > #payment-cash[type="radio"] {
  display: none;
}
/* 비활성화 */
.payment-list > #payment-cash[type="radio"]:disabled + label:before {
  text-decoration: 2px line-through;
}
.payment-list > #payment-cash[type="radio"] + label {
  display: inline-block;
  width: 48%;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.payment-list > #payment-cash[type="radio"] + label::before {
  content: "만나서 현금 결제";

  width: 100%;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;
  color: #a9a8a9;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0 0 0 1px #eae6e4 inset;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-decoration:2px line-through ; */
}

.payment-list > #payment-cash[type="radio"]:checked + label::after {
  content: "만나서 현금 결제";
  color: var(--kk-color-Ymain);
  width: 100%;
  height: 100%;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  font-size: 15px;
  position: absolute;
  left: 0px;
  top: 0px;
  box-shadow: 0 0 0 1px var(--kk-color-Ymain) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-decoration:2px line-through ; */
}
/* 결제수단(Payment) */

/*--------------------------------------*/

/* 결제 금액 */
.wrap-history-frame > .payment {
  width: 80%;
  height: auto;
}
.delivery-details-frame > .payment-check {
  width: 100%;
  height: auto;
  padding-bottom: 25px;
}

.payment-check > .payment-check-list {
  width: 100%;
  height: auto;
  display: flex;
  gap: 5px;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 300;
  padding: 0px 10px 10px 10px;
  border-bottom: 2px dashed var(--order-border-bottom-);
}

.payment-check-list > li {
  display: flex;
  justify-content: space-between;
}
/* 결제 금액 끝 */

/*최종 결제(FinalConfirmation)*/
.delivery-details-frame > .final-confirmation {
  width: 100%;
  height: auto;
}

.final-confirmation > .payment-result {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 5px 0;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  /* padding-top: 30px; */
  /* margin-top: 30px; */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1em;

  color: #000;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
}

.payment-result > .payment-result-list {
  font-family: Bahnschrift;
  margin: 20px 0;
  width: 100%;
  padding-left: 2em;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 10px;
  color: #333;
  border-bottom: 1.5px dashed #ebebeb;
}
/* .order-history-frame .payment-result {
  margin-bottom: 0;
} */

.option_area {
  margin-left: 15px;
  font-weight: 100;
}
.order_option {
  color: #aaa;
  padding-top: 10px;
  /* padding-left: 10px; */
  text-align: left;
}
p.pay_number {
  margin-left: 3px;
  font-family: "noto-sans-cjk-kr";
  font-size: 18px;
}
.payment-result-list > li {
  padding: 3px 0px 3px 0px;
  display: flex;
  justify-content: space-between;
}

.payment-result > .total-payment-result {
  margin: 20px auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  font-weight: 450;
  /* margin: 20px 0; */
  /* padding-bottom: 10px; */
}
.delivery-history-frame .total-payment-result {
  width: 90%;
}
.total-payment-result > P {
  font-size: 18px;
  font-weight: 700;
  font-family: "noto-sans-cjk-kr";
}

.final-confirmation > .kiikey-guide {
  width: 100%;
  box-sizing: border-box;
  padding: 1em 0.2em;
  /* padding: 11px 20px 30px 20px; */
  color: #000;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */

  /* background-color: #f1f0f0; */
  /* text-align: center; */
  margin: 1em 0;
  border-top: 2px solid #bdbdbd;
  border-bottom: 2px solid #bdbdbd;
}

.final-confirmation > .payment-button {
  /* margin-top: -20px; */
  width: 100%;
  /* height: 80px; */
  padding: 0.6em 1em 0.4em;

  cursor: pointer;
  border: none;
  color: #000;

  border-radius: 8px;
  background: #bdbdbd;

  color: #fff;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */

  margin-bottom: 2em;
}

.payment-button:hover {
  background: #9d9d9d;
  /* color: #fff; */
  color: #000;
}

.payment-button:active {
  background: #adadad;
}
/*최종 결제(FinalConfirmation)*/

/*결제 확인(checkconfirmation)*/
.check-confirmation {
  margin: 0 auto;
  width: 90%;
  height: auto;
}

.check-confirmation > .check-payment-button {
  margin-top: 20px;
  width: 100%;
  height: 80px;
  font-size: 30px;
  font-weight: 500;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  background-color: var(--kk-color-Ymain);
}

.check-payment-button:hover {
  background-color: var(--kk-color-Ymain);
  color: #fff;
}

.check-payment-button:active {
  background-color: var(--kk-color-Ymain);
}
/*결제 확인(checkconfirmation)*/

/*배송 주문 내역 단일 물품 */
.order-history-itmes {
  margin: 0 auto;
  width: 556px;
  height: auto;
}
/* 이미지박스 */
.order-history-itmes .imgbox {
  width: 556px;
  height: 556px;
}
.order-history-itmes .imgbox > img {
  width: 100%;
  height: 100%;
}
/* 카테고리 */
.order-history-itmes .category {
  width: 100%;
  height: 30px;
}
.order-history-itmes .category p {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
/* 제품명 */
.order-history-itmes .product {
  width: 100%;
  height: 60px;
  margin-top: 40px;
}
.order-history-itmes .product span {
  font-size: 18px;
}
/* 제품가격 */
.order-history-itmes .product p {
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  font-weight: 300;
}
/* 용량 */
.order-history-itmes .kg {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: space-between;
  border: 2px solid var(--kk-color-Ymain);
  box-sizing: border-box;
  position: relative;
}

.kg > .select-box {
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url(src_assets/select-allow.png);
  background-repeat: no-repeat;
  background-size: 3%;
  background-position: 99% 50%;
  box-sizing: border-box;
  padding-left: 10px;
  appearance: none;
  border: none;
}

/* 수량 */
.order-history-itmes .number {
  width: 100%;
  height: 70px;
  line-height: 70px;
  display: flex;
  justify-content: space-between;
}
.order-history-itmes .number {
  font-weight: bold;
}
.order-history-itmes .number .count span:not(:nth-child(2)) {
  border: 2px solid rgb(199, 199, 199);
  border-radius: 50px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 25px;
  text-align: center;
  margin-top: 20px;
  display: inline-block;
  cursor: pointer;
}
.order-history-itmes .number .count span {
  margin-left: 10px;
}
/* 구매하기 */

.order-history-frame .buy-button {
  display: block;
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border: none;
  background-color: var(--kk-color-Ymain);
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}
/*배송 주문 내역 단일 물품 끝 */

/* 장바구니 네임 */
.order-way-box > .shop-folder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-way-box > .shop-folder > .shop-name {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

/* 위치설정 반응형(LocationSet) */
@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 768px) {
  .locationset-frame {
    margin-top: 55px;
    width: 100%;
    box-sizing: border-box;
    padding: 0% 5% 0% 5%;
  }
}
/* 위치설정 반응형(LocationSet) */

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 768px) {
  .delivery-details-frame {
    width: 100%;
    margin-top: 55px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
  .payment > .payment-list {
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
    font-family: NotoSansCJKkr;
    font-weight: 500;
  }
  .payment-list > label {
    margin: 0 auto;
  }

  .Precautions {
    width: 90%;
    font-size: 13px;
    text-align: left;
  }
}

.payment-order-use-point-container {
  color: #000;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
}

.payment-order-use-point-container .payment-order-use-point-label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.2em;
  width: 100%;

  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 0.3em 1em 0.1em;

  font-family: Inter;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;

  color: #828282;
}

.payment-order-use-point-container .payment-order-use-point-label input {
  border: none;
  outline: none;
  width: 100%;
  text-align: right;
  font-size: 15px;
  height: 100%;
  color: inherit;
}

.payment-order-use-point-container .payment-order-use-point-label span {
}

.payment-receipt-container {
  display: flex;
  flex-direction: column;
  gap: 1em;

  color: #000;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
}

.payment-receipt-container > label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payment-receipt-container > label > input {
  display: none;
}

.payment-receipt-container > label > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}
.payment-receipt-container > label > div > img {
  /* content: url("src_assets/icon/check_circle.svg"); */
  content: url("src_assets/icon/radio_button_unchecked.svg");
  width: 24px;
  height: 24px;
}

.payment-receipt-container > label > input:checked + div > img {
  content: url("src_assets/icon/check_circle.svg");
}

.payment-receipt-container > label > input:checked + .payment-receipt-detail {
  display: block;
}

.payment-receipt-container > .payment-receipt-detail {
  display: none;

  color: #000;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
}

.payment-receipt-container > .payment-receipt-detail label {
  width: 100%;
}

.payment-receipt-container
  > .payment-receipt-detail
  label
  > input[name="receipt-type"] {
  display: none;
}

.payment-receipt-container > .payment-receipt-detail label > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.payment-receipt-container > .payment-receipt-detail label > div > span {
  padding-top: 0.3em;
}

.payment-receipt-container > .payment-receipt-detail label > div > img {
  content: url("src_assets/icon/radio_button_unchecked.svg");
}

.payment-receipt-container
  > .payment-receipt-detail
  label
  > input[name="receipt-type"]:checked
  + div
  > img {
  content: url("src_assets/icon/radio_button_checked.svg");
}

.payment-receipt-container
  .payment-receipt-detail
  .payment-receipt-detail-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.payment-receipt-container
  .payment-receipt-detail
  .payment-receipt-detail-phone
  input {
  width: 100%;
  height: 20px;
  text-align: center;

  border-radius: 8px;
  border: 1px solid #e0e0e0;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  background: #fff;
}
