/*위치설정 (LocationSet)*/
.locationset-frame {
  margin-top: 22px;
  width: 840px;
  height: auto;
  box-sizing: border-box;
  padding: 0px 2% 0px 7%;
}

.locationset-frame > .locationset-title {
  margin-bottom: 20px;
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  border-bottom: 10px solid #f9fafa;
  display: none;
}

.locationset-frame > .address-find {
  margin-top: 10px;
  display: flex;
  /* gap: 10px; */
  flex-direction: column;
}
.address-find p {
  margin: 5px 0;
}

.locationset-frame > .location-set-box {
  width: 80%;
  height: auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #eae8e7;
}

.location-set-box > span {
  /* margin-bottom: 20px; */
}

.location-set-box > p {
  /* padding-left: 20px; */
  margin: 5px 0;
}
.locationset-frame .order-title {
  margin-bottom: 0px;
}
.address-find > div {
  margin: 10px 0;
  display: flex;
  width: 100%;
  height: 45px;
  background-color: var(--kk-color-hover-light);
}

.default-address > input {
  width: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  padding-left: 2.5%;
  font-size: 16px;
}

.default-address > .address-search-button {
  display: block;
  width: 45px;
  height: 100%;
  margin-right: 10px;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/search-img_b.png);
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  cursor: pointer;
}

.detail-address > input {
  width: 100%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  padding-left: 2.5%;
  font-size: 16px;
}

.locationset-frame > .location-save-box {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d6d6d6;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.location-save-box > button {
  margin-bottom: 10px;
  width: 100%;
  height: 45px;
  border-radius: 50px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}

.address-save-button {
  background-color: #b7b7b7;
}
.location-save-button {
  background-color: var(--kk-color-Ymain);
}

.location-save-box > button:hover {
  background-color: #000;
}
.location-save-box > .location-save-button:hover {
  background-color: var(--kk-color-Ymain);
}
.locationset-frame > .recent-address {
  width: 100%;
  height: auto;
}

.recent-address > .recent-address-title {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recent-address-title > button {
  margin-top: 8px;
  height: 25px;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  padding: 0px 5px 0px 5px;
  background-color: #e6e6e6;
  font-size: 12px;
  font-family: NotoSansCJKkr;
  font-weight: 300;
  transform: translate(0, -22%);
}

.recent-address > li {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  box-sizing: border-box;
  /* padding: 0 0 0 2.5%; */
  cursor: pointer;
}

.recent-address > li > p:hover {
  background-color: var(--kk-color-hover-light);
}
.recent-address > li > p {
}

.recent-address > li > button {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  border-radius: 100%;
  border: none;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  /* margin: auto; */
  /* transform: translate(-50%, -50%); */
}

@media screen and (max-width: 768px) {
  .locationset-frame {
    /* margin-top: 100px; */
    margin: 75px auto 0 auto;
    width: 100%;
    padding: 0px 5% 0px 5%;
  }
  .locationset-frame > .location-set-box {
    width: 100%;
  }
}

/* 위치설정 끝(LocationSet) */
