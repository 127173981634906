.user-agree {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 10%;
  font-family: "Nanum Gothic";
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7%;
}

.user-agree > .user-agree-area {
  margin: 0 auto;
  width: 65%;
  height: auto;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 70px;
  gap: 5%;
}

.user-agree-area > h2 {
  text-align: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.user-agree-area > .user-agree-box {
  width: 100%;
  height: auto;
}

.user-agree-box > p {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .user-agree {
    background-color: #fff;
  }
  .user-agree > .user-agree-area {
    width: 100%;
  }
}
