.create-account-frame {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 20%;
  font-family: "Nanum Gothic";
}

.create-account-form {
  margin: 0 auto;
  width: 71%;
  height: auto;
  border-radius: 50px;
  background-color: white;
  padding: 70px;
}

.create-account-form > .form-title {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 2px solid rgb(214, 214, 214);
  margin-bottom: 60px;
}

.create-account-form > .join-table {
  margin: 0 auto;
  margin-left: 21%;
  margin-bottom: 100px;
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.join-table tr {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 42px;
  display: flex;
}

.join-table tr td > input {
  box-sizing: border-box;
  padding-left: 10px;
}
.join-table tr td:first-child {
  width: 120px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
}

.join-table tr .input-note {
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 14px;
  color: rgb(163, 8, 8);
}

.join-table tr .small-td {
  width: 51%;
  display: flex;
  justify-content: space-between;
}

.join-table tr .gender-td {
  width: 30%;
  display: flex;
  justify-content: left;
  gap: 2%;
}

#gender-male[type="radio"] {
  width: 100px;
  height: 100%;
  background-color: gold;
  display: none;
}

#gender-male[type="radio"] + label {
  display: inline-block;
  width: 125px;
  height: 100%;
  border-radius: 50px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#gender-male[type="radio"] + label::before {
  box-shadow: 0 0 0 2px gray inset;
  content: "남자";
  border-radius: 50px;
  font-size: 17px;
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
  background-color: #f4f4f4;
  position: absolute;
  top: 0px;
  left: 0px;
}

#gender-male[type="radio"]:checked + label::after {
  content: "남자";
  border-radius: 50px;
  font-size: 17px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
  color: #fff;
  background-color: var(--kk-color-Ymain);
  position: absolute;
  left: 0px;
  top: 0px;
}

#gender-female[type="radio"] {
  width: 100px;
  height: 100%;
  background-color: gold;
  display: none;
}

#gender-female[type="radio"] + label {
  display: inline-block;
  width: 125px;
  height: 100%;
  border-radius: 50px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#gender-female[type="radio"] + label::before {
  box-shadow: 0 0 0 2px gray inset;
  content: "여자";
  border-radius: 50px;
  font-size: 17px;
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
  position: absolute;
  background-color: #f4f4f4;
  top: 0px;
  left: 0px;
}

#gender-female[type="radio"]:checked + label::after {
  box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset;
  content: "여자";
  border-radius: 50px;
  font-size: 17px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
  position: absolute;
  color: #fff;
  background-color: var(--kk-color-Ymain);
  left: 0px;
  top: 0px;
}

.small-td > input {
  width: 100%;
  border-radius: 100px;
  border: none;
}

.join-table tr td:nth-child(3) {
  width: 15%;
}

.join-table tr .large-td {
  width: 67%;
}

.createbutton:active {
  background-color: rgb(214, 214, 214);
}

.join-button-large-size {
  width: var(--join-button-large-width);
  height: var(--join-button-height);
}

.timer-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.join-timer-style {
  border: none;
  border-radius: 100px;
  color: red;
  font-size: 14px;
}

.join-btn-style {
  margin-left: 10px;
  border: none;
  border-radius: 100px;
  background-color: var(--kk-color-Ymain);
  color: #fff;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
}

.join-btn-style:hover {
  background-color: var(--kk-color-hover);
}

.join-input-style {
  width: var(--join-input-width);
  height: var(--join-input-height);
  border-radius: 100px;
  border: none;
  box-sizing: border-box;
  padding-left: 20px;
  box-shadow: 0 0 0 1px rgb(206, 206, 206) inset;
}

.join-input-style-pw {
  width: var(--join-input-width);
  height: var(--join-input-height);
  border-radius: 100px;
  border: none;
  box-sizing: border-box;
  padding-left: 20px;
  background-color: #f4f4f4;
}

.join-btn-style-gender {
  width: 49%;
  height: 100%;
  border: none;
  border-radius: 100px;
  background-color: var(--kk-color-Ymain);
  color: var(--kk-color-sub);
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.join-btn-style-gender:hover {
  background-color: var(--kk-color-hover);
}

@media screen and (max-width: 1280px) {
  .create-account-form > .join-table {
    width: 90%;
    margin-left: 17%;
  }
}

@media screen and (max-width: 768px) {
  .create-account-form > .join-table {
    width: 100%;
    margin: 0 auto;
  }
  .create-account-frame {
    width: 100%;
    background-color: #fff;
  }
  .create-account-form {
    margin: 0 auto;
    width: 90%;
    padding: 0px;
  }
  .create-account-form > .join-table {
    width: 100%;
  }
  .join-table tr .small-td {
    width: 45%;
  }
  .join-table tr .gender-td {
    width: 50%;
  }
  .join-table tr td:nth-child(3) {
    width: 20%;
  }
}
