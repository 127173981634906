.event-bg{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 1000;
  position: fixed;
  top: 0;

}
/* 2022-07-18 김재용 */
.event-bg.dis-none {
  display: none;
}
/* background-image: url(src_assets/event.png);
background-size: 361px 800px;
background-repeat: no-repeat; */
.event-bg .event-page{
  width: auto;
  height: 70vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
}

.event-page > .event-img-box
{ position: relative;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  height: 70vh;
}

.event-img-box > img
{
  width: auto;
  max-width: 95vw;
  height: auto;
  max-height: 100%;
}
.event-bg .event-page #close{
  position: absolute;
  width: 35px;
  height: 35px;
  border: 0;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  color: #fff;
  border: 2px solid ghostwhite;
  border-radius: 100%;
  right: 0px;
  top: -60px;
  cursor: pointer;
}

.event-page #close:hover
{
  background-color: rgb(46, 46, 46);
}
.event-bg .event-page #chkbox{
  width: 20px;
  height: 20px;
  position: absolute;
  border: 2px solid #fff;
  box-sizing: border-box;
  bottom: -6%;
  left: 0%;
}
.event-bg .event-page span{
  position: absolute;
  bottom: -6%;
  left: 10%;
  color: #fff;
}

