.order-shop-list-frame {
  width: 840px;
  min-width: 350px;
  height: auto;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 20px;
  font-family: "Noto Sans KR", sans-serif;
}

.order-shop-list-frame.kapp {
  padding-top: 5px;
}

.order-shop-list-frame > .order-shop-title {
  margin-bottom: 20px;
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  border-bottom: 10px solid #f9fafa;
}
.order-shop-promotion {
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  border-bottom: 10px solid #f7f7f7;
  font-family: "Noto Sans KR", sans-serif;
}
.order-shop-promotion p {
  margin-left: 34px;
}
.order-shop-restaurant {
  margin-left: 34px;
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  font-family: "Noto Sans KR", sans-serif;
}
.order-shop-list-frame > .sale-shop-list-area {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.sale-shop-list-area_none {
  display: none;
}

.sale-shop-list-area > .sale-shop-list {
  /* width: 100%; */
  /* height: 100%; */
  /* max-height: 215px; */
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}

.sale-shop-list > li {
  width: 25%;
  /* height: 23vh; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  /* overflow: hidden; */
  /* box-shadow: 2px 2px 1px rgba(151, 151, 151, 0.3); */
  box-shadow: 0 0 6px 0 rgba(13, 13, 13, 0.07);
  transition-duration: 0.1s;
  cursor: pointer;
  margin: 0 13px;
}
.sale-shop-list > li:hover > .shop-menu-img > img {
  transform: scale(1.1);
}

.sale-shop-list > li > .shop-menu-img {
  /* width: 100%; */
  /* height: 75%; */
  /* max-width: 105px; */
  /* max-height: 100px; */
  border-radius: 10px 10px 0 0;

  overflow: hidden;
}

.shop-menu-img > img {
  /* width: 100%; */

  transition-duration: 0.2s;
}

.sale-shop-list > li > span {
  height: 25%;
  font-size: 17px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.sale-shop-list-area > .shop-menu-index {
  margin-top: 25px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  gap: 1%;
}

.shop-menu-index > li {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #dedede;
  cursor: pointer;
}

.shop-menu-index > li:hover {
  background-color: var(--kk-color-Ymain);
}

.shop-menu-index > li .active {
  background-color: var(--kk-color-Ymain);
}

.order-shop-list-frame > .shop-search-list {
  width: 100%;
  height: auto;
}

.shop-search-list > .search-result {
  margin-top: 10px;
  width: 100%;
  padding-left: 5px;
  height: auto;
  display: flex;
}

.search-result > span {
  margin-right: 10px;
  font-weight: bold;
  color: #8d8d8d;
}

.shop-search-list > .shop-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.shop-list > li {
  width: 100%;
  margin: 0 auto;
  min-height: 125px;
  height: auto;
  display: flex;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 1%;
  padding-right: 1%;
  border-bottom: 2px solid #bdbdbd;
  border-top: 1px solid #f6f5f5;
  cursor: pointer;
}

.shop-list > li:first-child {
  border-top: none;
}

.shop-list > li > .shop-img {
  margin-right: 10px;
  display: block;
  width: 85px;
  height: 75px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.shop-img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.shop-list > li > .product-img {
  margin-right: 10px;
  display: block;
  width: 85px;
  height: 75px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  border: 3px solid #f5f6f6;
}
.product-img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

/* 가게가 영업중이면 display:none > 제거 */
.shop-img > .menu-preparing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.menu-preparing.none {
  display: none;
}

.shop-list > li > .shop-info {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 0;
  flex-grow: 1;
  display: flex;
  gap: 0.2em;
}
.shop-info {
  height: auto;
  display: flex;
  flex-direction: column;
  width: 0;
  flex-grow: 1;
  display: flex;
  gap: 0.2em;
}
.shop-info > li {
  /* display: flex;
  flex-direction: row; */
}
.info-top {
}
.shop-info > .info-top .shop-name {
  width: auto;
  font-size: 20px;
  font-weight: bold;
  border-top: none;
}

.shop-info > .shop-grade {
  display: flex;
  flex-direction: row;
  height: auto;
  margin-bottom: 10px;
  justify-content: flex-start;
  font-size: 15px;
}

.shop-info > .shop-grade > span {
  width: 200px;
  /* margin-right: 5px; */
  font-size: 16px;
  color: #0e0e0e;
}

.shop-info > .shop-grade > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shop-info > .shop-grade > span > img {
  height: 100%;
}
.shop-info > .shop-price {
  display: flex;
  height: 20px;
  color: #4d4d4d;
}
.shop-info > .shop-price > span {
  width: auto;
  height: 16px;
  line-height: 16px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 20px;
}
/* 제품 가격과 배송비 */
.category-product-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  /* color: #4d4d4d; */
}
.category-product-price .result-price {
  display: flex;
  flex-direction: row;
}

.category-product-price .result-price .origin-price {
  position: relative;
  padding: 0 0.5em;
  text-align: center;

  color: #828282;

  white-space: nowrap;

  font-size: 15px;
}

.category-product-price .result-price .origin-price::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0.3em;
  min-width: 100%;
  min-height: 3px;
  background: #828282;
  opacity: 0.7;
}

.category-product-review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  color: #4d4d4d;
}

.category-product-review .result-review {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.category-product-parcel-price {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.category-product-parcel-price .parcel_free_price {
  color: #828282;
  font-size: 11px;
}

.result-price > h3 {
  color: #949494;
  text-decoration: line-through;
  margin-right: 1%;
  font-size: 16px;
  font-weight: normal;
}
.result-price > span {
  width: auto;
  height: 16px;
  line-height: 16px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 20px;
}

.shop-info > .shop-price > span > img {
  padding-top: 3px;
}
.shop-info > .shop-price > p {
  width: auto;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
}

.shop-info > .shop-price > p:last-child {
  margin-left: 3px;
  flex-wrap: wrap;
}

.shop-info > .shop-price > span > img {
  height: 100%;
}

.info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  /* border: 1px solid red; */
}

.info-top svg {
  cursor: pointer;
}

.info-top svg[active] circle {
  stroke: #f00;
}

.info-top svg[active] g path {
  fill: #f00;
}

.shop-list > li > .shop-state {
  width: 20%;
  height: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 3%;
}

.shop-state > span {
  min-width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  color: #5f6060;
  font-size: 16px;
  font-weight: bold;
  background-color: #f1f0f0;
  display: inline-block;
  margin-left: 5px;
}

.order-shop-nav ul {
  /* border: 1px solid red; */
  border-bottom: 2px solid #f5f6f6;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  /* overflow-x: scroll; */
  /* overflow-x: visible; */
  overflow-x: auto;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-overflow-scrolling: touch;
  /* justify-content: center; */
  align-items: center;
}
.order-shop-nav > ul > li {
  flex: 1;
  padding: 10px 18px;
  text-align: center;

  -webkit-overflow-scrolling: touch;
  /* -ms-overflow-style: -ms-autohiding-scrollbar; */
  /* border: 1px solid red; */
}
/* .order-shop-nav > ul > li .active {
  background-color: #fee900;
} */
.order-shop-nav > ul > li:before {
  background-color: #fee900;
  border-radius: 100px;
  content: "";
  display: none;
  height: 9px;
  left: 0;
  position: absolute;
  top: 25px;
  transition: 0.3s;
  width: 100%;
  z-index: -100;
}
/* 탭바 컬러 */
.PrivateTabIndicator-colorSecondary-3 {
  background-color: var(--kk-color-Ymain) !important;
}
.PrivateTabIndicator-root-1 {
  height: 5px !important;
}
.MuiTab-root {
  font-family: "Noto Sans KR", sans-serif !important;
}
.shop-state {
  display: flex;
}

@media screen and (max-width: 1280px) {
  .ordering {
    width: 100%;
  }
  .order-shop-list-frame {
    width: 100%;
  }
  .shop-info > .shop-price > p {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .sale-shop-list > li {
    /* height: 30vw; */
    border: 1px solid red;
    white-space: nowrap;
  }
  .sale-shop-list > li > span {
    /* font-size: 3vw; */
    font-size: 15px;
    padding: 10% 10%;
  }
  .shop-list > li {
    height: auto;
    gap: 5px;
  }
  .shop-list > li > .shop-img {
    min-width: 80px;
    height: 80px;
  }
  .shop-list > li > .product-img {
    min-width: 83px;
    height: 83px;
  }
  .shop-price p {
    font-size: 14px;
  }

  .shop-info > .info-top .shop-name {
    font-size: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    max-width: 200px;
  }
  .shop-state > span {
    min-width: 42px;
    height: 23px;
    line-height: 22px;
    font-size: 12px;
    margin-left: 3px;
  }

  .result-price > h3 {
    font-size: 13px;
  }
  .result-price > span {
    font-size: 15px;
  }
  .delivery-price > span {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .shop-info > .info-top .shop-name {
    max-width: 250px;
  }
}

@media screen and (max-width: 430px) {
  .shop-info > .info-top .shop-name {
    font-size: 16px;
    /* width: 50%; */
    max-width: 150px;
  }
}

@media screen and (max-width: 400px) {
  .shop-info > .info-top .shop-name {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 125px;
  }
}

.order-search-box {
  /* display: fixed; */
  /* bottom: 0; */
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* flex-shrink: 0; */
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 31px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 2px #0000000d;
  /* padding: 50px 0; */
}

.MuiTab-textColorInherit.Mui-selected {
  font-size: 1em;
}

.ordering .tabSection.tab-main-section > div {
  height: 100%;
}

.ordering .tabSection.tab-main-section > div > div {
  height: 100%;
}
