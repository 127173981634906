

.review-type-frame 
{
    width: 90%;
    margin: 0 auto;
    margin: 0 auto;
    width: 90%;
    height: auto;
    padding: 5%;
    display: flex;
    flex-direction: column;
}

.review-type-choice
 {
    margin: 20px;
}

.review-type-choice > .review-type-radio
{
    margin: 10px;
    display: flex;
    align-items: center;
}

.food-review-area .review-title > p{
    font-size: 18px;
    font-weight: bold;
     
 }

.review-type-choice > .review-type-radio > .review-type-radio-btn
{
    width: 20px;
    height: 20px;
}

.review-type-choice > .review-type-radio > .review-type-label
{
    margin: 10px;
    font-size: 18px;
    font-weight: 500;
}

.review-type-choice > .review-type-radio > .review-type-label:hover
{
    color: rgb(113, 112, 112);
}

.review-writing-btn
{
    float: right;
    text-align: center;
    margin-right: 30px;
    width: 150px;
    height: 40px;
    border: 2px solid #f4f4f4;
    border-radius: 100px;
    background-color: #fff;
    font-weight: bold;
    margin-left: 10px;
    color: #0e0e0e;
    font-weight: normal;
    cursor: pointer;
    transition: 0.3s;
} 

.review-writing-btn:hover
{
  border: 2px solid #ffc938;
  
}

