.Beautydetail-frame {
  margin: 0 auto;
  width: 90%;
  height: auto;
  margin: 50px;
}
.Beautydetail-frame > .img-wrap {
  width: 100%;
  height: auto;
}
/* 슬라이드 이미지*/

.img-wrap > .slide-img {
  max-width: 732px;
  height: 400px;
}

.order-menu-top-name {
  /* font-size: ; */
  text-align: center;
  padding: 10px 0;
  font-size: 21px;
  border-bottom: 8px solid #f5f6f6;
}
.slide-img > li {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.slide-img > li > img {
  display: block;
  margin: 0 auto;

  height: 100%;
}

/* 인디케이터 */
.box-bottom {
  width: 100%;
  height: 50px;
  display: flex;
  position: relative;
}
.box-bottom .indicator {
  margin: 0 auto;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.box-bottom .indicator li {
  display: inline-block;
  width: 100%;
  height: 100%;
  width: 15px;
  height: 15px;
  line-height: 30px;
  font-size: 0;
  background-color: #ebebeb;
  border-radius: 50px;
  margin-left: 10px;
  cursor: pointer;
}

.box-bottom .indicator li:hover {
  background-color: var(--kk-color-Ymain);
}

/* 않니..여기 있잖아? */
.box-bottom .indicator li.active {
  background-color: var(--kk-color-Ymain);
}

.box-bottom > .indicator-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
}

/* 공유하기,좋아요 버튼 */
.box-bottom .share {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  border: none;
  background-image: url(src_assets/share_mini.png);
  background-size: 30px;
  background-repeat: no-repeat;
}
.box-bottom .like {
  width: 30px;
  height: 30px;
  border: none;
  background-image: url(src_assets/like_mini.png);
  background-size: 30px;
  background-repeat: no-repeat;
}
.detail-wrap {
  width: 100%;
  height: auto;
}
.detail-wrap .detail-wrap-tab {
  width: 100%;
  height: 65px;
  display: flex;
}

.detail-wrap .detail-wrap-tab > input[type="radio"] {
  display: none;
}

.detail-wrap .detail-wrap-tab > #raview-tab[type="radio"] + label {
  width: 33.3%;
  height: 100%;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#raview-tab[type="radio"] + label::before {
  content: "리뷰";
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: 20px 20px 0 0;
}

#raview-tab[type="radio"]:checked + label::after {
  content: "리뷰";
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: 20px 20px 0 0;
}

#raview-tab[type="radio"]:checked + label::before {
  display: none;
}

/*  */
.detail-wrap .detail-wrap-tab > #detail-tab[type="radio"] + label {
  width: 33.3%;
  height: 100%;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#detail-tab[type="radio"] + label::before {
  content: "상세정보";
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: 20px 20px 0 0;
}

#detail-tab[type="radio"]:checked + label::after {
  content: "상세정보";
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: 20px 20px 0 0;
}

#detail-tab[type="radio"]:checked + label::before {
  display: none;
}

/*  */

.detail-wrap .detail-wrap-tab > #questions-tab[type="radio"] + label {
  width: 33.3%;
  height: 100%;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#questions-tab[type="radio"] + label::before {
  content: "문의";
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: 20px 20px 0 0;
}

#questions-tab[type="radio"]:checked + label::after {
  content: "문의";
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #efefef;
  border-radius: 20px 20px 0 0;
}

#questions-tab[type="radio"]:checked + label::before {
  display: none;
}

.Beautydetail-frame > .beauty-detail-info {
  margin-bottom: 10px;
  height: auto;
  padding: 5px 0px 5px 0px;
  border-top: 1px solid rgb(218, 218, 218);
  border-bottom: 1px solid rgb(218, 218, 218);
  font-weight: 300;
  display: flex;
  flex-direction: column;
  list-style-type: disc;
}

.beauty-detail-info > li {
  margin-bottom: 5px;
  display: flex;
  width: 100%;
}

.beauty-detail-info > li > p:first-child {
  width: auto;
  padding-right: 10px;
}

.beauty-detail-info > .detail-function {
  display: flex;
  justify-content: right;
}

.detail-function > button {
  border: none;
  margin-left: 5px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
}

.detail-function > .detail-share-button {
  background-image: url(src_assets/share_mini.png);
}

.detail-function > .detail-like-button {
  background-image: url(src_assets/like_mini.png);
}

/* 리뷰 쪽 문의 쪽이랑 CSS를 공유함 */

.detail-section {
  margin-top: 2%;
  width: 100%;
}
.detail-wrap .detail {
  width: 100%;
  height: auto;
}
.detail-section > .user {
  width: 100%;
  height: auto;
  margin-top: 30px;
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 3%;
}
.user-info {
  margin-bottom: 20px;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.user-info > .user-name {
  font-weight: 600;
}

.detail-section > .user > .user-Questions {
  width: 100%;
  height: auto;
}
.que-text {
  display: block;
  width: 100%;
  height: auto;
  border: none;
  color: #0e0e0e;
  font-weight: normal;
  font-size: 14px;
  resize: none;
  pointer-events: none;
  word-break: break-all;
}

.detail-section > .store {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 3%;
}

.detail-wrap .detail .user .user-Questions {
  width: 100%;
  height: auto;
}

.detail-section > .review-writing {
  margin-top: 50px;
  width: 100%;
  height: 90px;
  display: flex;
}

.review-writing > #review-weiting-input {
  width: 90%;
  height: 100%;
  box-shadow: 0 0 0 1px gray inset;
  box-sizing: border-box;
  padding: 5px;
  resize: none;
}

.review-writing > .review-weiting-confirm {
  width: 10%;
  height: 100%;
  font-weight: 600;

  cursor: pointer;
  border: none;
  text-align: center;
  background-color: var(--kk-color-Ymain);
}

.review-weiting-confirm:hover {
  background-color: var(--kk-color-hover-light);
}
.review-weiting-confirm:active {
  background-color: var(--kk-color-active);
}

/* 상세정보 */
.beauty-info-section {
  margin-top: 2%;
  width: 100%;
}

.beauty-info-section > .beauty-info {
  width: 100%;
  height: auto;
}

.beauty-info > .beauty-info-text {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #000;
  font-weight: 300;
  border: none;
  resize: none;
  padding: 10px;
}

.Beautydetail-frame > .back-page-button {
  display: block;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 35px;
  width: 264px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 40px;
  box-sizing: border-box;
  background-color: #b7b7b7;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.back-page-button:hover {
  background-color: var(--kk-color-Ymain);
}

/* 문의글 없을떄 */
.empty-text-area {
  width: 100%;
  height: 130px;
}
.empty-text-area .empty-imgg {
  width: 50px;
  height: 100px;
  padding-top: 10px;
  margin: 0 auto;
}
.empty-text-area .empty-imgg > img {
  width: 100%;
  height: 100%;
}
.empty-text-area .empty-text {
  text-align: center;
}

@media screen and (max-width: 1280px) {
  .Beautydetail-frame {
    margin: 0 auto;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  .slide-img > li {
    width: 100%;
    height: 80%;
    background-color: #fff;
  }
  .slide-img > li > img {
    padding-top: 10%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .Beautydetail-frame {
    margin: 0 auto;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  .slide-img > li {
    width: 100%;
    height: 80%;
    background-color: #fff;
  }
  .slide-img > li > img {
    padding-top: 10%;
    margin: 0 auto;
  }
}
