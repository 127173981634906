/*Mykiikey 에 공통으로 들어가는  css 입니다 */

.mykiikey-frame {
  margin: 0 auto;
  /* margin-bottom: 100px; */
  width: 1380px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.mykiikey-frame > .mykiikey-menu-list-area {
  margin-right: 20px;
  width: 430px;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 5px 0px 5px;
}

.mykiikey-menu-list-area > .menu-list {
  padding-top: 50px;
  border-top: 10px solid #f6f7f7;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-list > li {
  width: 100%;
  height: 50px;
  display: flex;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer;
}
/* .menu-list > li:hover a>.list-daps-bar
{
  opacity: 1;
} */

.menu-list > li > a {
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

/* .menu-list > li > a > .list-daps-bar
{
  margin-top: 23px;
  margin-right: 10px;
  width: 30px;
  height: 10%;
  border-radius: 30px;
  background-color: var(--kk-color-Ymain);
  opacity: 0;
} */

.menu-list > li > a > .list-daps {
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.list-daps > p {
  font-weight: 450;
}

.menu-list > li > a:hover > .list-daps > p {
  font-weight: 600;
}

.menu-list > li > a:hover > .list-daps::before {
  display: block;
}

.list-daps:before {
  display: none;
  content: "";
  position: absolute;
  border-radius: 4.5px;
  top: 25px;
  left: 0px;
  width: 110%;
  height: 9px;
  background-color: #fee900;
}

.list-daps > p {
  z-index: 1;
}

.menu-list > li > a > .menu-list-button {
  width: 50px;
  height: 100%;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/allow-button-right.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25%;
}

.mykiikey-frame > .mykiikey-menu-area {
  width: 840px;
  height: 100%;
  float: left;
  padding-top: 20px;
}

.daps-frame {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.daps-title-box {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 90%;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  border-bottom: 10px solid #f9fafa;
}

.daps-title-box > .daps-main-title {
  font-size: 30px;
  font-weight: bold;
}

.daps-title-box > .daps-sub-title {
  color: #a3a3a3;
}

.daps-ul-list {
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em 0;
}
.daps-ul-list-gift {
  margin: 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.daps-ul-list > li {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* padding: 25px 0px 25px 0px; */
  /* border-bottom: 1px solid rgb(219, 219, 219); */

  /* border-radius: 8px; */
  /* border: 1px solid #bdbdbd; */
  box-sizing: border-box;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
}

.daps-frame > .daps-paging-list {
  display: block;
  margin-top: 20px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  gap: 2%;
}

.daps-paging-list > button {
  width: 30px;
  height: 100%;
  border: none;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  background-color: transparent;
  transition-duration: 0.1s;
  cursor: pointer;
}

.peging-left-button {
  background-image: url(src_assets/allow-button-left.png);
}

.peging-left-button:hover {
  background-position: left;
}

.peging-right-button {
  background-image: url(src_assets/allow-button-right.png);
}

.peging-right-button:hover {
  background-position: right;
}

.daps-paging-list > li {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.1s;
}

.daps-paging-list > li:hover {
  color: #fff;
  background-color: var(--kk-color-Ymain);
}

.mobile-scroll-button {
  display: none;
  margin: 0 auto;
  margin-top: 50px;
  width: 80%;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.mobile-scroll-button:active {
  background-color: var(--kk-color-Ymain);
}

.daps-frame > .daps-empty-view {
  margin-top: 100px;
  width: 100%;
  height: 300px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.daps-empty-view > .mascot-img-box {
  width: 100%;
  height: 50%;
  position: relative;
}

.mascot-img-box > img {
  height: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.daps-empty-view > .empty-note {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.empty-note > .empty-main-title {
  font-size: 21px;
  font-weight: bold;
}

.empty-note > .empty-sub-title {
  color: #a3a3a3;
}

/* 페이지 버튼 */
.page-list {
  margin: 0 auto;
  margin-top: 30px;
  width: auto;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.page-list > li {
  width: 30px;
  height: 100%;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.page-list > li:hover {
  background-color: var(--kk-color-Ymain);
  border-radius: 50px;
  color: #fff;
}
.page-list > li:active {
  background-color: var(--kk-color-Ymain);
  border-radius: 50px;
  color: #fff;
}
.page-list > button {
  width: 25px;
  height: 100%;
  line-height: 50px;
  border: 1px solid transparent;
  background-color: #fff;
  font-size: 30px;
  cursor: pointer;
}

.page-list > .page-left-button {
  background-color: transparent;
  background-image: url(src_assets/allow-button-left.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.page-list > .all-page-left-button {
  background-color: transparent;
  background-image: url(src_assets/allow-button-left-double.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.page-list > .page-right-button {
  background-color: transparent;
  background-image: url(src_assets/allow-button-right.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.page-list > .all-page-right-button {
  background-color: transparent;
  background-image: url(src_assets/allow-button-right-double.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.mykiikey-frame.kapp {
  margin-top: 0px;
}

.mykiikey-menu-area.kapp {
  padding-top: 70px;
}

@media screen and (max-width: 1152px) {
  .mykiikey-frame {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .mykiikey-frame {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .mykiikey-frame > .mykiikey-menu-list-area {
    display: none;
  }
  .mykiikey-frame > .mykiikey-menu-area {
    width: 100%;
  }
  .daps-frame > .daps-paging-list {
    display: none;
  }
  .daps-frame > .mobile-scroll-button {
    display: block;
  }
  .page-list {
    width: 50%;
  }
}
