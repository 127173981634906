.header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -900;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  transition-duration: 0.3s;
  /* display: none; */
}
.header.main {
  background-color: var(--kk-color-Ymain);
}
.header.intro {
  background-color: var(--kk-color-introduction);
}
.header > .mobile-topnav-call-button {
  display: none;
}

.mobile-active {
  display: block;
}

.header > .nav-section {
  margin: 0 auto;
  width: 1280px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
}

.nav-section > a {
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .header {
    padding: 0 5% 0 5%;
  }
  .header > .nav-section {
    margin: 0 auto;
    width: 98%;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    /* z-index: -909; */
    top: 0;
    /* left: 50%; */
  }
  .header.main {
    background-color: rgba(0, 0, 0, 0);
  }

  .header > .mobile-topnav-call-button {
    display: block;
    width: 100px;
    height: 100%;
    position: absolute;
    z-index: 999;
    border: none;
    background-color: transparent;
    background-image: url("src_assets/mobile-menu-img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    cursor: pointer;
    display: none;
  }
  .order-menu-top-name {
    color: #0e0e0e;
    font-size: 21px;
    font-weight: 500;
  }
  .header-mobile-top {
    width: 100%;
    z-index: 989;
    height: 50px;
    /* padding: 40px 0; */
    background-color: #fff;
    position: fixed;
    top: 12px;

    box-sizing: border-box;
    left: 0;
    padding: 0 5%;
  }
  #mobileTitle {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
  }

  .header-mobile-top-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 100%;
    /* height: 100%;C */
    z-index: 999;
    position: absolute;
    /* top: 50%; */
    left: 20px;
    /* padding-right: 10%; */
    /* transform: translate(-50%, -50%); */
  }
  .header-mobile-top-button button {
    display: block;
    width: 17px;
    height: 17px;
    /* height: 100%; */
    border: none;
    background-color: transparent;
    background-image: url("src_assets/allow-button-left.png");
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: 50%;
    cursor: pointer;
  }
  .header-mobile-top-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    /*text-align: center;*/
    /* border: 1px solid red; */
    transform: translate(-50%, -50%);
    color: #000000;
    font-family: "Inter-ExtraBold", Helvetica;
    font-size: 32px;
    font-weight: 800;
    color: #000000;
    /* font-style: Medium; */
  }
}
.overlap {
  height: 31px;
  right: 20px;
  position: absolute;
  top: 15px;
  width: 28px;
}
.local-mall {
  height: 25px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 25px;
}
.text-wrapper-3 {
  color: #0084ff;
  font-family: "Inter-ExtraBold", Helvetica;
  font-size: 11px;
  font-weight: 800;
  height: 8px;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 8px;
}

.database {
  height: 25px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 25px;
}
.notifications-active {
  height: 25px;
  right: 56px;
  position: absolute;
  top: 20px;
  width: 25px;
}
