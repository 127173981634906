.shop-menu-list-frame {
  width: 940px;
  height: 100%;
  position: relative;
  font-family: noto-sans-cjk-kr, sans-serif;
  font-weight: 500;
  font-style: normal;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.shop-menu-list-frame > .shop-menu-list-inner {
  width: 840px;
  padding-bottom: 66px;
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.shop-menu-list-inner > .shop-menu-select {
  margin: 0 auto;
  width: 90%;
}

.shop-menu-list-inner > .thumbnailimg-box {
  width: 100%;
  height: 270px;
  overflow: hidden;
  background-color: #6dd5ed;
  background-position: 0%;
  background-repeat: no-repeat;
  position: absolute;
}

.thumbnailimg-box > img {
  display: block;
  margin: 0 auto;
  height: 80%;
  margin-top: 0;
}

.thumbnailimg-box > .jangboja-img {
  width: 100%;
  height: 100%;
}

.shop-menu-list-inner > .shop-menu-list {
  margin: 0 auto;
  margin-top: 33px;
  width: 100%;
  height: auto;
}

.shop-menu-list > .shop-menu-title {
  width: 90%;
  height: 196px;
  background-color: #fff;
  margin-bottom: 35px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 2px 2px 7px rgb(0 0 0 / 5%);
  border-radius: 5px;
  /* gap: 5%; */
  position: relative;
  margin: 0 auto 35px auto;
  /* padding: 10px 0; */
}

.shop-menu-title > .shop-menu-info-link {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/drawable/Exclamation.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.shop-menu-title > h2 {
  margin: 15px 0;
  text-align: center;
  font-size: 25px;
  width: 90%;
  margin: 0 auto;
  /* margin-bottom: 20px; */
}

.shop-menu-title > span {
  margin: 5px auto;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}

.shop-menu-title > span > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shop-menu-list > .shop-grade {
  display: block;
  height: 25px;
  display: flex;
}

.shop-grade > p:first-child {
  width: 110px;
}

.shop-grade > p > img {
  height: 100%;
}

.shop-grade > p:last-child {
  margin-left: 5px;
}
.shop-grade > p {
  /* margin-left: 5px; */
}

.shop-cost > p:first-child {
  width: 15px;
  height: 15px;
  margin-top: 1px;
  margin-right: 5px;
}

.shop-cost > p:last-child {
  margin-left: 5px;
}

.shop-cost > p > img {
  height: 15px;
  /* height: 100%; */
}

.shop-menu-title .all-review {
  width: 77px;
  height: 26px;
  /* font-weight: bold; */
  font-weight: 300;
  border: none;
  border-radius: 7px;
  position: absolute;
  background-color: #e6e6e6;
  bottom: 5%;
  right: 2%;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  /* margin: 10px; */
  /* margin: 10px 10px 10px 0; */
  /* margin-bottom: 10px; */
  /* margin-right: 3%; */
}

.all-review:hover {
  /* background-color: var(--kk-color-Ymain); */
}
.all-review-wrap {
  text-align: right;
  /* padding: 10px; */
}

.shop-menu-list > .shop-menu-info {
  width: 100%;
  height: auto;
  /* margin-bottom: 10px; */
  margin-bottom: 50px;
  /* border-bottom: 10px solid var(--order-border-bottom-); */
}

.shop-menu-info > .info-select {
  /* margin-bottom: 25px; */
  width: 100%;
  height: 55px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

/**/

.info-select > #tab-change-menu[type="radio"] {
  display: none;
}

.info-select > #tab-change-menu[type="radio"] + label {
  display: inline-block;
  width: 49%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  font-size: 19px;
  letter-spacing: -0.53px;
  color: #000;
}

.info-select > #tab-change-menu[type="radio"] + label::before {
  content: "메뉴";
  width: 105%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  color: rgba(14, 14, 14, 0.5);
}

.info-select > #tab-change-menu[type="radio"]:checked + label::after {
  box-sizing: border-box;
  content: "메뉴";
  color: #0e0e0e;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 2px solid var(--kk-color-Ymain);

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  font-weight: 500;
}

.info-select > #tab-change-menu[type="radio"]:checked {
  display: none;
}
.info-select > #tab-change-info[type="radio"]:checked {
  display: none;
}

/* .info-select > #tab-change-menu[type="radio"]:checked ~ #tab-change-info[type="radio"] + label{
}
.info-select > #tab-change-info[type="radio"]:checked ~ #tab-change-menu[type="radio"] + label{
} */
/**/

.info-select > #tab-change-info[type="radio"] {
  display: none;
}

.info-select > #tab-change-info[type="radio"] + label {
  display: inline-block;
  width: 49%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  letter-spacing: -0.53px;
  /* font-weight: 500; */
  font-family: NotoSansCJKkr;
  font-size: 19px;
}

.info-select > #tab-change-info[type="radio"] + label::before {
  content: "정보";
  width: 105%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  color: rgba(14, 14, 14, 0.5);
  border-bottom: 1px solid #e7e7e7;
  /* font-weight: 500; */
  /* font-family: "Noto Sans KR", sans-serif; */
  font-family: NotoSansCJKkr;
  font-size: 19px;
}

.info-select > #tab-change-info[type="radio"]:checked + label::after {
  content: "정보";
  width: 100%;
  height: 100%;
  color: #0e0e0e;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-right: 1px solid #e7e7e7;
  border-bottom: 2px solid var(--kk-color-Ymain);
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  font-weight: 600;
  /* font-family: "Noto Sans KR", sans-serif; */
  font-family: NotoSansCJKkr;
  font-size: 19px;
}

.intro-menu {
  position: relative;
}
.intro-menu::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: -25px;
  left: 0;
  background-color: #e7e7e7;
  z-index: -5;
}

/* .info-select  > button
{
  width: 49.8%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  background-color: #fff;
  border-top: 3px solid #e7e7e7;
  border-left: 3px solid #e7e7e7;
  border-right: 3px solid #e7e7e7;
  overflow: hidden;
} */

.shop-menu-intro {
  width: 100%;
}

.shop-menu-info > .menu-intro {
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  font-weight: 300;
  padding: 10px;
}

.menu-intro > span {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.shop-menu-info > .menu-info {
  display: none;
  box-sizing: border-box;
  padding: 10px;
}

/*매장 내 검색*/
.shop-search-area {
  width: 100%;
  height: auto;
  padding: 20px 0px 30px 0px;
  border-bottom: 10px solid var(--order-border-bottom-);
  border-top: 10px solid var(--order-border-bottom-);
}

.shop-search-area > .shop-search-box {
  margin: 0 auto;
  margin-top: 20px;
  width: 90%;
  height: 35px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #d3d3d3 inset;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.shop-search-box > #shop-search-input {
  width: 95%;
  height: 95%;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  padding-left: 10px;
}

.shop-search-box > .shop-search-button {
  width: 5%;
  height: 100%;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/search-img_g.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

/*매장 내 검색 끝*/

/*카테고리 검색*/
.shop-category-area {
  margin: 0 auto;
  width: 90%;
  height: auto;
  padding: 20px 0px 30px 0px;
  border-top: 10px solid var(--order-border-bottom-);
}

.shop-category-area > .shop-category-list {
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  width: 90%;
  display: flex;
}

.shop-category-list > li {
  width: 75px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.shop-category-list > li > .category-img {
  margin: 0 auto;
  width: 40px;
  height: 40px;
}

.category-img > img {
  width: 100%;
  height: 100%;
}

.shop-category-list > li > span {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

/* 카테고리 종류 */
.category-kind {
  margin-top: 20px;
  width: 100%;
  height: 30px;
  border-top: 2px solid #eceae9;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 30px;
}
.category-kind span {
  height: 100%;
  line-height: 30px;
  font-weight: 300;
  font-size: 15px;
  margin-right: 20px;
  position: relative;
}
.category-kind span:not(:first-child)::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #000;
  top: 5px;
  left: -10px;
}

/* 즉석식품.통조림 리스트 */
.shop-instant {
  margin-top: 10px;
  font-size: 17px;
  background-color: #eceae9;
  padding: 3px 5px;
  font-weight: 500;
}
.shop-instant-list {
  width: 100%;
  height: 105px;
  display: flex;
  border-bottom: 2px solid #eceae9;
}
.shop-instant-list img {
  width: 65px;
  height: 65px;
  padding-top: 20px;
}
.shop-instant-list .instant-name {
  width: 85%;
  height: auto;
  padding: 20px;
}
.shop-instant-list .instant-name > p {
  font-size: 14px;
  font-weight: 400;
}
.shop-instant-list .quantity {
  width: 15%;
  height: 20px;
  margin-top: 40px;
  display: flex;
  align-content: space-between;
}

/* +,-버튼 */
.btn {
  width: 30px;
  height: 30px;
  border: 1px solid #cacaca;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #fff;
  font-size: 23px;
}
.btn:nth-child(1) {
  margin-left: 10px;
}
.shop-instant-list .quantity span {
  margin: 15px;
  height: 5px;
  line-height: 5px;
}

/*매장내 검색결과 */
.shop-search-result {
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.shop-search-result > .shop-search-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 300;
  font-size: 14px;
}

.shop-search-list > li > span {
  display: flex;
  gap: 10px;
}

.shop-search-list > li > span > button {
  border-radius: 5px;
  border: none;
  color: #525252;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #e6e6e6;
}

.more-info {
  padding-left: 15px;
}

.more-info > li {
  display: flex;
  gap: 10px;
}

.more-info > li > button {
  border-radius: 5px;
  border: none;
  color: #525252;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #e6e6e6;
}

/*매장내 검색결과 끝 */

/*카테고리 검색결과 */

.shop-category-result {
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.shop-category-result > .shop-catagory-result-list {
  width: 100%;
  height: auto;
  padding-left: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  list-style-type: disc;
}

/*카테고리 검색결과 끝  */

@media screen and (max-width: 1280px) {
  .shop-menu-list-frame {
    width: 100%;
  }
  .shop-menu-list-frame > .shop-menu-list-inner {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .shop-menu-list-frame {
    width: 100%;
  }
  .shop-menu-list-frame > .shop-menu-list-inner {
    width: 100%;
  }
  .shop-menu-list-frame > .shop-menu-select {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
}
