.adList
{
  margin: 0 auto;
  width: 1152px;
  height: auto;
  box-sizing: border-box;
  padding-top: 65px;
}

.adList > .adList-title
{
  display: block;
  margin-bottom: 40px;
  width: 100%;
  height: auto;
  font-size: 28px;
  font-weight: bold;
}

.adList > ul
{
  display: block;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.adList > ul > li
{

  margin-bottom: 5%;
  width: 45%;
  height: 45%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
}

.adList > ul > li > img
{
  margin: 0 auto;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  transition-duration: 0.2s;
}

.adList > ul > li > img:hover
{
  transform: scale(0.95);
}
.adList > ul > li > span
{
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-family: 'Nanum Gothic';
}

@media screen and (max-width: 1280px) {
  .adList
  {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }
  
}

@media screen and (max-width: 768px)
{
  .adList
  {
    padding: 0px;
  }
  .adList > .adList-title
  {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 40px;
  }
  .adList > ul
  {
    flex-direction: column;
  }
  .adList > ul > li
  {
    width: 90%;
    height: 450px;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .adList > ul > li > span
  {
    text-align: left;
  }
}
