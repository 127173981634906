ul,
ol {
  list-style-type: none;
}
.banner-frame {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 600px;
  padding-top: 125px;
  box-sizing: border-box;
  background-color: var(--kk-color-Ymain);
  background-image: url(src_assets/coins.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-mobile-bg {
  display: none;
}

.banner-frame > .mobile-banner-img {
  display: none;
}

.banner-frame > .banner-inner {
  margin: 0 auto;
  width: 1200px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.banner-inner > .banner-left {
  width: 45%;
  height: 210px;
  box-sizing: border-box;
  color: #fff;
  box-sizing: border-box;
  padding-top: 10px;
}

.banner-left > .banner-text {
  width: 100%;
  max-width: 550px;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-size: 62px;
  font-family: "SUIT-ExtraLight";
}

.banner-text > span > b {
  font-family: "SUIT-Medium";
}

/* .banner-text > span >  b
{
    font-size: 70px;
    font-family: 'SUIT-Medium';
} */

.banner-inner > .banner-right {
  width: 55%;
  height: auto;
  position: relative;
}

.banner-right > .banner-tv {
  display: block;
  position: absolute;
  width: 90%;
  top: 82%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.banner-right > .mobile-tv {
  display: none;
}

.banner-right > .tv-ad-index {
  position: relative;
  max-width: 700px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tv-ad-index > .mobile-tv {
  display: none;
}
.tv-ad-index > .ad-show-button {
  margin: 0 auto;
  display: table;
  padding: 6%;
  background-color: #5e5e5e;
  border: none;
  border-radius: 100%;
  transition-duration: 0.2s;
  position: absolute;
  top: 62%;
  left: 57%;
  transform: translate(-50%, -50%);
  background-image: url(src_assets/yellow_tv_button_img.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  cursor: pointer;
}
.tv-ad-index > .game-show-button {
  width: 40%;
  margin: 0 auto;
  display: table;
  border-radius: 30%;
  transition-duration: 0.2s;
  position: absolute;
  top: 40%;
  left: 92%;
  transform: translate(-50%, -50%);
  /* background-image: url(src_assets/yellow_tv_button_img.png); */
  /* background-image: url(src_assets/coins.png); */
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  cursor: pointer;
}
.tv-ad-index > .game-show-button:active {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  content: url(src_assets/game_start_btn_mobile_hover.png);
}

.tv-ad-index > .game-show-button-mobile {
  display: none;
}

.ad-show-button:hover {
  background-color: var(--kk-color-Ymain);
}

.game-show-button:hover {
  outline: none;
  content: url(src_assets/game_start_btn_hover.png);
}

.tv-ad-index > .ad-number-index {
  margin: 0 auto;
  display: table;
  position: absolute;
  top: 136%;
  left: 56.5%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding-right: 1%;
  font-size: 1.5vw;
  color: #fff;
}

.ad-number-index > span {
  display: block;
  width: 45px;
  height: 100%;
  text-align: center;
  padding: 3px 0px 3px 0px;
}
.ad-number-index > span:first-child {
  color: #c76719;
  position: relative;
}

.ad-number-index > span:first-child::after {
  position: absolute;
  content: "";
  top: 8px;
  left: 42px;
  width: 2px;
  height: 1.5vw;
  background-color: white;
}
.ad-number-index > span:nth-child(2) {
  width: 37px;
}

.banner-inner > .banner-mid {
  width: 100%;
  height: auto;
}

.banner-mid > .count-number {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 30px;
}

.count-number > .count-text {
  width: 100%;
  height: auto;
  display: flex;
  color: #fff;
  flex-direction: column;
  margin-bottom: 15px;
  font-family: "SUIT-Medium";
}

.count-number > .ad-point-box {
  width: auto;
  height: auto;
}

.ad-point-box > .ad-point {
  width: 100%;
  height: 100%;
  display: flex;
}
.ad-point > .point-result {
  margin-left: 5px;
  width: 78px;
  height: 100%;
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #f9c112;
  border-radius: 1px;
  background-image: url(src_assets/point-bg.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ad-point > .comma {
  display: block;
  width: 8px;
  position: relative;
}

.ad-point > .comma > img {
  position: absolute;
  bottom: 3px;
  left: 70%;
  transform: translate(-50%, 0%);
  width: 80%;
  height: 15px;
}

@media screen and (max-width: 1280px) {
  .banner-frame > .banner-inner {
    width: 84%;
  }
  .banner-left > .banner-text {
    font-size: 50px;
  }
  .tv-ad-index > .ad-show-button {
    position: absolute;
    top: 68%;
    left: 57%;
    padding: 7%;
  }
  .tv-ad-index > .game-show-button {
    width: 40%;
    position: absolute;
    top: 48%;
    left: 90%;
  }

  .tv-ad-index > .ad-number-index {
    top: 70%;
    left: 56.5%;
    transform: translate(-50%, 430%);
  }
}

@media screen and (max-width: 768px) {
  .banner-frame {
    height: 750px;
    background-position: right;
    background-image: none;
    background-color: #fff;
    padding-top: 100px;
  }

  .banner-frame > .banner-mobile-bg {
    display: block;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
    height: 100%;
    border-radius: 100%;
    background-color: var(--kk-color-Ymain);
  }

  .banner-frame > .mobile-banner-img {
    display: block;
    position: absolute;
    width: 120%;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 998;
  }

  .banner-frame > .banner-inner {
    width: 100%;
    height: 100%;
  }
  .banner-inner > .banner-left {
    display: none;
  }
  .banner-inner > .banner-right {
    width: 100%;
    height: 400px;
  }

  .banner-frame > .mobile-banner-img {
    display: block;
    position: absolute;
    width: 120%;
    top: 0px;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, 0%);
  }

  .banner-right > .mobile-tv {
    display: block;
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .banner-right > .banner-tv {
    display: none;
  }

  .banner-right > .tv-ad-index {
    margin: 0 auto;
    width: 100%;
  }
  .tv-ad-index > .ad-show-button {
    padding: 30px;
    position: absolute;
    top: 41%;
    left: 50%;
  }

  .tv-ad-index > .game-show-button {
    display: none;
  }

  .tv-ad-index > .game-show-button-mobile {
    display: block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    content: url(src_assets/game_start_btn_mobile.png);
    width: 30%;
    position: absolute;
    top: 5%;
    left: 60%;
  }

  .game-show-button-mobile:active {
    outline: none;
    content: url(src_assets/game_start_btn_mobile_hover.png);
  }

  .tv-ad-index > .ad-number-index {
    width: 100px;
    height: 30px;
    top: 291px;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: 20px;
  }
  .ad-number-index > span:first-child::after {
    top: 9px;
    height: 18px;
  }
  .ad-point-box > .ad-point {
    margin: 0 auto;
    width: 90%;
    justify-content: space-around;
  }
  .banner-inner > .banner-mid {
    margin-top: -100px;
    width: 100%;
    color: #000000;
  }
  .count-number > .count-text {
    color: #000000;
    padding-left: 20px;
  }
  .ad-point > .point-result {
    margin-left: 1px;
    width: auto;
    height: auto;
    font-size: 11vw;
    padding: 1% 3% 1% 3%;
    border-radius: 10px;
  }

  .ad-point > .comma {
    margin-left: 1px;
    margin-right: 1px;
    padding: 0px 0px 0px 1px;
    position: relative;
  }
}

.play-ad {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.bannermodal-frame {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
