.personal-info {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 10%;
  font-family: "Nanum Gothic";
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7%;
}

.personal-info > .personal-area {
  margin: 0 auto;
  width: 65%;
  height: auto;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 70px;
  gap: 5%;
}

.personal-area > h2 {
  text-align: center;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.personal-area > .personal-info-box {
  width: 100%;
  height: 100%;
}

.personal-info-box > p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.personal-info-box > table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  border: 1;
}

.personal-info-box > table > tbody > tr > td {
  border: solid 1px #000000;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
}

.personal-info-box > table > tbody > tr:first-child {
  background-color: #d9d9d9;
  height: 50px;
  text-align: center;
}

.personal-info-box > .WordSection1 > table {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .personal-info {
    background-color: #fff;
  }
  .personal-info > .personal-area {
    width: 100%;
  }
}
