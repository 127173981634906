.profile-area {
  margin-bottom: 10px;
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.profile-area .photo {
  width: 85px;
  height: 100%;
  margin-right: 10px;
}

.profile-area .photo > img {
  height: 100%;
}
.profile-area .myname {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-area .myname > p {
  font-size: 17px;
  margin-bottom: 5px;
}

.profile-area .myname > p > b {
  font-size: 24px;
}

.profile-area > .profile-setting {
  position: absolute;
  bottom: 0px;
  right: 0px;
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
  border-radius: 30px;
  font-weight: bold;
  color: #191919;
  border: none;
  width: auto;
  height: 25px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.profile-area > .profile-setting:hover {
  background-color: #eee;
}

.profile-area > .profile-setting:active {
  background-color: var(--kk-color-active);
}

.my-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
}
.my-list > li {
  margin-bottom: 4%;
  flex: 1 1 40%;
  height: 90px;
  box-sizing: border-box;
  padding: 12px;
  box-shadow: 0 0 0 1px rgb(219, 219, 219);
  border-radius: 5px;
  cursor: pointer;
}

.my-list > li > .my-list-title {
  display: block;
  width: 100%;
  height: 20px;
  display: flex;
}

.my-list-title > p {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.my-list > li > .my-list-result {
  margin-top: 5%;
  display: block;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
}

.my-list-result > button {
  border: none;
  background-color: transparent;
  font-size: 30px;
  color: var(--kk-color-Ymain);
}

.my-list-result > p:last-child {
  box-sizing: border-box;
  padding-top: 15px;
  font-size: 16px;
  margin-left: 10px;
}

/* 내포인트 */
.my-list .point {
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border-bottom: 1px solid #090909;
}
.my-list .point p {
  width: 150px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  background-image: url(src_assets/mypoint.png);
  background-size: 28px 26px;
  background-repeat: no-repeat;
}
.my-list .point span {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: #ec9e99;
  font-weight: bold;
  text-align: center;
  margin-left: 200px;
}
.my-list .point span:last-child {
  width: 100px;
  color: #090909;
  font-size: 18px;
  text-align: center;
  margin-left: 30px;
}
/* 내리뷰 */
.my-list .review {
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border-bottom: 1px solid #090909;
}
.my-list .review p {
  width: 150px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  background-image: url(src_assets/myreview.png);
  background-size: 27px 24px;
  background-repeat: no-repeat;
}
.my-list .review span {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-left: 200px;
}
.my-list .review span:last-child {
  width: 100px;
  color: #090909;
  font-size: 18px;
  text-align: center;
  margin-left: 30px;
}
/*내스크랩 */
.my-list .scrap {
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border-bottom: 1px solid #090909;
}
.my-list .scrap p {
  width: 150px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  background-image: url(src_assets/myscrap.png);
  background-size: 32px 24px;
  background-repeat: no-repeat;
}
.my-list .scrap span {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  text-align: center;
  margin-left: 200px;
}
.my-list .scrap span:last-child {
  width: 100px;
  color: #090909;
  font-size: 18px;
  text-align: center;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
}
