.product_main_area {
  width: 100%;
  height: auto;
}
.shop_name_area {
  width: 100%;
  height: 80px;
  border-bottom: 10px solid rgba(243, 243, 243, 0.945);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pre_btn {
  width: 50px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pre_btn img {
  width: 10px;
  cursor: pointer;
}
.shop_name {
  width: 100%;
  text-align: center;
  font-family: "noto-sans-cjk-kr";
}

.product_sname {
  color: #0085ff;
  font-size: 11px;

  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
  margin: 0.5em 0;
}

.product_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;

  width: 100%;
  text-align: start;
  font-family: "noto-sans-cjk-kr";
}
.product_name h2 {
  line-height: 130%;
  width: 0;
  flex-grow: 1;
}

.Purchase_select {
  width: 100%;
  height: 50px;
  box-shadow: 1px 1px 3px 1px #eee;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}
.option_color {
  width: 20%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color_option {
  width: 80%;
  height: 30px;
}
.color_option img {
  width: 50px;
  height: 30px;
  border-radius: 5px;
}

.sub_color_option {
  width: 80%;
  height: 30px;
}
.sub_color_option img {
  width: 50px;
  height: 30px;
  border-radius: 5px;
}

.option_text {
  font-family: "Bahnschrift";
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
  border: 0;
  font-size: 18px;
}
.next_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.next_btn img {
  width: 10px;
  height: 18px;
}
.select_options_area {
  margin: 0 auto;
  width: 80%;
  height: auto;
}
.select_options {
  border: 1px solid #eee;
  margin: 0 auto;
  width: 80%;
  height: 150px;
}
.options {
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px dotted #eee;
}

.product_main_info {
  margin: 10px 0;
  width: 100%;

  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto;
  gap: 0.5em;
}

.product_status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-family: "Bahnschrift";
}

.discount {
  margin: 0 auto;
  width: 80%;
  height: 50px;
  color: #e81a49;
  font-family: "noto-sans-cjk-kr";
}
.discount span {
  color: #aaa;
  text-decoration: line-through;
  font-size: 18px;
}
.price,
.discount-price {
  width: 100%;
  font-size: 20px;
  margin: 0 auto;
  /* color: #e81a49; */
  font-family: "Bahnschrift";
}

.product_main_info .send_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
}

.product_main_info .send_btn label div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2em;
}

.product_main_info .send_btn label input {
  display: none;
}

.product_main_info .send_btn label div img {
  content: url("src_assets/icon/radio_button_unchecked.svg");
}

.product_main_info .send_btn label input:checked + div img {
  content: url("src_assets/icon/radio_button_checked.svg");
}

.product_main_info .pacel-price {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.shop-menu-list-inner .review-link-btn,
.shop-menu-list-inner .inquiry-link-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  padding: 0.5em 0;

  font-size: 15px;
  font-weight: 500;
  font-family: Inter;
  font-style: normal;
}

.shop-menu-list-inner .review-link-btn div,
.shop-menu-list-inner .inquiry-link-btn div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.btn_area {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.detail_info {
  margin: 0 auto;
  width: 80%;
  height: 50px;
}
.img_slide_area {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
}
.Slide_img {
  /* margin: 20px auto; */
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.Slide_img > img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
}
.tab_menu_btn_Area {
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  height: auto;
}
.tabmenu {
  width: 100%;
  height: 600px;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
}
.display_none {
  display: none;
}
.tabmenu img {
  width: auto;
  height: 500px;
  margin: 30px auto;
}
.tabmenu2 {
  width: 100%;
  height: auto;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: column;
}
.tabmenu2 img {
  width: 70%;
  height: auto;
  margin: 30px auto;
}
.tabmenu2-pre {
  white-space: pre-line;
  margin-left: 10px;
  margin-right: 10px;
  font-family: "noto-sans-cjk-kr-light";
  font-size: 12px;
  letter-spacing: -1px;
  line-height: 150%;
  color: #666;
}
.tabmanu_btn {
  cursor: pointer;
  color: #ccc;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 33.3%;
  height: 50px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-radius: 10px 10px 0 0;
}

.tabmanu_btn_gift {
  cursor: pointer;
  color: #191919;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 99.5%;
  height: 50px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}

.bold.tabmanu_btn {
  color: #333;
  font-weight: bold;
  font-family: "noto-sans-cjk-kr";
}
.more_info {
  cursor: pointer;
  background-color: #fff;
  width: 300px;
  height: 50px;
  box-shadow: 1px 1px 3px 1px #eee;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-family: "noto-sans-cjk-kr";
  z-index: 1;
}
.recommend_area {
  width: 100%;
  height: 250px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}
.Recommend_product_area {
  width: 90%;
  height: auto;
}
.Recommend_product {
  cursor: pointer;
  box-shadow: 1px 1px 3px 1px #eee;
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.recommended_area_Title {
  display: flex;
  flex-direction: column;

  margin: 1.5em 0;
}

.recommended_area_Title p {
  font-size: 18px;
  font-family: "noto-sans-cjk-kr";
  font-weight: 600;
  width: 100%;
  height: auto;
  /* text-align: center; */
  /* margin-top: 40px; */
  /* border-top: 10px solid rgba(238, 238, 238, 0.295); */
  /* padding-top: 40px; */
  padding: 40px 0;
}
.detail__btn {
  border-radius: 10px;
  text-align: center;
  width: 65px;
  height: 25px;
  background-color: #f1f0f0;
  color: #5f6060;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.Recommend_product img {
  width: 150px;
  height: 180px;
}
.recommend_name {
  text-align: center;
}
.recommend_price {
  margin-top: 10px;
  text-align: center;
  color: #e81a49;
}
.recommend_price span {
  color: #aaa;
  text-decoration: line-through;
  font-family: "Bahnschrift";
}
.recommend_price h3 {
  font-family: "Bahnschrift";
}

.Buy_btn {
  display: none;
  width: 90%;
  height: 50px;
  /* border: 1px solid #eee; */
  position: fixed;
  bottom: 0px;
  background: #fff;
  z-index: 1;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 6px 0;
}
.shopping_cart_btn {
  cursor: pointer;
  color: #333;
  display: none;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50px;
  border: 1px solid var(--kk-color-Ymain);
}

.Buy_btn .like-btn img {
  width: 45px;
  height: 45px;
}
.Buy_btn .Buying_btn,
.Buy_btn .direct-btn {
  cursor: pointer;
  /* display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 50px;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  border-radius: 8px;
  background: #bdbdbd;
  /* background-color: var(--kk-color-Ymain); */
  /* border: 1px solid var(--kk-color-Ymain); */
}

.Buy_btn .like-btn {
}
@media screen and (max-width: 768px) {
  .shopping_cart_btn {
    display: flex;
  }
  .Buying_btn {
    display: flex;
  }
  .Buy_btn {
    display: flex;
  }
  .pre_btn {
    position: absolute;
    left: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pre_btn img {
    width: 10px;
    height: 20px;
  }
  .recommend_area {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 50px;
  }
  .recommend_product_area {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .Recommend_product {
    width: 100px;
    height: 150px;
  }
  .Recommend_product img {
    width: 100px;
    height: auto;
  }
  .recommend_name {
    font-size: 13px;
  }
  .recommend_product_area:nth-child(3) {
    display: none;
  }
  .Recommend_product {
    width: 150px;
  }

  .img_slide_area {
    margin: 0 auto;
  }
}
