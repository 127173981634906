.food-review-area {
  margin: 0 auto;
  width: 90%;
  height: auto;
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.food-review-area > li {
  width: 100%;
  height: auto;
}

.star-check {
  width: 100%;
  height: 20px;
  display: flex;
}

.star-check > input[type="checkbox"] {
  display: none;
}

/* 여기서 부터 별표 인풋 CSS */
/* 원스타 */
.star-check > #one-star[type="checkbox"] + label {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.star-check > #one-star[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-empty.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #one-star[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-full.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #one-star[type="checkbox"]:checked + label::before {
  display: none;
}

/* 투스타 */
.star-check > #two-star[type="checkbox"] + label {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.star-check > #two-star[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-empty.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #two-star[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-full.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #two-star[type="checkbox"]:checked + label::before {
  display: none;
}
/* 쓰리스타 */
.star-check > #three-star[type="checkbox"] + label {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.star-check > #three-star[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-empty.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #three-star[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-full.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #three-star[type="checkbox"]:checked + label::before {
  display: none;
}
/* 포스타 */
.star-check > #four-star[type="checkbox"] + label {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.star-check > #four-star[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-empty.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #four-star[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-full.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #four-star[type="checkbox"]:checked + label::before {
  display: none;
}

/* 오성 */
.star-check > #five-star[type="checkbox"] + label {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.star-check > #five-star[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-empty.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #five-star[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(src_assets/star/single-star-full.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.star-check > #five-star[type="checkbox"]:checked + label::before {
  display: none;
}

.food-review-area .review-title {
  width: 100%;
}
.food-review-area .review-title > p {
  font-size: 18px;
  font-weight: bold;
}
.food-review-area .store-name {
  margin-top: 25px;
  width: 100%;
  height: 40px;
}
.food-review-area .store-name p {
  font-size: 18px;
  color: #8d8d8d;
}
.food-review-area .star-img {
  width: 65px;
  height: 20px;
  background-image: url(src_assets/four-star.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.food-review-area .review-box {
  margin-top: 30px;
  width: 100%;
  height: 300px;
}
.food-review-area .review-box > #text {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  resize: none;
  padding: 20px;
}
.food-review-area .camera-box {
  padding-top: 10px;
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
.food-review-area .camera-box input[id^="camera"] {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  margin-right: 10px;
}

input[id^="camera"][type="file"] {
  display: none;
}

input[id^="camera"][type="file"] + label {
  display: block;
  width: 75px;
  height: 75px;
  background-image: url(src_assets/cameras.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 18px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}

input[id^="camera"][type="file"] + label > .img-delete {
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: rgb(80, 80, 80);
  background-image: url(src_assets/list-delete-button.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

input[id^="camera"][type="file"] + label > .img-delete.block {
  display: block;
}

input[id^="camera"][type="file"] + label > .img-delete:hover {
  background-color: var(--kk-color-hover);
}

input[id^="camera"][type="file"] + label > img {
  width: 100%;
  height: 100%;
}

input[id^="camera"][type="file"] + label:hover {
  background-color: #ebebeb;
  background-image: url(src_assets/camera.png);
}

.food-review-area .review-save {
  margin-top: 50px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
}
.food-review-area .review-save > .review-save-button {
  width: 27%;
  height: 100%;
  background-color: var(--kk-color-Ymain);
  border: 0;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.review-save-button:hover {
  background-color: var(--kk-color-Ymain) !important;
}

.review-save-button:active {
  background-color: var(--kk-color-Ymain) !important;
}

@media screen and (max-width: 1152px) {
  .daps-frame {
    width: 100%;
  }
  .food-review-area {
    width: 100%;
  }
  .food-review-area .review-box {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .food-review-area .review-box {
    width: 85%;
  }

  .food-review-area .camera-box {
    width: 100%;
  }
  input[id^="camera"][type="file"] + label {
    width: 15vw;
    height: 15vw;
  }
  .food-review-area {
    margin: 0 auto;
    width: 90%;
    padding: 0;
  }
  .food-review-area .review-box {
    width: 100%;
  }
}
