.footer {
  width: 100%;
  height: 250px;
  background-color: #f7f7f7;
  box-sizing: border-box;
  padding-top: 2%;
  color: rgb(0, 0, 0);
  display: none;
}

.footer > .footer-inner {
  margin: 0 auto;
  width: 1280px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-inner > .footer-left {
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer-left > .footer-left-list {
  width: 100%;
  height: auto;
  justify-content: space-around;
  font-size: 14px;
}

.footer-left-list > li {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

.footer-left-list > li > p > .popup-show {
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.footer-left-list > li > p > a {
  width: 100%;
  height: 100%;
  font-weight: 550;
}

.footer-left-list > li > p > .page-move {
  display: none;
}

.footer-left-list > li > p > a:hover {
  text-decoration: underline;
}

.footer-left-list > li > p {
  border-right: 1px solid white;
  padding-left: 10px;
  padding-right: 10px;
}

.footer-left-list > li > p > a {
  display: block;
  width: 100%;
  height: 100%;
}

.footer-left-list > li > p > a > img {
  width: 100%;
  height: 100%;
}

.footer-left-list > li > p:first-child {
  padding-left: 0px;
}

.footer-left-list > li:first-child {
  padding-left: 0px;
}

.footer-left-list t > li:last-child {
  border-right: 0px;
}

.footer-right > .copyright {
  font-size: 12px;
  color: rgb(168, 168, 168);
}

.footer-inner > .footer-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer-right > .footer-right-list {
  width: 100%;
  height: auto;
  justify-content: space-around;
  font-size: 14px;
  text-align: right;
}

.footer-right-list > li {
  margin-bottom: 8px;
  word-spacing: 0%;
}
.footer-right-list > li > a {
  font-weight: bold;
}

.footer-right-list > li > a:hover {
  text-decoration: underline;
}

.footer-right > .copyright {
  display: block;
  width: 100%;
  text-align: right;
}

@media screen and (max-width: 1280px) {
  .footer > .footer-inner {
    width: 85%;
  }
  .footer-inner > .footer-left {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .footer-left-list > li {
    flex-wrap: wrap;
  }
  .footer-left-list > li > p {
    margin-top: 5px;
    padding-left: 0px;
  }
  .footer-left-list > li > p > .page-move {
    display: block;
  }
  .footer-left-list > li > p > .popup-show {
    display: none;
  }
}
