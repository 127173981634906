.adwatched-title {
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0e0e0e;
}

.adwatched-info {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  color: #919191;
}

.adwatched-button {
  width: 150px;
  height: 40px;
  border: 2px solid #f4f4f4;
  border-radius: 100px;
  background-color: #fff;
  font-weight: bold;
  margin-left: 10px;
  color: #0e0e0e;
  padding-left: 25px;
  font-weight: normal;
  cursor: pointer;
  background-image: url(src_assets/scarp.png);
  background-repeat: no-repeat;
  background-position: left 20px center;
  transition: 0.3s;
}

.adwatched-button:hover {
  border: 2px solid #6dd5ed;
}

@media screen and (max-width: 768px) {
  .adwatched-info > p {
    margin-bottom: 10px;
  }
}
