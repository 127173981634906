.shop-review {
  margin: 20px auto;
  width: 90%;
  height: auto;
}

.shop-review > .review-count {
  width: 100%;
  /* height: 80px; */
  display: flex;
  padding-bottom: 10px;
  /* padding: 10px 0; */
  justify-content: space-between;
}

.review-count > .review-class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
}

.review-class > li {
  /* display: flex; */
  margin-top: 3px;
}

.review-class > li > span {
  width: 100px;
}

.review-count > .review-select {
  margin-top: 6%;
  width: 150px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.review-select > input[type="checkbox"] {
  margin-right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  display: none;
}

.review-select > input[type="checkbox"] + label {
  margin-right: 10px;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.review-select > input[type="checkbox"] + label::before {
  content: "";
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  /* box-shadow: 0 0 0 2px #929292 inset; */
  background-image: url(src_assets/check_off.svg);

  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

.review-select > input[type="checkbox"]:checked + label::after {
  content: "";
  color: white;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url(src_assets/check_on.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain) inset; */
  /* background-color: var(--kk-color-Ymain); */
}

.review-select > input[type="checkbox"]:checked + label::before {
  display: none;
}

.review-select > p {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.shop-review > .review-show {
  width: 100%;
  height: auto;
}
/* 2022-07-18 김재용 */
.review-show.dis-none {
  display: none;
}

.review-show > .review-account {
  padding-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f5f6f6;
}

.review-account > .account-info {
  width: 200px;
  height: 100%;
  display: flex;
}

.account-info > .account-img {
  margin-right: 10px;
  width: 60px;
  height: 100%;
}

.account-info > .account-detail {
  display: block;
  width: 210px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.account-detail-text {
  font-size: 20px;
  margin-bottom: 4px;
  color: #0e0e0e;
}

.account-detail > p {
  width: 100%;
}

.account-detail > p > img {
  width: 50%;
}

.review-account > .account-img {
  display: block;
  float: right;
  width: 60px;
  height: 100%;
}

.account-img > img {
  height: 100%;
}

.review-account > .account-date {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  color: #909090;
  font-size: 13px;
  font-weight: 300;
  align-items: flex-end;
}

.review-show > .review-photo {
  width: 100%;
  height: 380px;
  display: flex;
  flex-direction: row;
}

.review-photo > span {
  display: block;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.review-photo > span > img {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 100%;
}

.review-show > .review-text {
  /* margin-top: 20px; */
  margin: 15px 0;
  /* margin-bottom: 5px; */
  width: 100%;
  height: auto;
  box-sizing: border-box;
  color: #0e0e0e;
  font-weight: 300;
}

.review-show > .review-order-history {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* padding-bottom: 30px; */
}

.review-show > .report {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 1px solid #d1d1d1;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.report > .report-button {
  width: 60px;
  height: 30px;
  border: none;
  text-align: right;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(src_assets/report.png);
  background-repeat: no-repeat;
  background-position: 10% 50%;
  background-size: 25%;
  padding-right: 5px;
  color: #a1a1a1;
}
/* 신고 모달창 220728지영*/
.report-modal-bg {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
.report-modal-frame {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 350px;
  min-height: 300px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 30px;
  background-color: #fff;
  z-index: 1003;
}
.report-modal-frame > h3 {
  margin-bottom: 7%;
}
.report-modal-frame > li {
  height: 35px;
}
.report-modal-frame > li > input {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.report-modal-frame > li > label {
  vertical-align: 3px;
  margin-left: 3%;
  cursor: pointer;
}
.report-modal-button {
  width: 290px;
  height: 38px;
  padding-top: 15px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
#report-cancle {
  width: 130px;
  height: 38px;
  background-color: #b7b7b7;
  border: 0;
  border-radius: 20px;
  color: #fff;
  font-size: 17px;
}
#report-save {
  width: 130px;
  height: 38px;
  background-color: var(--kk-color-Ymain);
  border: 0;
  border-radius: 20px;
  color: #fff;
  font-size: 17px;
}
#report-cancle:hover {
  background-color: #000;
}
#report-save:hover {
  background-color: var(--kk-color-Ymain);
}

.review-order-history > li {
  margin-right: 5px;
  width: auto;
  height: 100%;
  /* border-radius: 30px; */
  border-radius: 19px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  padding: 7px 14px;
  background-color: #f0f0f0;
  margin: 2px;
  font-weight: 600;
}
.review-order-history > li:nth-child(1) {
  background-color: #fff;
  font-weight: bold;
  /* margin: 0; */
  padding-left: 0px;
  border-radius: 0px;
}

.review-order-history > .review-order-title {
  color: #b6b6b6;
}

.review-show > .ceo-comment-box {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--order-border-bottom-);
}

.ceo-comment-box > .ceo-commnet {
  color: #0e0e0e;
  padding: 15px 0;
}

.ceo-comment-box > .ceo-comment-text {
  color: #0e0e0e;
  display: block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f6f6f6;
  font-weight: 300;
  line-height: 130%;
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 768px) {
  .shop-map > .map-api {
    /* height: 40vw; */
    /* display: none; */
  }
  .shop-review {
    box-sizing: border-box;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
  }
  .review-show > .review-photo {
    height: 35vw;
  }
}
