.categoty-search-frame {
  margin: 0 auto;
  width: 90%;
  height: auto;
  padding-bottom: 30px;
}

.categoty-search-frame > .category-search-list-box {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--order-border-bottom-);
  color: #707070;
}

.category-search-list-box > .category-search-list {
  display: flex;
  width: auto;
  height: auto;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  justify-content: left;
}

.category-search-list > li {
  margin: 0px 5px 0px 5px;
  flex-shrink: 0;
  width: auto;
  height: 35px;
  box-sizing: border-box;
  padding: 0px 10px 0px 0px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.category-search-list > li:hover {
  color: #0e0e0e;
  border-bottom: 6px solid var(--kk-color-Ymain);
}

.category-search-list > li.active {
  color: #0e0e0e;
  border-bottom: 6px solid var(--kk-color-Ymain);
}

.categoty-search-frame > .category-summary-list-box {
  margin-top: 25px;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  color: #707070;
  background-color: #f5f6f6;
}

.category-summary-list-box > .category-summary-list {
  display: flex;
  width: auto;
  height: auto;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  display: flex;
  justify-content: left;
}

.category-summary-list > li {
  margin: 0px 5px 0px 5px;
  flex-shrink: 0;
  width: auto;
  height: 25px;
  box-sizing: border-box;
  background-color: var(--kk-color-hover-light);
  border-radius: 25px;
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.category-summary-list > li:hover {
  background-color: var(--kk-color-Ymain);
  color: #fff;
}

.category-summary-list > li.active {
  background-color: var(--kk-color-Ymain);
}

.categoty-search-frame > .category-summary-cate-list-box {
  margin-bottom: 50px;
  width: 100%;
  padding-left: 2%;
  height: 50px;
  box-sizing: border-box;
  color: #707070;
  border-bottom: 1px solid var(--order-border-bottom-);
}

.category-summary-cate-list-box > .category-summary-cate-list {
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  justify-content: left;
  padding: 10px 0;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid var(--order-border-bottom-);
}

.category-summary-cate-list > li {
  margin: 0px 5px 0px 5px;
  flex-shrink: 0;
  width: auto;
  height: 35px;
  box-sizing: border-box;
  text-align: center;
  padding: 0px 5px 0px 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.category-summary-cate-list > li:hover {
  color: #0e0e0e;
  border-bottom: 6px solid var(--kk-color-Ymain);
}

.category-summary-cate-list > li.active {
  color: #0e0e0e;
  border-bottom: 6px solid var(--kk-color-Ymain);
}

.category-summary-list > li:last-child {
  border: none;
}

.categoty-search-frame > .category-item-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.category-item-list > .category-item-list-title {
  margin-top: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  background-color: rgb(212, 212, 212);
}
.category-item-list > li {
  width: 100%;
  min-height: 125px;
  height: auto;
  box-sizing: border-box;
  padding: 20px 0px 20px 0px;
  display: flex;
  border-bottom: 1px solid var(--order-border-bottom-);
}

.category-item-list > li > .list-item-img {
  margin-right: 10px;
  display: block;
  width: 85px;
  height: 85px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.list-item-img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.list-item-img > .menu-preparing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.menu-preparing.none {
  display: none;
}

.category-item-list > li > .list-item-info {
  width: 90%;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-item-info > .list-item-category-name {
  display: flex;
  flex-direction: column;
}

.list-item-info > .list-item-count {
  width: auto;
  height: 50px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-top: 2%;
}
@media screen and (max-width: 1280px) {
  .category-search-list-box > .category-search-list {
    overflow-x: scroll;
  }
  .category-summary-list-box > .category-summary-list {
    overflow-x: scroll;
  }
  .category-summary-cate-list-box > .category-summary-cate-list {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .categoty-search-frame {
    margin: 0 auto;
    width: 90%;
  }
  .categoty-search-frame > .category-search-list {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
  .category-search-list > li {
    min-width: 150px;
  }
  .categoty-search-frame > .category-summary-list {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
  .category-summary-list > li {
    min-width: 150px;
  }
  .categoty-search-frame > .category-summary-cate-list {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }
  .category-summary-cate-list > li {
    min-width: 100px;
  }
}
