.ordering {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .ordering {
    margin: 0 auto;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ordering {
    width: 100%;
    /* margin-bottom: 50px; */
    padding-top: 60px;
    box-sizing: border-box;
  }
}
.ordering .div-2 {
  background-color: #ffffff;
  height: 0;
  flex-grow: 1;
  position: relative;
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  overflow: auto;
}
.ordering .line {
  height: 2px;
  left: 0;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 999;
  object-fit: cover;
  padding: 0% 5%;
  box-sizing: border-box;
}
