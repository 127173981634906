/* 이상윤 220720 새 CSS 장보고 디테일 섹션 */
.jangboja-datail-section{
  margin: 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.jangboja-datail-section > .shop-ad-area
{
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.shop-ad-area > .shop-ad-area-title
{
  margin-bottom: 20px;
  font-size: 18px;
}

.shop-ad-area > .shop-ad-area-text
{
  width: 100%;
  min-height: 80px;
  height: auto;
  border: none;
  resize: none;
  background-color: #fff;
}

.jangboja-datail-section > .shop-info-list
{
  width: 100%;
  min-height: 100px;
  max-height: 300px;
  height: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 5px 0px 5px;
} 

.shop-info-list > li
{
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  padding: 5px 0px 5px 0px;
}

.shop-info-list > li:hover
{
  background-color: var(--kk-color-hover-light);
}

.shop-info-list > li > .shop-info-list-head
{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}

.shop-info-list-head > .shop-info-name
{
  box-sizing: border-box;
  padding-top: 1px;
}

.shop-info-list-head > .shop-info-detail
{
  font-weight: 300;
}

.shop-info-list > li > .shop-info-list-body
{
  width: 100%;
  height: auto;
  padding: 5px 0px 5px 0px;
  background-color: rgb(243, 243, 243);
}

.shop-info-list-body.dis-none
{
  display: none;
}

.shop-info-list-body > .shop-info-detail-list
{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
}
.shop-info-detail-list > li
{
  margin-bottom: 5px;
  display: flex;
}

.shop-info-detail-list > li > span:first-child
{
  margin-right: 5px;
  width: 85px;
}

.jangboja-datail-section > .frame-bar
{
  margin: 10px 0px 10px 0px;
  display: block;
  width: 100%;
  height: 10px;
  background-color: var(--order-border-bottom-);
}