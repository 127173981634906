.shop-menu-info > .intro-menu {
  width: 100%;
  height: auto;
  min-height: 165px;
  box-sizing: border-box;
  font-weight: 300;
  /* padding: 10px; */
  border-bottom: 10px solid #f5f6f6;
  padding: 20px 10px;
  margin: 0 auto;
}

.shop-menu-info > .intro-info {
  width: 100%;
  height: auto;
  min-height: 165px;
  box-sizing: border-box;
  font-weight: 300;
  padding: 10px;
}
.intro-menu-text {
  width: 90%;
  margin: 0 auto;
}
.adv52Icon {
  width: 100%;
  position: relative;
  border-radius: 8px;
  max-width: 100%;
  overflow: hidden;
  height: 81px;
  object-fit: cover;
  top: 40px;
}
