.shop-info-frame {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.shop-map-wrap {
  width: 100%;
  margin: 0 auto;
  border-bottom: 10px solid #f6f7f7;
}

.shop-info-frame .shop-map {
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 10px;
}

.shop-map h3 {
  margin-bottom: 10px;
}

.shop-map .map-api {
  width: 100%;
  height: 265px;
  /* border-radius: 10px; */
  box-shadow: 0 0 0 3px black inset;
  margin-top: 5px;
}

.shop-map .map-api-print {
  width: 100%;
  height: 500px;
  /* border-radius: 10px; */
  box-shadow: 0 0 0 3px black inset;
  margin-top: 5px;
}

.shop-info-frame .shop-info-detail {
  margin-bottom: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  padding-top: 10px;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* border-bottom: 10px solid #f6f7f7; */
}

.shop-info-detail .basic-info {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.basic-info>li {
  margin-bottom: 7px;
  display: flex;
}

.basic-info>li>span:first-child {
  width: 90px;
  font-weight: bold;
  color: #0e0e0e;
}

.basic-info>li>span:nth-child(2) {
  font-weight: 300;
}

.shop-info-detail>.opening-hours {
  width: 100%;
  height: auto;
  display: flex;
}

.opening-hours>span {
  width: 90px;
  font-weight: bold;
  color: #0e0e0e;
}

.opening-hours>.opening-date {
  display: flex;
  flex-direction: column;
}

.opening-date>li {
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.opening-date>li>span:first-child {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  text-align: center;
}

.opening-date>li>span:nth-child(2) {
  font-weight: 300;
  /* margin-top: 2.5px; */
}

.opening-date>li>.not-open {
  border-radius: 5px;
  background-color: #f5f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.opening-date>li>.do-open {
  border-radius: 5px;
  background-color: var(--kk-color-Ymain);
  color: #fff;
  line-height: 22px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.shop-info-frame>.delivery-tip {
  width: 100%;
  height: auto;
}

.delivery-tip>.price-class {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  gap: 10px;
}

.price-class>.tip-price-list {
  width: 100%;
  height: auto;
  display: flex;
  /* gap: 5px; */
  flex-direction: column;
}

.tip-price-list>li {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  line-height: 120%;
}

.delivery-tip>.location-class {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.location-class>li {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  font-weight: 300;
}

.location-class>li>span {
  display: flex;
  line-height: 120%;
}

.location-class>li>span:nth-child(2) {
  display: block;
  min-width: 100px;
  text-align: right;
}

.delivery-tip>.night-delivery {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.night-delivery>li {
  display: flex;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 300;
  gap: 10%;
}

.ceo-info-wrap {
  border-top: 10px solid #f6f7f7;
  width: 100%;
}

.shop-info-frame .ceo-info {
  width: 90%;
  height: auto;
  /* margin: 0 auto; */
  margin: 20px auto;
}

.ceo-info>.ceo-info-title {
  display: block;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ceo-info>.ceo-info-table {
  width: 50%;
  height: 100%;
}

.ceo-info-table>tbody {
  display: flex;
  flex-direction: column;
}

.ceo-info-table>tbody>tr {
  width: 100%;
  margin-bottom: 7px;
  display: flex;
}

.ceo-info-table>tbody>tr>td:first-child {
  width: 110px;
  margin-right: 10px;
  font-weight: bold;
  color: #0e0e0e;
}

.ceo-info-table>tbody>tr>td:nth-child(2) {
  font-weight: 300;
  width: 100%;
}

.shop-info-frame .order-title {
  font-size: 18px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.shop-map .order-title {
  width: 90%;
}

.tip-price-list-text {
  font-weight: bold;
  color: #0e0e0e;
  margin-bottom: 10px;
}

.tip-price-list-night {}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 768px) {
  .shop-info-frame {
    box-sizing: border-box;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    padding: 20px 0px;
  }

  .shop-info-frame>.delivery-tip {
    width: 90%;
    margin: 20px auto;
    /* margin: 0 auto; */
  }

  .delivery-tip>.night-delivery {
    width: 100%;
  }

  .ceo-info>.ceo-info-table {
    width: 100%;
  }

  .shop-menu-info-btn {}

  .shop-menu-info-btn>button {
    width: 50px;
    height: 50px;
    border-radius: 250px;
    border: 0;
    background-color: #fff;
    /* box-shadow: 10px 10px 10px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: fixed;
    bottom: 20%;
    right: 10%;
    z-index: 999;
    color: var(--kk-color-Ymain);
    font-size: 40px;

    /* box-shadow: 0px 5px 5px 2px gray inset; */
  }
}