.loginRoute {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 10%;
  font-family: "Nanum Gothic";
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7%;
}

.loginRoute > .loginRoute-frame {
  margin: 0 auto;
  width: 768px;
  height: 470px;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.loginRoute-frame > .frame-title {
  margin: 0 auto;
  margin-top: 5%;
  width: 80%;
  height: 50px;
  box-sizing: border-box;
  padding-bottom: 2%;
  border-bottom: 1px solid gray;
}

.frame-title > h2 {
  text-align: center;
}

.loginRoute-frame > .member-type {
  margin: 0 auto;
  margin-top: 50px;
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 5%;
}

.member-type > div {
  width: 45%;
}

.member-type > div > h3 {
  width: 100%;
  text-align: center;
}

.member-type > div > span {
  display: block;
  width: 100%;
  height: 30%;
  border-radius: 10px;
  margin-bottom: 10%;
  position: relative;
}

.member-type > div > span > img {
  height: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.member-type > div > button {
  border: none;
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 100px;
  background-color: var(--kk-color-button);
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.member-type > div > button:hover {
  background-color: var(--kk-color-Ymain);
}

@media screen and (max-width: 768px) {
  .loginRoute {
    background-color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .loginRoute > .loginRoute-frame {
    height: 570px;
    display: flex;
    flex-direction: column;
    padding-top: 15%;
    padding-bottom: 15%;
    gap: 7%;
  }
  .loginRoute-frame > div {
    margin: 0 auto;
    border-radius: 10px;
    background-color: rgb(248, 248, 248);
    box-shadow: 2px 2px 5px rgba(129, 129, 129, 0.3);
  }
  .loginRoute-frame > div > span {
    margin-top: 10%;
  }
  .loginRoute-frame > div > span > img {
    height: 100%;
  }
  .loginRoute-frame > div > button {
    margin: 0 auto;
    width: 80%;
  }
  .loginRoute-frame > .member-type {
    flex-direction: column;
  }
  .member-type > div {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
  }
  .member-type > div > h3 {
    display: none;
  }
  .member-type > div > span {
    display: none;
  }
  .member-type > div > span > img {
    display: none;
  }
  .member-type > div > button {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    padding-top: 90px;
    transition-duration: 0.1s;
  }

  .member-type > div > button:hover {
    background-color: rgb(165, 90, 75);
  }
  .corporate-member-box > button {
    background-image: url(src_assets/corporate.png);
    background-repeat: no-repeat;
    background-position: 50% 25%;
    background-size: 65px;
  }
  .general-member-box > button {
    background-image: url(src_assets/general.png);
    background-repeat: no-repeat;
    background-position: 50% 25%;
    background-size: 25%;
  }
}
