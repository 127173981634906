/*뷰티 카테고리CSS 는 하나의 CSS로 관리함 */

/*뷰티 카테고리 타입1 */
.beautycate-frame {
  margin: 0 auto;
  width: 90%;
  height: auto;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.beautycate-frame > .cate-list {
  margin-top: 10px;
  width: 100%;
  height: 100%;
}

.cate-list > .cate-list-head {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cate-list-head > .cate-list-title {
  display: block;
  width: auto;
  height: auto;
  box-sizing: border-box;
  padding: 0px 5px 0px 5px;
  left: 5%;
  background-color: #fff;
  position: absolute;
  z-index: 1;
}

.cate-list-head > .cate-list-info {
  width: 100%;
  height: auto;
  min-height: 140px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 30px 20px 15px 20px;
}

.cate-list-info::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 12px;
  left: 0px;
  border-radius: 30px;
  box-shadow: 0 0 0 2px gray;
}

.cate-list-info > .cate-info-img {
  margin-right: 10px;
  width: 100px;
  height: auto;
  box-sizing: border-box;
  position: relative;
}

.cate-info-img > img {
  width: 100%;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cate-list-info > .cate-info-text {
  display: block;
  width: 90%;
  height: 100%;
}

.cate-info-text > p {
  display: flex;
}

.cate-list > li {
  margin: 40px 0px 40px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--order-border-bottom-);
}

.cate-list > li > .list-item-img {
  margin-right: 10px;
  display: block;
  width: 85px;
  height: 85px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.list-item-img > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.list-item-img > .menu-preparing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.menu-preparing.none {
  display: none;
}

.cate-list > li > .list-item-info {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-item-info > .list-item-name {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item-name > .item-name {
  font-size: 18px;
}

.list-item-name > .item-detail {
  font-size: 16px;
  color: var(--kk-color-hover);
}

.item-detail > img {
  margin-top: 5px;
  margin-right: 5px;
}

.item-detail > p {
  display: flex;
}

.list-item-info > .list-item-name > span {
  display: flex;
}

.list-item-name > span > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item-name > span > img {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item-info > div > span > img {
  height: 15px;
  border-radius: 10px;
}

.list-item-price {
  width: auto;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item-price > span {
  display: flex;
  flex-wrap: wrap;
}

.list-item-price > span > p {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item-price > span:last-child {
  display: block;
  text-align: right;
  font-size: 16px;
  color: rgb(255, 190, 190);
}

#list-item-check {
  display: none;
}

#list-item-check[type="checkbox"] + label {
  margin-top: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

#list-item-check[type="checkbox"] + label::before {
  content: "";
  color: #e7e7e7;
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  /* box-shadow: 0 0 0 2px #5f5f5f; */
  background-image: url(src_assets/check_off.svg);

  border-radius: 2px;
  border-bottom: none;
}

#list-item-check[type="checkbox"]:checked + label::after {
  content: "";
  color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  text-align: center;
  position: absolute;
  background-image: url(src_assets/check_on.svg);

  top: 0px;
  left: 0px;
  /* box-shadow: 0 0 0 2px  var(--kk-color-Ymain); */
  /* background-color: var(--kk-color-Ymain); */
}

#list-item-check[type="checkbox"]:checked + label::before {
  display: none;
}
/*뷰티 카테고리 타입1 끝 */

/*뷰티 카테고리 타입2 */

.option-frame {
  margin: 0 auto;
  width: 90%;
  height: auto;
  padding-bottom: 30px;
  border-bottom: 10px solid var(--order-border-bottom-);
}

.option-frame > .option-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.option-list > .option-title {
  margin-bottom: 20px;
  display: block;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
}

.option-list > li {
  display: flex;
  margin-top: 7px;
}

.option-list > li > .option-check-box {
  width: 35px;
}

.option-check-box > #option-check[type="checkbox"] {
  display: none;
}

#option-check[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

#option-check[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  background-image: url(src_assets/check_off.svg);

  /* box-shadow: 0 0 0 2px var(--kk-color-Ymain); */
  border: none;
  border-radius: 5px;
}

#option-check[type="checkbox"]:checked + label:after {
  content: "";
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  background-image: url(src_assets/check_on.svg);

  /* background-color: var(--kk-color-Ymain); */
  border: none;
  border-radius: 5px;
}
#option-check[type="checkbox"]:checked + label:before {
  display: none;
}

.option-list > li > .option-name-box {
  margin-left: 5px;
  width: 125px;
}

.option-list > li > .option-price-box {
  margin-left: 1px;
  width: auto;
}

/*뷰티 카테고리 타입2 끝 */

/* 뷰티 카테고리 타입3
이것은 카테고리 타입 1을 프레임을 기반으로 변경되거나
추가된 부분의 CSS */
.list-item-img > .deatil-button {
  width: 100%;
  border: none;
  display: block;
  color: #aaaaaa;
  text-align: center;
  background-color: transparent;
  background-image: url(src_assets/info_circle_icon.png);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 15%;
}

.list-item-info > .list-item-detail {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item-detail > .item-price {
  display: flex;
  flex-direction: column;
}

.item-price > span {
  display: flex;
}

.item-price > span > p:first-child {
  margin-right: 10px;
}
.item-price > span > img {
  margin-top: 9px;
  margin-right: 3px;
  margin-left: 3px;
  height: 50%;
}

.item-price > span:nth-child(1) {
  font-weight: 300;
}

.list-item-count-box {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item-count-box > .list-tiem-count {
  display: flex;
  justify-content: right;
}

.list-item-count-box > .discount {
  margin-top: 10px;
  text-align: right;
  color: rgb(255, 146, 57);
}

/* 뷰티 카테고리 타입3 끝 */

/* 예약 달력 뷰(모달임) */

.reservation-date-frame {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44, 0.3);
  z-index: 1000;
}

.reservation-date-frame > .reservation-date-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 650px;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.reservation-date-box > .reservation-close {
  margin-bottom: 5px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  cursor: pointer;
  border: none;
}

.reservation-date-box > .reservation-date {
  width: 100%;
  height: 70%;
  box-sizing: border-box;
}

.reservation-date-box > .time-select {
  margin-top: 5%;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
}

.time-select > .reservation-time {
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding-bottom: 15px;
  overflow-x: scroll;
  display: flex;
}

.reservation-time > input[type="radio"] {
  display: none;
}

.reservation-time > input[type="radio"] + label {
  margin-right: 10px;
  display: block;
  min-width: 55px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.reservation-time > input[type="radio"] + label:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(212, 212, 212, 0.2);
}

.reservation-time > input[type="radio"]:checked + label:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
}

.time-select > .reservation-button {
  display: block;
  margin: 0 auto;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  width: 150px;
  height: 35px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.reservation-button:hover {
  background-color: var(--kk-color-hover);
}

.reservation-button:active {
  background-color: var(--kk-color-active);
}

/* 뷰티 카테고리 반응형 */
@media screen and (max-width: 768px) {
  .beautycate-frame {
    margin: 0 auto;
    width: 90%;
  }

  .cate-list > li {
    margin: 0 auto;
    width: 95%;
    padding: 30px 0% 30px 0%;
  }
  .option-frame {
    margin: 0 auto;
    width: 90%;
  }
  .reservation-date-frame > .reservation-date-box {
    width: 100%;
  }
}
