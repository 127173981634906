@charset "utf-8";

.statement-frame {
  margin: 0 auto;
  width: 90%;
  height: auto;
  box-sizing: border-box;
}

.statement-frame > .statement-paper {
  margin: 0 auto;
  width: 515px;
  /* margin-top: 50px; */
  height: auto;
  box-sizing: border-box;
  /* background-image: url(src_assets/statement-frame.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px;
  display: flex;
  flex-flow: column;
  gap: 1em;
  position: relative;
  /* border-left: 1px solid #eaeaea; */
  /* border-right: 1px solid #eaeaea; */
}
/* .statement-paper > .order-paper-bg{
  width: 511px;
  height: auto;
  position: absolute;
  z-index: -1;
}
.statement-paper > img{
  width: 100%;
  height: auto; */

.statement-paper > .statement-close {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: right;
}

.statement-close > button {
  width: 35px;
  height: 25px;
  border: none;
  font-weight: bold;
  color: #767676;
  cursor: pointer;
  border-radius: 10px;
}

.statement-close > button:hover {
  background-color: var(--kk-color-hover-light);
}

.statement-close > button:active {
  background-color: var(--kk-color-active);
}

.statement-paper > .statement-title-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.statement-title-box > span {
  display: block;
  display: flex;
}

.statement-title-box > .statement-title {
  justify-content: center;
}

.statement-title > p:first-child {
  margin-right: 1px;
  font-size: 26px;
}

.statement-title > p:nth-child(2) {
  padding-top: 6px;
  color: #767676;
}

.statement-title-box > .statement-shop-title {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
  font-weight: 600;
  text-decoration: underline;
  text-underline-position: under;
  justify-content: right;
  cursor: pointer;
  box-sizing: border-box;
}
.statement-title-box > .statement-shop-title p:hover {
  background-color: #fee900;
  transition: 0.3s;
}

.statement-paper > .delivery-address {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 2px dashed rgb(83, 83, 83);
}

.delivery-address > span {
  margin-bottom: 5px;
  display: block;
  display: flex;
}

.delivery-address > span > p {
  font-size: 14px;
  font-weight: 600;
  color: rgb(83, 83, 83);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.delivery-address > .address-head > p:first-child {
  margin-right: 5px;
  font-size: 18px;
  color: #000;
}

.statement-paper > .statement-message {
  margin-bottom: 10px;
  font-weight: 600;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  border-bottom: 2px dashed #767676;
}

.statement-message > span {
  margin-bottom: 5px;
  display: flex;
}

.statement-message > span > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  color: #767676;
}

.statement-message > span > p:first-child {
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.statement-message > .common-message {
  font-weight: 600;
}

.statement-paper > .statement-list-title {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 2px dashed #767676;
}

.statement-list-title > span {
  display: flex;
}

.statement-list-title > .statement-menu-name {
  width: 65%;
}

.statement-list-title > .statement-detail {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
}

.statement-detail > p {
  width: 50%;
  text-align: center;
}

.statement-paper > .statement-list-area {
  width: 100%;
  height: auto;
  font-weight: 600;
  font-size: 10px;
  /* padding-bottom: 10px; */
  /* border-bottom: 2px dashed #767676; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statement-paper > .statement-list-area .statement-body-name > p {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statement-list-area > li > .statement-list-head {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.statement-list-head > span {
  display: block;
}
.statement-list-head > .statement-head-name {
  width: 65%;
}

.statement-list-head > .statement-head-detail {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
}

.statement-head-detail > p {
  width: 50%;
  text-align: center;
}
.statement-head-detail > p:last-child {
  padding-right: 6px;
  text-align: right;
}
.statement-list-area > li > .statement-list-body {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #767676;
}

.statement-list-body > .list-option-name {
  margin-bottom: 8px;
  display: block;
  width: 100%;
}

.statement-list-body > li {
  display: flex;
  justify-content: space-between;
}

.statement-list-body > li .statement-body-name {
  width: 65%;
}

.statement-list-body > li .statement-body-detail {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
}

.statement-body-detail > p {
  width: 50%;
  text-align: center;
}
.statement-body-detail > p:last-child {
  text-align: right;
  padding-right: 6px;
}

.statement-list-area > .statement-tips {
  margin-top: 50px;
  width: 100%;
  height: auto;
}

.statement-tips > .statement-tips-info {
  display: flex;
  box-sizing: border-box;
  padding: 0px 6px 0px 0px;
  justify-content: space-between;
}

.statement-paper > .statement-list-result {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  color: #0e0e0e;
  border-bottom: 2px dashed #767676;
}

.statement-list-result > span {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 6px 0px 0px;
}

.statement-paper > .statement-payment-check {
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 2px dashed #767676;
}

.statement-payment-check > span {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0px 6px 0px 0px;
}

.statement-paper > .statement-info {
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
  height: auto;
  color: #767676;
}

@media screen and (max-width: 768px) {
  .statement-frame {
    margin: 0 auto;
    width: 100%;
  }
  .statement-frame > .statement-paper {
    width: 100%;
    padding: 20px;
  }

  .statement-paper > .statement-list-area {
    font-size: 9px;
  }
  .statement-paper > .statement-list-area .statement-body-name > p {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* .statement-frame {
  width: 840px;
  height: auto;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 20px;
}

.content-title {
  font-size: 15px;
  font-weight: 600;
  float: left;
  height: 30px;
  line-height: 30px;
}

.content {
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 15px;
  padding-bottom: 15px;
  padding-top:1px;
  width: 511px;
  height: auto;
}

.wrap {
  width: 420px;
  height: auto;
  margin: 0 auto;
  margin-top: 50px;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: url(src_assets/statement-frame.png);
  background-repeat: no-repeat;
  background-size: cover;
}


.wrap > .title 
{
  text-align: center;
  width: 100px;
  margin: 0 auto;
  height: 30px;
}

.wrap .title p 
{
  font-weight: bold;
  font-size: 25px;
  float: left;
}

.wrap .title span {
  font-size: 13px;
  color: #4b4a4a;
  font-weight: bold;
  height: 30px;
  line-height: 45px;
}


.wrap .location {
  text-align: right;
  height: auto;
}

.wrap .location p {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  text-decoration: underline;
  text-underline-position: under;
}



.wrap .address {
  width: 100%;
  height: auto;
  margin-top: 20px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px dashed #d7d7d7;
}

.wrap .address .content-title {
  font-size: 15px;
  font-weight: 600;
  float: left;
  height: 30px;
  line-height: 30px;
}

.wrap .address span {
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  color: #4b4a4a;
}

.wrap .address p {
  font-size: 12px;
  color: #4b4a4a;
}

.wrap .address p::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -10px;
  left: 0;
}


.wrap .notice {
  margin-top: 10px;
  width: 100%;
  height: auto;
  margin-top: 15px;
  padding-bottom: 10px;
  border-bottom: 2px dashed #d7d7d7;
}

.wrap .notice h3 {
  font-size: 13px;
  font-weight: bold;
  float: left;
  height: 20px;
  line-height: 20px;
}

.wrap .notice span {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  color: #4b4a4a;
}

.wrap .notice-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.wrap .notice-list li {
  display: flex;
  gap: 2px;
}

.wrap .notice-list li .notice-head {
  font-weight: 600;
  font-size: 15px;
}

.wrap .notice h2 {
  font-size: 14px;
}

.wrap .notice h2:last-child:last-child {
  position: relative;
}

.wrap .notice h2:last-child::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -10px;
  left: 0;
}

.menu-table
{
  width: 100%;
  height: auto;
  color: #767676;
}

.menu-table > caption
{
  display: none;
}

.menu-table > .menu-title
{
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #000;
}


.menu-title > tr
{
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 2px dashed #d7d7d7;
  font-weight: bold;
}

.menu-title > tr > td:first-child
{
  width: 60%;
}


.menu-title > tr > td
{
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.menu-title > tr > td > p
{
  display: block;
  width: 50%;
  text-align: center;
}



.menu-list > tr
{
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dilivery-tip 
{
  margin-top: 30px;
}


.menu-point > tr
{
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}



.menu-list > tr > td:first-child
{
  width: 60%;
}


.menu-list > tr > td
{
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.menu-list > tr > td > p
{
  display: block;
  width: 50%;
  text-align: center;
}

.menu-list > tr > td > p:nth-child(2)
{
  text-align: right;
}

.menu-list > .menu-list-name
{
  font-weight: bold;
  color: #000;
}



.menu-table > .menu-point
{
  display: block;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 2px dashed #d7d7d7;
}

.menu-point > tr
{
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: space-between;
}

.menu-table > .menu-result
{
  display: block;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 2px dashed #d7d7d7;
  border-bottom: 2px dashed #d7d7d7;
  color: #000;
}

.menu-result > tr
{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
}






테이블
caption {
  display: none;
}

.menu {
  width: 100%;
  margin-top: 3px;
  border-collapse: collapse;
  height: 60px;
  margin-top: 10px;
}

th:nth-child(1) {
  width: 65%;
  text-align: left;
}

th:nth-child(2) {
  width: 15%;
  text-align: center;
}

th:nth-child(3) {
  width: 20%;
  text-align: center;
}

td:nth-child(2) {
  width: 10%;
  text-align: center;
  text-align: center;
}

td:nth-child(3) {
  width: 25%;
  text-align: center;
  text-align: right;
}


카피라이트
.wrap .copy {
  width: 100%;
  height: auto;
  font-size: 11px;
}

.wrap .copy ul {
  margin-top: 10px;
}

.wrap .copy ul li {
  color: #797979;
  font-weight: bold;
}


#back_btn_div {
  display: inline-block;
  float: right;
}


#back_btn {
  float: right;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 15px;
  font-weight: bold;
}

@media screen and (max-width: 1280px)
{

}

@media screen and (max-width: 768px)
{
  .statement-frame
  {
    width: 100%;
  }
  .statement-frame > .content
  {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
  }
  .content > .wrap
  {
    margin: 0 auto;
    width: 100%;
    background-image: url(src_assets/statement-frame.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
} */

.statement-paper .statement-payment-result > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
