.shop-empty-frame
{
  margin: 0 auto;
  margin-top: 150px;
  width: 90%;
  height: auto;
  position: relative;
}

.shop-empty-frame >.shop-empty-area
{
  position: absolute;
  top:5%;
  left:50%;
  transform: translate(-50%,0%);
  width: 300px;
  height: 450px;
  display: flex;
  flex-direction: column;
}

.shop-empty-area > .empty-img
{
  width: 100%;
  height: 50%;
  position: relative;
}

.empty-img > img
{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.shop-empty-area > .empty-text
{
  display: block;
  width: 100%;
  font-size: 18px;
  text-align: center;
}

@media screen and (max-width: 768px)
{
  .shop-empty-frame
  {
    height: 450px;
  }

}
