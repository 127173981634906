.store-area{
    margin: 0 auto;
    margin-top: 30px;
    width: 90%;
    height: auto;
}
.store-wrap{
    width: 100%;
    height: 710px;
    
}
/* 동네이름과 찜하기 갯수 */
.store-area .town-title{
    margin-bottom: 10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #000;
    box-sizing: border-box;
}
.store-area .town-title .name{
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.name > img
{
    margin-right: 10px;
    height: 100%;
}

.store-area .town-title .name > p
{
    width: auto;
    height: 100%;
    text-align: left;
    font-size: 20px;
    font-weight: bold;

}
.store-area .town-title .favorite-number{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}
.store-area .town-title .favorite-number p{
    width: auto;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    text-align: right;
    font-weight: bold;
    color: #ed1d32;
}

.favorite-number > img
{
    margin-left: 5px;
    margin-right: 5px;
    height: 100%;
}

.store-area .town-title .favorite-number span{
    width: auto;
    font-size: 25px;
    text-align: left;
    font-weight: normal;
    color: #ed1d32;
    height: 20px;
    line-height: 20px;
}
/* 가게 리스트 */
.store-area{
    margin: 0 auto;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.store-area > li
{
  width: 100%;
  min-height: 125px;
  height: auto;
  display: flex;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 1%;
  padding-right: 1%;
  border-bottom: 1px solid #f6f5f5;
  border-top:  1px solid #f6f5f5;
  cursor: pointer;
}
/* .store-area > li:hover
{
    background-color: var(--kk-color-hover-light);
} */

.store-area > li .store-img{
    margin-right: 10px;
    width: 85px;
    height: 85px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.store-img > img
{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    border-radius: 15px;
}

.store-img > .menu-preparing
{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0,0.5);
  color: #fff;
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  text-align: center;
}

.menu-preparing.none
{
    display: none;
}

.store-area > li > .store-detail-box
{
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.store-detail-box > .store-detail
{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.store-detail > .store-info
{
    width: auto;
    height: auto;
    margin-bottom: 20px;
}

.store-info > .store-name
{
    font-weight: 600;
}

.store-info > .store-grade
{
    margin-top: 5px;
    height: 15px;
}

.store-grade > img
{
    margin-right: 10px;
    height: 100%;
}

.store-detail > .store-state
{
    display: block;
    width: auto;
    display: flex;
}

.store-state > p
{
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
    width: 45px;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color:#838383;
    background-color: rgb(216, 216, 216);
    border-radius: 30px;
}

.store-detail-box > .store-price
{
    height: 20px;
}

.store-detail-box > .store-price > span
{
    display: flex;
    font-size: 15px;
}

.store-detail-box > .store-price > span > img
{
    margin-right: 5px;
    height: 100%;
}




@media screen and (max-width: 768px)
{
    .store-state > p
    {
        margin: 0 2px 0px 2px;
        width: 35px;
        height: 25px;
        font-size: 10px;
        border-radius: 10px;
    }
    .store-area
    {
        margin: 0 auto;
        width: 90%;
    }

}

@media screen and (max-width: 650px){
   


}

