/* 이상윤 220720 새 CSS 장보고 이미지 눌렀을때 나오는 모달 창 CSS */

.jangboja-img-modal-frame
{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44,0.3);
  z-index: 1000;
}

.jangboja-img-modal-frame > .jangboja-img-modal
{
  position: relative;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 750px;
  height: 61%;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
}

.jangboja-img-modal > .modal-close-button
{
  position: absolute;
  top:22px;
  right:22px;
  width: 40px;
  height: 40px;
  font-size: 25px;
  border: none;
  color: #c2c2c2;
  background-color:transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border: 2px solid #c2c2c2;
  border-radius: 100%;
  cursor: pointer;
}

.modal-close-button:hover
{
  background-color: var(--kk-color-hover);
}

.jangboja-img-modal > .product-img-slide
{
  margin-top: 10%;
  width: 100%;
  height: 75%;
  position: relative;
}

.product-img-slide > li
{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.product-img-slide > li > img
{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  height: 100%;
}

.product-img-slide > button
{
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: rgb(194, 194, 194,0.6);
  padding: 5px 0px 5px 0px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition-duration: 0.1s;
}

.product-img-slide > button:hover
{
  background-color: rgb(213, 213, 213,0.8);
}

.product-img-slide> .slide-button-left
{
  position: absolute;
  top:50%;
  left:1%;
  transform: translate(0%,-50%);
  z-index: 1;
  background-image: url(src_assets/allow-button-left-w.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}

.product-img-slide > .slide-button-right
{
  position: absolute;
  top:50%;
  left:99%;
  transform: translate(-100%,-50%);
  z-index: 1;
  background-image: url(src_assets/allow-button-right-w.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
}

@media screen and (max-width: 768px)
{
  .jangboja-img-modal-frame > .jangboja-img-modal
  {
    width: 100%;
  }
  .jangboja-img-modal > .product-img-slide
  {
    margin-top: 15%;
  }
}
