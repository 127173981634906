.notfoundpage-frame
{
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
  height: 500px;
  padding-top: 80px;
}

.notfoundpage-frame > .notfoundpage-inner
{
  margin: 0 auto;
  width: 650px;
  height: 500px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-image: url(src_assets/404Nfound.png);
}