.answer-form {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
}

.answer-form > .answer-head {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  display: flex;
}

.answer-head > img {
  margin-top: 10px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.answer-head > .answer-text {
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  font-size: 21px;
}

.answer-form > .answer-result {
  width: 100%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
}

.answer-result > img {
  width: 30px;
  height: 30px;
}

.answer-form > .page-scrap {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.page-scrap > button {
  width: 45%;
  height: 100%;
  border: none;
  font-weight: bold;
  font-size: 22px;
  background-color: var(--kk-color-Ymain);
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
}

.page-scrap > button:hover {
  background-color: var(--kk-color-hover);
}

.page-scrap > button:active {
  background-color: var(--kk-color-active);
}

.answer-form > .answer-comfirm {
  margin-top: 20px;
  float: right;
  border: none;
  width: 100px;
  height: 50px;
  font-weight: bold;
  font-size: 22px;
  background-color: #b7b7b7;
  border-radius: 100px;
  color: #fff;
}

.answer-comfirm {
  background-color: var(--kk-color-hover);
}

.answer-comfirm {
  background-color: var(--kk-color-active);
}

@media screen and (max-width: 768px) {
  .answer-form {
    width: 95%;
  }
}
