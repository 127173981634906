.game-page {
  width: 100%;
  min-height: 650px;
  position: relative;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, 0%);
}

.game-form {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  border: 7px solid #ffd533;
  padding: 20px;
}

.game-app-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  border-radius: 25px;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  border: 7px solid #ffd533;
  padding: 10px;
}

.game-app {
  width: 460px;
  margin-top: 50px;
}

/* 틀린그림 스타일 */
#box2 {
  background-repeat: no-repeat;
}

#description {
  display: inline-block;
  text-align: left;
}

#myProgress {
  position: relative;
  margin: 10px 0 10px 0;
  width: 100%;
  height: 30px;
  background-color: #ddd;
}

#myBar {
  position: absolute;
  width: 1%;
  height: 100%;
  background-color: #9adb46;
}

/* .a {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
} */

/* .image_fade {
    position: absolute;
    animation: scale1 0.5s infinite alternate;
}  */

.image_fade,
.image_fade_answer,
.image_fade_sad {
  position: absolute;

  /* animation: scale1 0.5s infinite alternate; */
  width: 50px;
  height: 50px;
}

.image_fade_answer {
  transform: translate(-50%, -50%);
}

/* .image_fade_answer */
.img_list,
.img_list2 {
  position: relative;

  /* width: 50px; */
  /* height: 50px; */
}

.image_fade {
  position: absolute;
  /* top: 20px;
    left:-70px; */
  width: 600px;
  height: 600px;
}

.image_fade,
.image_fade_sad {
  animation: scale1 0.5s infinite alternate;
}

.game-app-form > .game-app > canvas {
  width: 460px;
  min-height: 288px;
}

@keyframes scale1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.game-app-head {
  position: absolute;
  /* background-color: var(--kk-color-Ymain); */
  top: 50%;
  left: 50%;
  z-index: 1111;
  transform: translate(-50%, -530%);
  /* text-align: left; */
}
.game-app-head > img {
  width: 200px;
  margin-right: 250px;
}

.game-foot {
  height: auto;
  text-align: center;
}

.game-modal > .game-foot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 240%);
}

.game-foot > img {
  width: 190px;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .game-form,
  .game-app-form {
    top: 50%;
    width: 350px;
  }
  .game-app-form > .game-app > canvas {
    width: 310px;
    min-height: 212px;
  }

  .game-modal > .game-app-head {
    height: auto;
    transform: translate(-50%, -529%);
  }
  .game-app-head > img {
    width: 140px;
    margin-right: 170px;
  }

  #myProgress {
    width: 310px;
  }

  .game-modal > .game-foot {
    position: absolute;
    transform: translate(-50%, 330%);
  }

  .game-modal > .game-foot > img {
    width: 140px;
  }
}
