.account-check {
  margin: 0 auto;
  width: 90%;
  padding-top: 35px;
}
.account-check > span {
  width: 100%;
  height: 30px;
  color: #919191;
}
.account-check > .account-input {
  margin-top: 50px;
}

.account-input > span {
  margin-right: 10px;
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #090909;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.account-input > .info-change-comfirm {
  margin-left: 10px;
  width: 27%;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 30px;
  border: none;
  background-color: var(--kk-color-Ymain);
  cursor: pointer;
  color: #fff;
}

.info-change-comfirm:hover {
  background-color: var(--kk-color-Ymain);
}

.info-change-comfirm:active {
  background-color: var(--kk-color-active);
}

#id-check {
  width: 50%;
  height: 35px;
  border-radius: 100px;
  border: 1px solid gray;
  box-sizing: border-box;
  padding-left: 10px;
}

#password-check {
  width: 50%;
  height: 60px;
  background-color: #f3f3f3;
  border: 0;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 16px;
}
