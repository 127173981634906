.Modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
   
  /* modal창 */
  .modalBody-wid {
    position: absolute;
    width: 100%;
    height: auto;

    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  }
  .modalBody-hei {
    position: absolute;
    width: auto;
    height: 80%;

    text-align: center;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  }
  .modalBody-wid > img {
    width: 95%;
    height: auto;
  }
  .modalBody-hei > img {
    width: auto;
    height: 100%;
  }
  .modalPrice {
    width: 95%;
    min-height: 80px;
    margin-top: -5px;
    margin-left: 2.5%;
    font-size: 28px;
    font-weight:450;
    color: rgb(250, 50, 60);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   
  #modalCloseBtn {
    position: relative;
    margin-left: 90%;
    border: none;
    color: rgba(0, 0, 0, 0.7);
    background-color: transparent;
    font-size: 30px;
  }
   
  #modalCloseBtn:hover {
    cursor: pointer;
  }