/*Mykiikey 에 공통으로 들어가는  css 입니다 */

.gift-table {
  width: 100%;
}
.gift-img > .menu-preparing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  text-align: center;
  font-size: 20px;
}
.gift-img {
  position: relative;
  text-align: center;
  width: 50%;
  padding-top: 10px;
}
.gift-img > img {
  width: 50%;
}
.gift-list-head {
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-around;
}
.gift-list-head > .gift-list-state {
  font-weight: 600;
  font-size: 18px;
  width: 85%;
}
.order-list-head {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
/* 지점이름 */
.order-list-head > .shop-name {
  font-weight: 600;
  font-size: 18px;
  width: 85%;
}
.order-list-head > span:not(:first-child) {
  margin-left: 10px;
}
/* 창닫기 */

.order-list-head > .order-list-state {
  width: auto;
}

.order-list-state > .order-state {
  margin-right: 10px;
  box-sizing: border-box;
  padding: 5px 15px 5px 15px;
  background-color: #cacaca;
  color: #fff;
  font-weight: 600;
  border-radius: 30px;
}

.order-list-state > .shop-name {
  font-weight: 600;
  font-size: 18px;
}

.order-list-head > .order-list-delete {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: none;
  background-color: transparent;
  border: 2px solid #cacaca;
  cursor: pointer;
  color: #cacaca;
}

.order-list-delete:hover {
  background-color: var(--kk-color-hover-light);
}

.order-list-function {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.order-list-head-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5em;
}

.order-list-info {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding: 0.5em;
}
/* 작성일자 */
.order-info {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  padding: 0.5em;
  /* flex-direction: column-reverse; */
  /* font-weight: 300; */
}
.order-info > p {
  margin-bottom: 5px;
  color: #919191;
  font-size: 16px;
  height: 25px;
  line-height: 25px;
}
/* 버튼 3종 */

.order-list-function > .order-list-buttons {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.order-list-buttons > li {
  width: auto;
  margin-left: 10px;
  margin-bottom: 5px;
  height: 40px;
}

.orderlist-button {
  width: 100px;
  height: 100%;
  border: 2px solid #f4f4f4;
  background-color: transparent;
  border-radius: 100px;
  box-sizing: border-box;
  padding-top: 9px;
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.order-list-buttons {
  display: flex;
  flex-wrap: nowrap;
}
.order-list-function > .order-list-buttons > button {
  width: 150px;
  height: 40px;
  border: 2px solid #f4f4f4;
  border-radius: 100px;
  background-color: #fff;
  font-weight: bold;
  margin-left: 10px;
  color: #0e0e0e;
  padding-left: 25px;
  font-weight: normal;
  cursor: pointer;
  transition: 0.3s;
}
.order-list-buttons > .order-cancle-button:hover {
  border: 2px solid #ff3a47;
  color: #ff3a47;
}
.order-list-buttons > .order-cancle-button {
  background-image: url(src_assets/icon/cancel-button.png);
  background-size: 22px;
  background-repeat: no-repeat;
  /* color: #ff3a47 !important; */
  background-position: left 20px center;
}
.order-list-buttons > .share-button {
  background-image: url(src_assets/share.png);
  /* background-size: 32px; */
  background-repeat: no-repeat;
  background-position: left 20px center;
}
.order-list-buttons > .orderdetail-button {
  background-image: url(src_assets/review.png);
  background-repeat: no-repeat;
  background-position: left 20px center;
}
.order-list-buttons > .review-button {
  background-image: url(src_assets/orderdetail.png);
  background-repeat: no-repeat;
  background-position: left 20px center;
}

.order-list-buttons > button:hover {
  border: 2px solid #ffc938;
}

.chagne-button {
  width: 90px;
  height: 33px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #d2d2d1;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
/* 공유하기 */
.share-modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
}
.share-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  width: 570px;
  height: 300px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  padding: 23px 23px;
}
.share-modal ul {
  width: 100%;
  display: flex;
  margin-left: 20px;
  /* justify-content: center; */
}
.share-modal ul:first-child {
  margin-bottom: 30px;
}
.share-modal ul li {
  width: 77px;
  height: 77px;
  font-size: 14px;
  margin-right: 10px;
  text-align: center;
}
.share-modal ul li img {
  width: 77px;
  height: 77px;
}
/* url박스 */
.url-box {
  width: 490px;
  height: 50px;
  display: flex;
  margin: 50px 0 0 20px;
}
.url {
  display: inline-block;
  width: 218px;
  width: 405px;
  height: 25px;
  height: 50px;
  padding: 0 9px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #222;
  line-height: 50px;
  vertical-align: middle;
  text-decoration: none !important;
  background-color: #f6f6f6;
}

.url-box .url-copy-button {
  display: inline-block;
  height: 25px;
  height: 50px;
  width: 85px;
  margin-left: -1px;
  left: -2px;
  padding: 0 7px 0 7px !important;
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  text-decoration: none !important;
  vertical-align: middle;
  background-color: var(--kk-color-Ymain);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.share-delete-btn {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 100px;
  border: 2px solid #d2d2d1;
  background-color: transparent;
  background-image: url(src_assets/list-delete-button.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  cursor: pointer;
  margin-left: 92%;
}
.share-delete-btn:hover {
  border: 2px solid #d1d1d1;
  background-color: #f4f4f4;
}
/*공유하기 모달*/

.daps-frame > .share-modal-frame {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44, 0.3);
  z-index: 1000;
}

.share-modal-frame > .share-route-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 125px;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 5px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.share-route-box > li {
  margin-left: 10px;
  margin-right: 10px;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.share-route-box > li > .icon-img {
  display: block;
  width: 100%;
  height: 75%;
  border-radius: 100%;
  background-color: var(--kk-color-Ymain);
  position: relative;
}

.share-route-box > li > .icon-img > a {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 45px;
  color: #fff;
  transform: translate(-50%, -50%);
}

.share-route-box > li > .icon-text {
  text-align: center;
  font-weight: 600;
}

/*공유하기 모달*/

.daps-frame > .review-upload-modal-frame {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44, 0.3);
  z-index: 1000;
}

.review-upload-modal-frame > .review-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 500px;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.review-upload > .review-title {
  display: block;
  width: 100%;
  height: auto;
}

.review-title > p:first-child {
  margin-bottom: 5px;
  font-size: 28px;
  font-weight: 600;
}

.review-title > p:nth-child(2) {
  font-size: 18px;
}

.review-upload > .star-grade-box {
  width: 100%;
  height: 30px;
  display: flex;
}

/* 난중에 여기에 백그라운드 별 넣기 */
.star-grade-box > label {
  margin-right: 5px;
}

.review-upload > .review-text {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 150px;
  resize: none;
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
}

.review-upload > .photo-upload-box {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  padding: 0px 3% 0px 3%;
  display: flex;
  justify-content: space-between;
}

.photo-upload-box > li {
  width: 100px;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}

/* 난중에 여기에 사진이미지 넣기 */
.photo-upload-box > li > button {
  width: 100%;
  height: 100%;
  border: none;
  background-image: url(src_assets/drawable/ic_camera.png);
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: center;
  cursor: pointer;
}

.photo-upload-box > li > button:hover {
  background-color: rgb(190, 190, 190);
}

.photo-upload-box > li > button > img {
  width: 100%;
  height: 100%;
}
.review-upload > .review-confirm {
  margin: 0 auto;
  margin-top: 15px;
  width: 80%;
  height: 50px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.review-confirm:hover {
  background-color: var(--kk-color-hover-light);
}

.review-confirm:active {
  background-color: var(--kk-color-active);
}

/* 바코드 */
.barcode-use-list-head {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.barcode-use-list-state > .shop-name {
  font-weight: 700;
  font-size: 20px;
  height: 21px;
  line-height: 20px;
}

.barcode-use-info {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}
.barcode-use-info > .use-date {
  margin-bottom: 9px;
  color: #b9b9b9;
  font-size: 20px;
  height: 25px;
  line-height: 25px;
}
.barcode-use-info > .use-price {
  margin-bottom: 5px;
  color: #000000;
  font-weight: 700;
  font-size: 26px;
  height: 26px;
  line-height: 26px;
  align-self: end;
}
.barcode-use-info > .use-type {
  margin-bottom: 5px;
  color: #b9b9b9;
  font-weight: 400;
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  align-self: end;
}

@media screen and (max-width: 1280px) {
  .order-list-function {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .order-list-function > .order-list-buttons {
    margin-bottom: 10px;
    width: auto;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .mykiikey-frame {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .mykiikey-frame > .mykiikey-menu-list-area {
    display: none;
  }
  .mykiikey-frame > .mykiikey-menu-area {
    width: 100%;
  }
  .daps-frame > .daps-paging-list {
    display: none;
  }
  .daps-frame > .mobile-scroll-button {
    display: block;
  }
  .order-list-function {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .order-list-function > .order-list-buttons {
    margin-bottom: 10px;
    width: auto;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  .order-list-function > .order-list-buttons > button {
    width: 110px;
    padding-left: 25px;
  }

  .order-list-buttons > .order-cancle-button {
    background-position: left 10px center;
  }
  .order-list-buttons > .share-button {
    background-position: left 10px center;
  }
  .order-list-buttons > .orderdetail-button {
    background-position: left 10px center;
  }
  .order-list-buttons > .review-button {
    background-position: left 10px center;
  }

  .order-list-function > .order-list-buttons > button:first-child {
    margin-left: 0;
  }

  .share-modal {
    width: 90%;
  }

  .share-modal > .url-box {
    width: 90%;
    background-color: red;
    display: flex;
  }

  .url-box > .url {
    width: 90%;
  }
  .share-modal ul li {
    width: 55px;
    height: 55px;
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
  }
  .share-modal ul li img {
    width: 55px;
    height: 55px;
  }
}

.order-list-search-filter {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.order-list-search-filter label input {
  display: none;
}
.order-list-search-filter label {
  width: max-content;
}
.order-list-search-filter label span {
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  padding: 0.5em 0.5em 0.25em;
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #828282;
  min-width: 72px;
  text-align: center;
}

.order-list-search-filter label input:checked + span {
  background: #bdbdbd;
  color: #fff;
}

.order-history-item-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0 1em;
  margin-top: 10px;
}

.order-history-item-btn-container > div {
  width: 100%;
  height: 39px;
  border-radius: 8px;
  background: #bdbdbd;

  /* Button Shadow */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
  /* padding: 0.7em 1em 0.5em; */
  padding-top: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}
