@charset "utf-8";

.questions-list {
  width: 100%;
  height: auto;
  border-top: 1px solid #b1b1b1;
}
caption {
  display: none;
}
.list {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-collapse: collapse;
  height: 150px;
  text-align: left;
  table-layout: fixed;
}
/* 질문 */
.questions-list {
  margin: 0 auto;
  width: 90%;
  height: auto;
  color: #0e0e0e;
  display: flex;
  flex-direction: column;
}

.questions-list > li {
  height: auto;
  padding-top: 20px;
  border-bottom: 1px solid #d2d2d1;
}
.questions-list > li > .questions-head {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
}

.questions-list > li:hover {
  /* background-color: var(--kk-color-hover-light); */
  cursor: pointer;
}

.questions-head > .questions-text {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
}
.questions-head > .questions-text span {
  height: auto;
}
.questions-head > .questions-text > p {
  font-size: 16px;
  color: #919191;
  font-weight: normal;
  height: auto;
  line-height: 30px;
  margin-bottom: 10px;
}

.questions-detail > .open-answer-button {
  float: right;
  width: 45px;
  height: 15px;
  border: none;
  background-color: transparent;
  background-image: url(src_assets/allow-button-top-b.png);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.questions-list > li > .questions-answer {
  width: 100%;
  height: auto;
  background-color: #f3f3f3;
  box-sizing: border-box;
  padding: 20px;
  word-break: break-all;
}
/* 2022-07-18 김재용 */
.questions-answer.dis-none {
  display: none;
}

.questions-answer > span {
  display: block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

/* 페이지 버튼 */
.page {
  max-width: 400px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.page ul {
  width: 450px;
  height: auto;
  display: flex;
  flex-direction: row;
}
.page ul li {
  width: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.page ul li:first-child {
  border: 1px solid var(--kk-color-Ymain);
  box-sizing: border-box;
  background-color: var(--kk-color-Ymain);
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 50px;
  line-height: 30px;
  cursor: pointer;
}
.page button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid transparent;
  background-color: #fff;
  font-size: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1152px) {
  .mykiikey-frame {
    width: 100%;
  }
  .questions-list > th:first-child {
    width: 80%;
  }
  .questions-list > th:last-child {
    width: 20%;
    padding-right: 10%;
  }

  .open .img {
    background-position: 20px;
  }
}
@media screen and (max-width: 768px) {
  .mykiikey-frame {
    margin-top: 0px;
    /* margin-bottom: 100px; */
  }
  .mykiikey-frame > .mykiikey-menu-list-area {
    display: none;
  }
  .mykiikey-frame > .mykiikey-menu-area {
    width: 100%;
  }
  .daps-frame > .daps-paging-list {
    display: none;
  }
  .daps-frame > .mobile-scroll-button {
    display: block;
  }
  .questions-list > th:first-child {
    width: 70%;
  }
  .questions-list > th:last-child {
    width: 16%;
    padding-right: 10%;
  }
  /* 화살표 */
  .open .img {
    background-position: 5px;
  }
}

@media screen and (max-width: 650px) {
}
