button {
  cursor: pointer;
}

.container {
  width: 100%;
  height: 100%;
  margin: auto;
}

.container .title {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.container .title > button {
  border: none;
  margin-left: 5px;
  height: 20px;
  align-self: center;
  background: none;
}

.container .title .pagination {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-self: center;
}
.container .title .pagination button {
  margin: 0 10px;
  border: none;
  background: none;
}

.container .title .pagination-left.none
{
  opacity: 0;
}

.container .week {
  display: flex;
}

.container .week .weekday {
  width: calc(100% / 7);
  text-align: center;
}

.container .date {
  margin-top: 20px;
}

.container .date .weekday {
  width: calc(100% / 7);
  float: left;
  text-align: center;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}

.container .date .weekday.none
{
  color:#c9c9c9;
  cursor: default;
  pointer-events: none;
}

.container .date .light:hover
{
  background-color: rgb(185, 185, 185);
}

.container .date .today {
  background-color: rgb(0, 0, 0);
  color: white;
  font-weight: 700;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
