.my-wallet {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-wallet-barcode-view-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 90%;
  height: 100%;
  margin: auto;
}

.my-wallet-barcode-header {
  /* border: 1px solid #D6D6D6; */
  width: 70%;
  height: 20%;

  display: flex;
  align-items: center;
}

.my-wallet-barcode-header div {
  display: flex;
  align-items: center;
  margin-bottom: 10%;
}

.my-wallet-barcode-header div .barcode-header-main-text {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  letter-spacing: -0.05em;

  color: #323232;

  white-space: nowrap;
}

.my-wallet-barcode-header div .barcode-header-sub-text {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* or 18px */

  letter-spacing: -0.05em;

  color: #323232;

  margin-left: 10px;

  white-space: nowrap;
}

.my-wallet-barcode-area {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 14px;

  width: 99%;
}

.my-wallet-barcode-area > .barcode-content-wrap {
  position: relative;
  width: 82%;
  height: 0;
  padding-bottom: 50%;
  margin: auto;
  margin-top: 10px;
  /* margin-top: 10px;
    margin-bottom: 10px; */
}

.my-wallet-barcode-area > .barcode-content-wrap > .barcode-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  /* margin: 10%; */
}

.barcode-content canvas {
  /* position: relative; */
  /* top: 40px; */
  width: 100%;
  height: 33%;
  margin-top: 10px;
  /* width: 100%; */
  /* height: 50%; */
}

.barcode-content .barcode-text-area {
  /* position: relative; */
  /* top: 50px; */
  width: 94%;
  display: flex;
  justify-content: flex-end;
  column-gap: 6px;
}

.barcode-content .barcode-text-area span {
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
}

.barcode-content .barcode-unit-area {
  /* position: relative; */

  width: 95%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  /* top: 20px; */
}

.barcode-content .barcode-unit-area div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.barcode-content .barcode-unit-area .barcode-price-unit {
  background: #6dd5ed;
  border-radius: 4px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;

  color: #323232;
  padding: 3px 6px;

  letter-spacing: -0.05em;
}

.barcode-content .barcode-unit-area .barcode-price {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;

  color: #323232;
  margin-left: 6px;

  letter-spacing: -0.05em;
}

.barcode-content .barcode-unit-area .barcode-text-private {
  background: #fff;
  border-radius: 10px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;

  color: #9d9d9d;
  padding: 4px 9px;
}

.barcode-content .barcode-unit-area .barcode-text-public {
  background: #d9d9d9;
  border-radius: 10px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;

  color: #323232;
  padding: 4px 9px;
}

.my-wallet-barcode-area div button {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  font-size: 25px;
  background-color: #ffffff;

  border: #ffffff;

  /* border-radius: 50%; */
}

.my-wallet-barcode-area div button img {
  position: absolute;
  width: 30%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 100px;
  font-weight: 100;
  color: #b9b9b9;
  /* background-color: red; */
  /* border-radius: 50%; */
}

.my-wallet-barcode-price-area {
  border: 1px dotted black;
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 12px;
}

.my-wallet-barcode-price-area div {
  padding: 10%;
}

.my-wallet-barcode-btn-area {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 14px;

  display: flex;
  justify-content: center;
  width: 99%;
  height: 90px;
  margin-top: 1px;
}

.my-wallet-barcode-btn-area div {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 3px;
}

.my-wallet-barcode-btn-area div img {
  width: 21px;
  height: 25px;
}

.my-wallet-barcode-btn-area div span {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #323232;
}

.my-wallet-barcode-warning-area {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 14px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 99%;
  height: 90px;
  margin-top: 1px;
}

.my-wallet-barcode-warning-area span {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  /* or 13px */

  text-align: center;

  color: #484848;
  letter-spacing: -0.05em;
}

/* 비밀번호 확인 모달창 */
.password-check-modal-frame {
  position: absolute;
  top: 0px;
  /* left: 0px; */
  width: 100%;
  height: 100%;
  background-color: rgba(44, 44, 44, 0.3);
  z-index: 1000;
  display: flex;
  overflow-y: hidden;
}

.password-check-modal-frame > .password-check-modal-wrap {
  position: relative;
  width: 75%;
  height: 0;
  padding-bottom: 50%;
  margin: auto;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* margin-top: 50%; */
}

.password-check-modal-wrap > .password-check-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%); */
  /* margin: 0 auto; */
  /* width: 450px; */
  /* min-height: 250px; */
  box-sizing: border-box;
  padding: 30px;
  border-radius: 30px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.password-check-modal > * {
  width: 100%;
}

.password-check-modal .title {
  font-weight: 700;
  font-size: 17px;
  padding-bottom: 20px;
  text-align: center;
}

.password-check-modal .input {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  font-size: 16px;

  vertical-align: middle;
  justify-content: center;
  white-space: nowrap;
  margin: auto;
}

.password-check-modal .input input {
  height: 24px;
  width: 70%;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  text-align: left;
  box-sizing: border-box;
  padding-right: 5px;
  font-weight: 600;
  font-family: "Bahnschrift";
  font-size: 15px;
}

.password-check-modal .button {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.password-check-modal .button > * {
  width: 40%;
  height: 30px;
  border-radius: 100px;
  border: none;
  font-size: 17px;
  color: #fff;
  cursor: pointer;
  padding: 8px 0;
}

.password-check-modal .button .cancel {
  background-color: #000000;
}

.password-check-modal .button .submit {
  background-color: var(--kk-color-Ymain);
}
