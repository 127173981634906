.email-Refused {
  width: 100%;
  height: auto;
  background-color: var(--kk-color-Ymain);
  box-sizing: border-box;
  padding-top: 10%;
  padding-bottom: 10%;
  font-family: "Nanum Gothic";
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 7%;
}

.email-Refused > .email-note {
  margin: 0 auto;
  width: 65%;
  height: auto;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 70px;
  gap: 5%;
}

.email-note > h2 {
  margin-bottom: 15px;
}

.email-note > span {
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .email-Refused {
    background-color: #fff;
  }
  .email-Refused > .email-note {
    width: 100%;
  }
}
